import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { RadioBase, SelectionController } from './gsk-radio-base';
import { RadioGroupBase } from './gsk-radio-group-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-radio': Radio;
    'gsk-radio-group': RadioGroup;
  }
}

@customElementDefinition('gsk-radio-group')
export class RadioGroup extends RadioGroupBase {}

@customElementDefinition('gsk-radio')
export class Radio extends RadioBase {}

export { SelectionController };

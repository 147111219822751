import { html, LitElement, property, query, TemplateResult, classMap, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import { observer } from '@gsk-tech/gsk-base/observer';
import {
  ConstraintValidationModel,
  createValidityObj,
  CustomValidityState,
  ValidityTransform,
} from '@gsk-tech/gsk-base/constraint-validation';
import { cssClasses } from './constants';
import { gskStyle } from './gsk-checkbox-group-css';
import { CheckboxBase } from './gsk-checkbox-base';

export class CheckboxGroupBase extends LitElement implements ConstraintValidationModel<string[]> {
  public static get styles() {
    return unsafeCSS(gskStyle);
  }

  @query('slot')
  protected slotEl!: HTMLSlotElement;

  @query('.checkbox-group')
  protected mdcRoot!: HTMLElement;

  @query('.checkbox-group-helper-text')
  protected helperTextEl!: HTMLElement;

  /**
   * Optional. Default value is false. Use this property in order to display the check boxes in the same line
   */
  @property({ type: Boolean })
  public inline = false;

  /**
   * Optional. Indicates the text content of the checkbox-group.
   */
  @property({ type: String })
  public label = '';

  // TODO: Comments are missing here
  @property({ type: String, reflect: true })
  @observer(function(this: CheckboxGroupBase) {
    this._updateName();
  })
  public name?: string;

  // TODO: Comments are missing here
  @property({ type: Boolean, reflect: true })
  @observer(function(this: CheckboxGroupBase) {
    this._updateDisabled();
  })
  public disabled?: boolean;

  // TODO: Comments are missing here
  @property({ type: Boolean, reflect: true })
  @observer(function(this: CheckboxGroupBase) {
    this._updateRequired();
  })
  public required?: boolean;

  /**
   * minimum number of checkboxes required
   */
  @property({ type: Number })
  public minValues: number = 0;

  /**
   * maximum number of checkboxes allowed
   */
  @property({ type: Number })
  public maxValues: number = Infinity;

  /**
   * Optional. This text appears below of the checkbox-group when it gets focused
   */
  @property({ type: String })
  public helperText = '';

  /**
   * Optional. Default value is false. Use this property to display always the helper text
   */
  @property({ type: Boolean })
  public persistentHelperText = false;

  /**
   * Optional. This property is use to display a message when checkbox-group is not valid
   */
  @property({ type: String })
  public validationMessage = '';

  /**
   * Optional. Default is false. If set, reportValidity method will be called on firstUpdated.
   */
  @property({ type: Boolean })
  public validateOnInitialRender = false;

  @property({ type: Boolean })
  public dirty = false;

  public get isUiValid(): boolean {
    if (this.willValidate) {
      /* istanbul ignore if */
      if (this.valid !== undefined) {
        return this.valid;
      }
      /* istanbul ignore if */
      if (this.dirty || this.validateOnInitialRender) {
        return this.validity.valid;
      }
    }
    return true;
  }

  /**
   * Optional. Use it to force the validity state of the component,
   * even if the `required` prop is true.
   */
  @property({ type: Boolean })
  public valid?: boolean = undefined;

  public validityTransform: ValidityTransform<string[]> | null = null;

  public get validity(): Readonly<ValidityState> {
    if (!this.willValidate) {
      return createValidityObj();
    }
    this._checkValidity();
    return this._validity;
  }

  /**
   * usually you'll want to use the getter rather than access this directly
   * so that the validity is recomputed
   */
  protected _validity: ValidityState = createValidityObj();

  protected get inputs(): CheckboxBase[] {
    return [...this.querySelectorAll('gsk-checkbox')];
  }

  @property({ type: Array })
  public get value(): string[] {
    return this.inputs
      .filter(input => Boolean(input.value) && input.checked)
      .map(input => input.value) as string[];
  }

  public set value(value: string[]) {
    this.inputs
      .filter(input => Boolean(input.value))
      .forEach(input => {
        input.checked = value.includes(input.value as string);
        input.indeterminate = false;
        return input.checked;
      });
    // _checkValidity is called because the validity needs to be updated from programmatic change to the value
    // but we don't want to emit an invalid event
    this._checkValidity();
    this.requestUpdate();
  }

  /**
   * Used to render the lit-html TemplateResult for the helper-text
   */
  protected _renderHelperText() {
    const isValidationMessage = !this.isUiValid;
    const classes = {
      'checkbox-group-helper-text': true,
      [cssClasses.PERSISTENT]: this.persistentHelperText,
      [cssClasses.VALIDATION_MSG]: isValidationMessage,
    };
    const message = isValidationMessage ? this.validationMessage || 'invalid' : this.helperText;

    return this.helperText || isValidationMessage
      ? html`
          <p class="${classMap(classes)}">${message}</p>
        `
      : null;
  }

  protected _renderLabel() {
    return html`
      <label class="checkbox-group__label"
        >${this.label}${this.required
          ? html`
              <span>*</span>
            `
          : ''}</label
      >
    `;
  }

  protected render(): TemplateResult {
    const classes = {
      'checkbox-group': true,
      [cssClasses.INLINE]: this.inline,
      [cssClasses.INVALID]: !this.isUiValid,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.label ? this._renderLabel() : ''}
        <div class="checkbox-group__content">
          <slot></slot>
        </div>
        ${this._renderHelperText()}
      </div>
    `;
  }

  protected async firstUpdated(_changedProperties: Map<PropertyKey, unknown>): Promise<void> {
    await super.firstUpdated(_changedProperties);
    // wait for the children to render
    await this.updateComplete;
    this._addListeners();
    if (this.validateOnInitialRender) {
      this.reportValidity();
    } else {
      this._checkValidity();
    }
  }

  /**
   * Adding listeners to the checkbox-group
   */
  protected _addListeners(): void {
    this.slotEl!.addEventListener('slotchange', this._handleSlotChange.bind(this));
    this.slotEl!.addEventListener('change', this._handleInteraction.bind(this));
    this.slotEl!.addEventListener('blur', this._handleInteraction.bind(this));
  }

  /**
   * Handles interaction events
   */
  protected _handleInteraction(evt): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    this.dirty = true;
    this.reportValidity();
    emit(this, evt.type, { value: this.value });
  }

  /**
   * Handles slot change event
   */
  protected _handleSlotChange(): void {
    this._updateName();
  }

  /**
   * Updates inputs disabled states
   */
  protected _updateDisabled() {
    if (this.disabled !== undefined) {
      const updates = [...this.children].map(item => {
        if (this.disabled) {
          item.setAttribute('disabled', '');
        } else {
          item.removeAttribute('disabled');
        }
        return (item as CheckboxBase).updateComplete;
      });
      Promise.all(updates as Promise<boolean>[]).then(() => {
        this.requestUpdate();
      });
    }
  }

  /**
   * Updates inputs disabled states
   */
  protected _updateRequired() {
    if (this.required !== undefined) {
      this.inputs.forEach(item => {
        if (this.required) {
          item.setAttribute('required', '');
        } else {
          item.removeAttribute('required');
        }
      });
    }
  }

  /**
   * Updates inputs names
   */
  protected _updateName() {
    if (this.name !== undefined) {
      this.inputs.forEach(input => {
        input.name = this.name;
      });
    }
  }

  public focus(): void {
    if (this.inputs.length > 0) {
      this.inputs[0].focus();
    }
  }

  // Validation
  /**
   * delegation to native element is easiest way to handle this
   */
  public get willValidate(): boolean {
    return this.inputs.some(box => box.willValidate);
  }

  public setCustomValidity(message: string): void {
    this.validationMessage = message;
    const isError = message !== '';
    this._validity = createValidityObj({
      customError: isError,
    });
    this.dirty = true;
    this.requestUpdate();
  }

  public checkValidity(): boolean {
    const isValid = this.validity.valid;

    if (!isValid) {
      const invalidEvent = new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  protected _checkValidity(): boolean {
    const { value } = this;
    const len: number = value.length;
    const isCustomError = this._validity.customError;
    const nativeValidity: CustomValidityState = createValidityObj({
      customError: isCustomError,
      valid: !isCustomError,
    });
    let valid = true;
    if (this.required && valid) {
      if (!(Boolean(value) && value.length)) {
        nativeValidity.valueMissing = true;
        valid = false;
      }
    }
    if (this.minValues && valid) {
      if (len > 0 && len < this.minValues) {
        nativeValidity.rangeUnderflow = true;
        valid = false;
      }
    }
    if (this.maxValues && valid) {
      if (len > this.maxValues) {
        nativeValidity.rangeOverflow = true;
        valid = false;
      }
    }
    nativeValidity.valid = nativeValidity.valid && valid;

    let validity = nativeValidity;
    if (this.validityTransform) {
      const transformedValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...transformedValidity };
    }

    this._validity = validity;
    return this._validity.valid;
  }

  public reportValidity(): boolean {
    const isValid = this.checkValidity();
    this.dirty = true;
    this.requestUpdate();
    return isValid;
  }
}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import {
  ListBase,
  ListItemBase,
  ListDividerBase,
  ListGroupBase,
  ListGroupHeaderBase,
} from './gsk-list-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-list': List;
    'gsk-list-item': ListItem;
    'gsk-list-divider': ListDivider;
    'gsk-list-group': ListGroup;
    'gsk-list-group-header': ListGroupHeader;
  }
}

// Define main components
@customElementDefinition('gsk-list-item')
export class ListItem extends ListItemBase {}

@customElementDefinition('gsk-list-divider')
export class ListDivider extends ListDividerBase {}

@customElementDefinition('gsk-list-group')
export class ListGroup extends ListGroupBase {}

@customElementDefinition('gsk-list-group-header')
export class ListGroupHeader extends ListGroupHeaderBase {}

@customElementDefinition('gsk-list')
export class List extends ListBase {}

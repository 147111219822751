import { FormfieldBase as MWCFormfield } from './mwc-formfield-base';
import { gskStyle as mwcStyle } from './mwc-formfield-css';
import { property, unsafeCSS, PropertyValues } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-formfield-css';

export class FormfieldBase extends MWCFormfield {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: Boolean, reflect: true })
  public noripple?: boolean;

  @property({ type: Boolean, reflect: true })
  public disabled?: boolean;

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('disabled')) {
      this.mdcRoot.classList.toggle('mdc-form-field--disabled', Boolean(this.disabled));
      [...this.children].forEach(item => {
        if (Boolean(this.disabled)) {
          item.setAttribute('disabled', '');
        } else {
          item.removeAttribute('disabled');
        }
      });
    }

    if (_changedProperties.has('noripple')) {
      [...this.children].forEach(item => item['noripple'] = this.noripple);
    }
  }
}

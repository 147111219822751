import { RadioBase as MWCRadio } from './mwc-radio-base';
import { gskStyle as mwcStyle } from './mwc-radio-css';
import { property, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';

import { SelectionController } from './selection-controller';
import { classMap } from 'lit/directives/class-map.js';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import { observer } from '@gsk-tech/gsk-base/observer';
import { gskStyle } from './gsk-radio-css';

export { SelectionController };

export class RadioBase extends MWCRadio {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  public constructor() {
    super();

    if (SelectionController) {
      const root = this.ownerDocument!.documentElement || window;
      // Bug fix - force override of controller;
      this._selectionController = SelectionController.getController(root);
    }
  }

  /**
   * Optional. Default value is false. Removes the ripple effect on the Radio.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value is false. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = false;

  /**
   * Required. Returns / Sets the element's name attribute, containing
   * a name that identifies the element when submitting the form.
   */
  @property({ type: String, reflect: true })
  @observer(function(this: RadioBase, value: string) {
    if (this._previousName !== value) {
      this._updateName();
    }
  })
  public name = '';

  /**
   * Optional. Default value is false.
   * Use it to make the component required and activate native validation
   */
  @property({ type: Boolean, reflect: true })
  public required = false;

  protected _previousName = '';

  /**
   * A read-only boolean property that returns true if the element is a candidate for constraint validation;
   * and false otherwise.
   */
  public get willValidate(): boolean {
    return this.formElement ? this.formElement.willValidate : false;
  }

  public render(): TemplateResult {
    const radioClasses = {
      'mdc-radio': true,
      'mdc-radio--primary': this.primary,
      'mdc-radio--selected': this.checked,
      'mdc-radio--no-ripple': this.noripple,
    };

    return html`
      <div class="${classMap(radioClasses)}" .ripple="${ripple({})}">
        <input
          class="mdc-radio__native-control"
          type="radio"
          name="${this.name}"
          aria-label="${this.label}"
          .checked="${this.checked}"
          .value="${this.value}"
          ?required="${this.required}"
          @change="${this._changeHandler}"
          @focus="${this._focusHandler}"
          @click="${this._clickHandler}"
        />

        <div class="mdc-radio__background">
          <div class="mdc-radio__outer-circle"></div>
          <div class="mdc-radio__inner-circle"></div>
        </div>
      </div>
    `;
  }

  protected _updateName() {
    if (this.name !== undefined) {
      const currentName = this.name as string;

      // Unregister old name
      this.name = this._previousName;
      this._selectionController!.unregister(this);

      // Register new name
      this.name = this._previousName = currentName;
      this._selectionController!.register(this);
    } else {
      this.name = this._previousName;
    }
  }
}

/**
 @license
 Copyright 2018 Google Inc. All Rights Reserved.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import {
  addHasRemoveClass,
  classMap,
  emit,
  findAssignedElement,
  FormElement,
  html,
  HTMLElementWithRipple,
  observer,
  property,
  query,
  RippleSurface,
} from '@gsk-tech/gsk-base/form-element';
import { MDCSelectAdapter } from '@material/select/adapter';
import { MDCSelectFoundation } from '@material/select/foundation';
import { MDCSelectFoundationMap } from '@material/select/types';
import { cssClasses, strings } from '@material/select/constants';
import { MDCSelectIcon, MDCSelectIconFactory } from '@material/select/icon';
import { MDCSelectHelperText, MDCSelectHelperTextFactory } from '@material/select/helper-text';
import { MDCNotchedOutline, MDCNotchedOutlineFactory } from '@material/notched-outline';
import { MDCFloatingLabel, MDCFloatingLabelFactory } from '@material/floating-label';
import { MDCLineRipple, MDCLineRippleFactory } from '@material/line-ripple';
import * as menuSurfaceConstants from '@material/menu-surface/constants';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import { EVENTS as MENU_EVENTS } from '@gsk-tech/gsk-menu/mwc-menu-base';
import { MenuBase as MWCMenu } from '@gsk-tech/gsk-menu/gsk-menu-base';
import { ListBase as MWCList } from '@gsk-tech/gsk-list/mwc-list-base';
import { ListItemBase as MWCListItem } from '@gsk-tech/gsk-list/mwc-list-item-base';
import { MDCListIndex } from '@material/list/types';
import { TemplateResult } from '@gsk-tech/gsk-base/base-element';

const lineRippleFactory: MDCLineRippleFactory = el => new MDCLineRipple(el);
const helperTextFactory: MDCSelectHelperTextFactory = el => new MDCSelectHelperText(el);
const iconFactory: MDCSelectIconFactory = el => new MDCSelectIcon(el);
const labelFactory: MDCFloatingLabelFactory = el => new MDCFloatingLabel(el);
const outlineFactory: MDCNotchedOutlineFactory = el => new MDCNotchedOutline(el);

type PointerEventType = 'click';

const POINTER_EVENTS: PointerEventType[] = ['click'];
const VALIDATION_ATTR_WHITELIST = ['required', 'aria-required'];

export class SelectBase extends FormElement {
  @query('.mdc-select')
  protected mdcRoot!: HTMLElementWithRipple;

  @query('.mdc-select-wrapper')
  protected wrapper!: HTMLElement;

  @query('.mdc-floating-label')
  protected mdcLabel!: HTMLElement;

  @query(strings.HIDDEN_INPUT_SELECTOR)
  protected _hiddenInput!: HTMLInputElement;

  @query('.mdc-select__selected-text')
  protected _selectedText!: HTMLElement;

  @query('.mdc-select__selected-text-inner')
  protected _selectedTextInner!: HTMLElement;

  @query('.mdc-select__placeholder')
  protected _placeholderEl!: HTMLElement;

  @query('slot')
  protected slotEl!: HTMLSlotElement;

  @query(strings.LABEL_SELECTOR)
  protected labelElement!: HTMLLabelElement;

  @query(strings.LINE_RIPPLE_SELECTOR)
  protected lineRippleElement!: HTMLElement;

  @query(strings.OUTLINE_SELECTOR)
  protected outlineElement!: HTMLElement;

  @query(strings.LEADING_ICON_SELECTOR)
  protected leadingIconElement!: HTMLElement;

  @query('.mdc-select-helper-text')
  protected helperTextElement!: HTMLElement;

  /**
   * Optional. Use this property to sets a label to the select component
   */
  @property({ type: String })
  public label = '';


  /**
   * Optional. Default value sets to false. Styles an outlined select that is flush with the surface.
   */
  @property({ type: Boolean })
  public get outlined(): boolean {
    return true;
  }

  public set outlined(_) {
    // noop
  }
  /**
   * Optional. Default value is set to false.
   * Works only with the 'Enhanced Select' variant.
   * Add this property when you want to use a Checkbox in your 'List Items' in order to prevent menu from closing on selection.
   */
  @property({ type: Boolean, reflect: true })
  @observer(function(this: SelectBase, value: boolean) {
    if (this.menuEl) this.menuEl.singleSelection = !value;
  })
  public multiple = false;

  /**
   * Optional. Default value is set to false. Use this property to whether or not the select should be disabled.
   */
  @property({ type: Boolean, reflect: true })
  @observer(function(this: SelectBase, value: boolean): void {
    this.mdcFoundation && this.mdcFoundation.setDisabled(value);
  })
  public disabled?: boolean;

  /**
   * Optional. Helper text to display below the select. Display default only when focused.
   */
  @property({ type: String })
  @observer(function(this: SelectBase, value: string) {
    this.mdcFoundation && this.mdcFoundation.setHelperTextContent(value);
  })
  public helperTextContent = '';

  /**
   * Optional. Message to show in the error color when the select is invalid. (Helper text will not be visible)
   */
  @property({ type: String })
  public validationMessage = '';

  /**
   * Optional. Default value is set to false. Always show the helper text despite focus.
   */
  @property({ type: Boolean })
  @observer(function(this: SelectBase, value: boolean) {
    this._helperText && this._helperText.foundation.setPersistent(value);
  })
  public persistentHelperText = false;

  /**
   * Optional. Use this property to sets the value for the aria-label text related to the leading icon
   */
  @property({ type: String })
  @observer(function(this: SelectBase, value: string) {
    this.mdcFoundation && this.mdcFoundation.setLeadingIconAriaLabel(value);
  })
  public leadingIconAriaLabel = '';

  /**
   * Optional. Used to sets the leading icon name to display in the input
   */
  @property({ type: String })
  @observer(function(this: SelectBase, value: string) {
    this.mdcFoundation && this.mdcFoundation.setLeadingIconContent(value);
  })
  public leadingIconContent = '';

  /**
   * Optional. Default value is set to false. Displays error state if value is empty and input is blurred.
   */
  @property({ type: Boolean })
  @observer(function(this: SelectBase, value: boolean) {
    if (this._nativeControl) {
      this._nativeControl.required = value;
    } else if (this._selectedText) {
      if (value) {
        this._selectedText!.setAttribute('aria-required', value.toString());
      } else {
        this._selectedText!.removeAttribute('aria-required');
      }
    }
  })
  public required = false;


  set floatLabel(_: boolean) {
    // noop
  }

  @property({ type: Boolean })
  get floatLabel() {
    return false;
  }

  /**
   * Optional. Sets disappearing input placeholder.
   */
  @property({ type: String })
  public placeholder = '';

  @property({ type: String, reflect: true })
  @observer(function(this: SelectBase, value: string) {
    if (this.value !== this._previousValue) {
      this._previousValue = value;
      setTimeout(() => {
        if (!value) {
          this._setSelectedIndex(-1);
        }
        this.mdcFoundation.setValue(value);
      });
    }
  })
  public value: string | string[] = '';

  /**
   * Gets the valid state of the select
   */
  public get internalValidity(): boolean {
    return this.mdcFoundation && this.mdcFoundation.isValid();
  }

  /**
   * Sets the valid state of the select
   */
  public set internalValidity(valid: boolean) {
    this.mdcFoundation && this.mdcFoundation.setValid(valid);
    this._setValidity(valid);
  }

  /**
   * Ripple getter for Ripple integration
   */
  public get ripple(): RippleSurface | undefined {
    return this.mdcRoot.ripple;
  }

  /**
   * Gets the current selected index value
   */
  public get selectedIndex(): MDCListIndex {
    return this._getSelectedIndex();
  }

  /**
   * Sets the current selected index value
   */
  public set selectedIndex(value: MDCListIndex) {
    this._setSelectedIndex(value);
  }

  /**
   * Enables or disables the use of native validation. Use this for custom validation.
   */
  protected _useNativeValidation = true;
  public set useNativeValidation(value: boolean) {
    this._useNativeValidation = value;
  }

  protected get selectEl(): HTMLSelectElement {
    return findAssignedElement(this.slotEl, 'select') as HTMLSelectElement;
  }

  protected get menuEl(): MWCMenu {
    return findAssignedElement(this.slotEl, 'gsk-mwc-menu') as MWCMenu;
  }

  protected get listEl(): MWCList | undefined {
    const menuRef: MWCMenu = this.menuEl;
    return menuRef ? (menuRef.list as MWCList) : undefined;
  }

  protected get listItems(): MWCListItem[] {
    return this.listEl ? this.listEl.listElements : [];
  }

  protected get formElement(): HTMLElement {
    return (this._nativeControl || this._selectedText) as HTMLElement;
  }

  protected _previousValue: string | string[] = '';

  protected _formElementId = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  protected _selectedItem!: MWCListItem;

  protected _isMenuOpen: boolean = false;

  protected _nativeControl!: HTMLSelectElement | null;

  protected _leadingIcon!: MDCSelectIcon;

  protected _helperText!: MDCSelectHelperText | null;

  protected _lineRipple!: MDCLineRipple | null;

  protected _label!: MDCFloatingLabel | null;

  protected _outline!: MDCNotchedOutline | null;

  protected _validationObserver!: MutationObserver;

  protected _handleNativeChange = this._onNativeChange.bind(
    this,
  ) as EventListenerOrEventListenerObject;

  protected _handleFocus = this._onFocus.bind(this) as EventListenerOrEventListenerObject;

  protected _handleBlur = this._onBlur.bind(this) as EventListenerOrEventListenerObject;

  protected _handleClick = this._onClick.bind(this) as EventListenerOrEventListenerObject;

  protected _handleKeydown = this._onKeydown.bind(this) as EventListenerOrEventListenerObject;

  protected _handleMenuOpened = this._onMenuOpened.bind(this) as EventListenerOrEventListenerObject;

  protected _handleMenuClosed = this._onMenuClosed.bind(this) as EventListenerOrEventListenerObject;

  protected _handleMenuSelected = this._onMenuSelected.bind(
    this,
  ) as EventListenerOrEventListenerObject;

  protected _handleMenuChange = this._onMenuChange.bind(this) as EventListenerOrEventListenerObject;

  protected mdcFoundation!: MDCSelectFoundation;

  protected readonly mdcFoundationClass = MDCSelectFoundation;

  /**
   * Create the adapter for the `mdcFoundation`.
   * Override and return an object with the Adapter's functions implemented
   */
  createAdapter(): MDCSelectAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      ...(this._nativeControl
        ? this._getNativeSelectAdapterMethods()
        : this._getEnhancedSelectAdapterMethods()),
      ...this._getCommonAdapterMethods(),
      ...this._getOutlineAdapterMethods(),
      ...this._getLabelAdapterMethods(),
    };
  }

  protected _getNativeSelectAdapterMethods() {
    return {
      getValue: () => this._nativeControl!.value,
      setValue: (value: string) => {
        const options = [...this.selectEl!.options];
        const selectedIndex = options.findIndex(item => item.value === value);
        let selectedContent: string | null = '';
        if (selectedIndex !== -1) {
          selectedContent = options[selectedIndex].textContent;
        }
        if (
          this._previousValue === value &&
          selectedIndex === this.selectedIndex &&
          this._getTextContent() === selectedContent
        ) {
          return;
        } else {
          this._setNativeSelectedIndex(selectedIndex);
        }
      },
      openMenu: () => undefined,
      closeMenu: () => undefined,
      isMenuOpen: () => false,
      setSelectedIndex: (index: number) => this._setNativeSelectedIndex(index),
      setDisabled: (isDisabled: boolean) => {
        this._nativeControl!.disabled = isDisabled;
      },
      setValid: (isValid: boolean) => {
        if (!this._useNativeValidation) return;

        if (isValid) {
          this.mdcRoot.classList.remove(cssClasses.INVALID);
        } else {
          this.mdcRoot.classList.add(cssClasses.INVALID);
        }

        this._setValidity(isValid);
      },
      checkValidity: () => {
        const classList = this.mdcRoot.classList;
        if (!classList.contains(cssClasses.DISABLED)) {
          return this.selectedIndex !== -1 && (this.selectedIndex !== 0 || Boolean(this.value));
        } else {
          return true;
        }
      },
    };
  }

  protected _getEnhancedSelectAdapterMethods() {
    return {
      getValue: () => {
        // await this.whenDefined();
        if (!this.multiple) {
          const selectedItem = this.listItems[this.selectedIndex as number];
          return selectedItem ? selectedItem.value : '';
        }

        const selectedIndex = this.selectedIndex;

        if (typeof selectedIndex === 'number') {
          return selectedIndex !== -1 ? this.listItems[selectedIndex].value : '';
        }

        return (selectedIndex
          .filter(index => Boolean(this.listItems[index].value))
          .map(index => this.listItems[index].value) as unknown) as string;
      },

      setValue: async (value: string) => {
        let selectedIndex: MDCListIndex;
        await this.whenDefined();

        if (!this.listEl) return;

        if (typeof value === 'string') {
          const desiredSelectedIndex = this.listItems.findIndex(item => {
            return value === item.value;
          });

          if (desiredSelectedIndex === -1) return; // Return early

          selectedIndex = desiredSelectedIndex;
        } else {
          selectedIndex = this.listItems
            .filter(item => (value as string[]).includes(item.value))
            .map(item => this.listItems.indexOf(item));
        }

        this._setEnhancedSelectedIndex(selectedIndex);
      },
      openMenu: () => {
        if (this.menuEl && !this.menuEl.open) {
          this.menuEl.setDefaultFocusState(2);
          this.menuEl.open = true;
          this._isMenuOpen = true;
          this._selectedText!.setAttribute('aria-expanded', 'true');
        }
      },
      closeMenu: () => {
        if (this.menuEl && this.menuEl.open) {
          this.menuEl.open = false;
        }
      },
      isMenuOpen: () => Boolean(this.menuEl) && this._isMenuOpen,
      setSelectedIndex: (index: MDCListIndex) => {
        this._setEnhancedSelectedIndex(index);
      },
      setDisabled: (isDisabled: boolean) => {
        this._selectedText!.setAttribute('tabindex', isDisabled ? '-1' : '0');
        this._selectedText!.setAttribute('aria-disabled', isDisabled ? 'true' : 'false');
        if (this._hiddenInput) {
          this._hiddenInput.disabled = isDisabled;
        }
      },
      checkValidity: () => {
        const classList = this.mdcRoot.classList;
        if (!classList.contains(cssClasses.DISABLED)) {
          const selectedIndex = this.selectedIndex;
          if (Array.isArray(selectedIndex)) {
            return selectedIndex.length > 0;
          } else {
            return selectedIndex !== -1 && (selectedIndex !== 0 || Boolean(this.value));
          }
        }

        return true;
      },
      setValid: (isValid: boolean) => {
        this._selectedText!.setAttribute('aria-invalid', (!isValid).toString());

        if (isValid) {
          this.mdcRoot.classList.remove(cssClasses.INVALID);
        } else {
          this.mdcRoot.classList.add(cssClasses.INVALID);
        }

        this._setValidity(isValid);
      },
    };
  }

  protected _getCommonAdapterMethods() {
    return {
      addClass: className => this.mdcRoot.classList.add(className),
      removeClass: className => this.mdcRoot.classList.remove(className),
      hasClass: className => this.mdcRoot.classList.contains(className),
      setRippleCenter: normalizedX =>
        this._lineRipple && this._lineRipple.setRippleCenter(normalizedX),
      activateBottomLine: () => this._lineRipple && this._lineRipple.activate(),
      deactivateBottomLine: () => this._lineRipple && this._lineRipple.deactivate(),
      notifyChange: _ => {
        // const index = this.selectedIndex;
        // emit(this, 'change', { value, index }, true);
      },
    };
  }

  protected _getOutlineAdapterMethods() {
    return {
      hasOutline: () => Boolean(this._outline),
      notchOutline: labelWidth => this._outline && this._outline.notch(labelWidth),
      closeOutline: () => this._outline && this._outline.closeNotch(),
    };
  }

  protected _getLabelAdapterMethods() {
    return {
      floatLabel: shouldFloat => this._label && this._label.float(shouldFloat),
      getLabelWidth: () => (this._label ? this._label.getWidth() : 0),
    };
  }

  protected _renderFloatingLabel() {
    return html`
      <label class="mdc-floating-label" for="${this._formElementId}">${this.label}</label>
    `;
  }

  protected _renderNotchedOutline(showAdjacentLabel: boolean | string) {
    return html`
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="mdc-notched-outline__notch">
          ${!showAdjacentLabel ? this._renderFloatingLabel() : ''}
        </div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>
    `;
  }

  protected _renderLineRipple() {
    return html`
      <div class="mdc-line-ripple"></div>
    `;
  }

  protected _renderHelperText() {
    return this.helperTextContent || this.validationMessage
      ? html`
          <div class="mdc-select-helper-text"></div>
        `
      : '';
  }

  protected _renderLeadingIcon() {
    return html`
      <i class="material-icons mdc-select__icon">${this.leadingIconContent}</i>
    `;
  }

  protected _renderDropdownIcon() {
    return html`
      <i class="mdc-select__dropdown-icon"></i>
    `;
  }

  protected _renderPlaceholder() {
    return html`
      <span class="mdc-select__placeholder">${this.placeholder}</span>
    `;
  }

  protected _renderSelectedText() {
    return html`
      <div class="mdc-select__selected-text">
        <span class="mdc-select__selected-text-inner"
          >${!this.placeholder
            ? html`
                &nbsp;
              `
            : ''}</span
        >
        ${this.placeholder ? this._renderPlaceholder() : ''}
      </div>
    `;
  }

  protected _renderAdjacentLabel() {
    const classes = {
      'mdc-floating-label--adjacent': true,
    };

    return html`
      <div class="${classMap(classes)}" for="${this._formElementId}">${this.label}</div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const hasOutline = this.outlined;
    const hasLabel = this.label;
    const hasLabelAndIsNotOutlined = hasLabel && !hasOutline;
    const hasLeadingIcon = this.leadingIconContent;
    const showAdjacentLabel = hasLabel && !this.floatLabel;
    const classes = {
      'mdc-select': true,
      'mdc-select--outlined': hasOutline,
      'mdc-select--with-adjacent-label': showAdjacentLabel,
    };

    return html`
      <div class="mdc-select-wrapper">
        <div class="${classMap(classes)}" .ripple="${!hasOutline && ripple({ unbounded: false })}">
          <input type="hidden" name="enhanced-select" />
          ${hasLeadingIcon ? this._renderLeadingIcon() : ''} ${this._renderDropdownIcon()}
          ${this._renderSelectedText()}
          <slot></slot>
          ${!showAdjacentLabel && hasLabelAndIsNotOutlined ? this._renderFloatingLabel() : ''}
          ${hasOutline ? this._renderNotchedOutline(showAdjacentLabel) : this._renderLineRipple()}
        </div>
        ${this._renderHelperText()} ${showAdjacentLabel ? this._renderAdjacentLabel() : ''}
      </div>
    `;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  async firstUpdated() {
    if (this.menuEl) {
      await this.whenDefined();
    }
    if (this.selectEl) {
      this._nativeControl = this.selectEl;
    }

    this.formElement.id = this._formElementId;
    if (this.required) {
      this.formElement.setAttribute('required', 'true');
    }
    this._label = this.labelElement ? labelFactory(this.labelElement) : null;
    this._lineRipple = this.lineRippleElement ? lineRippleFactory(this.lineRippleElement) : null;
    this._outline = this.outlineElement ? outlineFactory(this.outlineElement) : null;
    this._helperText = this.helperTextElement ? helperTextFactory(this.helperTextElement) : null;

    if (this.leadingIconElement) {
      this.mdcRoot.classList.add(cssClasses.WITH_LEADING_ICON);
      this._leadingIcon = iconFactory(this.leadingIconElement);
    }

    // The required state needs to be sync'd before the mutation observer is added.
    this._initialSyncRequiredState();
    this._addMutationObserverForRequired();

    super.firstUpdated();

    this.formElement.addEventListener('focus', this._handleFocus);
    this.formElement.addEventListener('blur', this._handleBlur);

    POINTER_EVENTS.forEach(evtType => {
      this.formElement.addEventListener(evtType, this._handleClick);
    });

    if (this.menuEl) {
      this._selectedText!.addEventListener('keydown', this._handleKeydown);
      this.menuEl.addEventListener(
        menuSurfaceConstants.strings.CLOSED_EVENT,
        this._handleMenuClosed,
      );
      this.menuEl.addEventListener(
        menuSurfaceConstants.strings.OPENED_EVENT,
        this._handleMenuOpened,
      );

      if (!this.multiple) {
        this.menuEl.addEventListener(MENU_EVENTS.selected, this._handleMenuSelected);
      } else {
        this.menuEl.addEventListener('change', this._handleMenuChange);
      }

      setTimeout(async _ => {
        await this.updateComplete;
        await this.menuEl!.updateComplete;
        this._enhancedSelectSetup();
      });

      if (this.leadingIconElement) {
        this.menuEl.classList.add(cssClasses.WITH_LEADING_ICON);
      }
    }

    if (this.selectEl) {
      this.selectEl.addEventListener('change', this._handleNativeChange);

      this._nativeSelectSetup();
    }

    setTimeout(() => this.layout());

    // set initial value
    const val = this.value;
    if (typeof val === 'string') {
      if (val !== '') {
        this._previousValue = val; // prevent initial value from creating change event
        this.mdcFoundation.setValue(val);
      } else if (this.selectEl) {
        const options = [...this.selectEl!.options];
        const selectedIndex = options.findIndex(item => item.value === '');
        this._setSelectedIndex(selectedIndex);
      }
    }
  }

  /**
   * Create and attach the MDC Foundation to the instance
   */
  createFoundation() {
    if (this.mdcFoundation !== undefined) {
      this.mdcFoundation.destroy();
    }

    this.mdcFoundation = new this.mdcFoundationClass(
      this.createAdapter(),
      this._getFoundationMap(),
    );
    this.mdcFoundation.init();

    // Override setSelectedIndex in order to avoid closing menu on multiple selection
    this.mdcFoundation.setSelectedIndex = index => {
      if (!this._isValidIndex(index)) return; // Return early

      this.mdcFoundation['adapter_'].setSelectedIndex(index);
      if (!this.multiple) {
        this.mdcFoundation['adapter_'].closeMenu();
      }
    };
  }

  /**
   * Handle native `change` event
   */
  protected _onNativeChange(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    setTimeout(() => {
      this.mdcFoundation.setSelectedIndex(this.selectedIndex as number);
      window && window.focus(); // Fixes IE11 selection
    });
  }

  /**
   * Handle focus event
   */
  protected _onFocus(evt) {
    this.mdcFoundation.handleFocus();
    emit(this.mdcRoot, evt.type, {}, true);
  }

  /**
   * Handle blur event
   */
  protected _onBlur(evt) {
    this.mdcFoundation.handleBlur();
    emit(this.mdcRoot, evt.type, {}, true);
  }

  /**
   * Handle click event
   */
  protected _onClick(evt) {
    if (this._selectedText) {
      this._selectedText.focus();
    }
    this.mdcFoundation.handleClick(this._getNormalizedXCoordinate(evt));
  }

  /**
   * Handle keydown event
   */
  protected _onKeydown(evt) {
    this.mdcFoundation.handleKeydown(evt);
  }

  /**
   * Handle menu opened event
   */
  protected _onMenuOpened() {
    this.menuLayout();

    if (this.menuEl!.items.length === 0 || Array.isArray(this.selectedIndex)) {
      return;
    }

    // Menu should open to the last selected element, should open to first menu item otherwise.
    const focusItemIndex = this.selectedIndex >= 0 ? this.selectedIndex : 0;
    const focusItemEl = this.menuEl!.items[focusItemIndex as number] as HTMLElement;
    focusItemEl.focus();
  }

  /**
   * Handle menu closed event
   */
  protected _onMenuClosed() {
    const activeElement = (this as any).getRootNode().activeElement;

    // _isMenuOpen is used to track the state of the menu opening or closing since the menu.open function
    // will return false if the menu is still closing and this method listens to the closed event which
    // occurs after the menu is already closed.
    this._isMenuOpen = false;
    this._selectedText!.removeAttribute('aria-expanded');

    if (activeElement !== this) {
      this.mdcFoundation.handleBlur();
    }
  }

  /**
   * Handle menu selected event
   */
  protected _onMenuSelected() {
    const selectedItem = this.listItems![this.selectedIndex as number];
    this._setTextContent(this.getListItemText(selectedItem));
    this._setValue(selectedItem.value);
  }

  protected getListItemText(listItem: MWCListItem): string {
    const list = this.listEl;
    const isTwoLine = list && list.type === 'two-line';
    if (isTwoLine) {
      const primarySlot = listItem.querySelector('[slot="primary"]');
      if (primarySlot) {
        return (primarySlot.textContent || '').trim();
      }
      return '';
    }
    return (listItem.textContent || '').trim();
  }

  /**
   * Handle menu change event
   */
  protected _onMenuChange(e: CustomEvent) {
    e.stopImmediatePropagation();
    e.preventDefault();
    setTimeout(() => {
      let nextTextContent = '';
      let nextValue: any = '';

      if (this.selectedIndex !== -1) {
        const isNumber = typeof this.selectedIndex === 'number';

        if (isNumber) {
          nextTextContent = this.getListItemText(this.listItems[this.selectedIndex as number]);
          nextValue = this.listItems[this.selectedIndex as number].value;
        } else {
          const selectedIndex = this.selectedIndex as number[];

          nextTextContent = selectedIndex
            .sort()
            .map(i => this.getListItemText(this.listItems[i]))
            .join(', ');

          nextValue = selectedIndex.sort().map(i => this.listItems[i].value);
        }
      }

      this._setTextContent(nextTextContent);
      this._setValue(nextValue);
    });
  }

  protected _isValidIndex(index: MDCListIndex) {
    const listItemsCount = this.menuEl ? this.menuEl!.items.length : this.selectEl!.options.length;

    return Array.isArray(index) ? !index.find(i => i >= listItemsCount) : index < listItemsCount;
  }

  protected _setTextContent(value: string) {
    if (this._selectedTextInner!.textContent === value) return;

    this._selectedTextInner!.textContent = value;

    if (this._placeholderEl) {
      this._placeholderEl.classList.toggle('mdc-select__placeholder--hidden', Boolean(value));
    }
  }

  protected _getTextContent() {
    return this._selectedTextInner && this._selectedTextInner!.textContent
      ? this._selectedTextInner.textContent.trim()
      : '';
  }

  /**
   * Handles setup for the native control
   */
  protected _nativeSelectSetup() {
    this.selectEl!.style.height = '100%';
    this.selectEl!.style.left = '0';
    this.selectEl!.style.opacity = '0';
    this.selectEl!.style.position = 'absolute';
    this.selectEl!.style.top = '0';
    this.selectEl!.style.width = '100%';
    this.selectEl!.style.zIndex = '1';
    this.selectEl!.style.cursor = 'pointer';
    this.selectEl!.style.font = getComputedStyle(this._selectedText).font;
    this.selectEl!.style.fontSize = getComputedStyle(this._selectedText).fontSize;
    this.selectEl!.style.padding = getComputedStyle(this._selectedText).padding;
  }

  /**
   * Handles setup for the enhanced menu.
   */
  protected _enhancedSelectSetup() {
    const isDisabled = this.mdcRoot.classList.contains(cssClasses.DISABLED);
    this._selectedText!.setAttribute('tabindex', isDisabled ? '-1' : '0');
    this.menuEl!.setAnchorCorner(menuSurfaceConstants.Corner.BOTTOM_START);
    this.menuEl!.setAnchorElement(this.mdcRoot);
    this.menuEl!.wrapFocus = false;
    this.menuEl!.singleSelection = !this.multiple;

    this.wrapper!.appendChild(this.slotEl!);
  }

  /**
   * Calculates where the line ripple should start based on the x coordinate within the component.
   */
  protected _getNormalizedXCoordinate(evt: MouseEvent | TouchEvent): number {
    const targetClientRect = (evt.target as Element).getBoundingClientRect();
    const xCoordinate = this.isTouchEvent_(evt) ? evt.touches[0].clientX : evt.clientX;
    return xCoordinate - targetClientRect.left;
  }

  protected isTouchEvent_(evt: MouseEvent | TouchEvent): evt is TouchEvent {
    return Boolean((evt as TouchEvent).touches);
  }

  protected _setValidity(isValid: boolean) {
    if (this._helperText && this.validationMessage) {
      this.mdcFoundation &&
        this.mdcFoundation.setHelperTextContent(
          isValid ? this.helperTextContent : this.validationMessage,
        );
      this._helperText.foundation.setValidation(!isValid);
      this.requestUpdate();
    }
  }

  protected _setValue(value: string | string[]) {
    this.value = value;
    if (this._previousValue !== value) {
      let selectedIndex = this.selectedIndex;
      if (this.multiple && !Array.isArray(selectedIndex)) {
        selectedIndex = selectedIndex === -1 ? [] : [selectedIndex];
      }
      emit(this, 'change', { value, selectedIndex: selectedIndex }, true);
    }
    this._previousValue = value;
  }

  protected _getValue() {
    if (Array.isArray(this.selectedIndex)) {
      // const selectedItems = (listEl!.selectedIndex as number[]).map(i => listItems[i]);
      // const text = selectedItems.map(item => item.textContent!.trim()).join(', ');
      // this._setTextContent(text);
      // value = selectedItems.map(item => item.value).join(',');

      return;
    }
  }

  /**
   * Returns a map of all subcomponents to subfoundations.
   */
  protected _getFoundationMap(): Partial<MDCSelectFoundationMap> {
    return {
      helperText: this._helperText ? this._helperText.foundation : undefined,
      leadingIcon: this._leadingIcon ? this._leadingIcon.foundation : undefined,
    };
  }

  protected async _setNativeSelectedIndex(index: number) {
    const options = this.selectEl!.options;
    let value: string;
    [...options].forEach(item => (item.selected = false));
    if (index === -1) {
      this._setTextContent('');
      value = '';

      if (this.mdcLabel) {
        this.mdcLabel.classList.remove('mdc-floating-label--float-above');
      }
    } else {
      const selectedOption = this.selectEl!.options[index];
      selectedOption.selected = true;
      value = selectedOption.value;
      this._setTextContent(selectedOption.textContent as string);
    }

    this._nativeControl!.value = value;
    this._setValue(value);
  }

  protected async _setEnhancedSelectedIndex(index: MDCListIndex) {
    if (!this.listEl) return; // Return early

    await this.listEl.updateComplete;

    let selectedIndex: MDCListIndex = index;
    let value: string | string[] = '';
    let text = '';

    if (typeof index === 'number' && this.multiple) {
      selectedIndex = index !== -1 ? [index] : [];
    }

    this.listEl.selectedIndex = selectedIndex;

    if (typeof selectedIndex === 'number') {
      const selectedItem = index !== -1 && this.listItems[index as number];

      if (selectedItem) {
        value = selectedItem.value;
        text = this.getListItemText(selectedItem);
      }
    } else {
      const selectedItems = (selectedIndex as number[]).map(i => this.listItems[i]);

      if (selectedItems.length > 0) {
        text = selectedItems.map(item => this.getListItemText(item)).join(', ');
        value =
          selectedItems.length > 1 ? selectedItems.map(item => item.value) : selectedItems[0].value;
      }
    }

    this._setTextContent(text);
    this._setValue(value);
  }

  protected _initialSyncRequiredState() {
    const isRequired =
      (this.formElement as HTMLSelectElement).required ||
      this.formElement.getAttribute('aria-required') === 'true' ||
      this.mdcRoot.classList.contains(cssClasses.REQUIRED);
    if (isRequired) {
      if (this._nativeControl) {
        this._nativeControl.required = true;
      }
      if (this._selectedText) {
        this._selectedText!.setAttribute('aria-required', 'true');
      }
      this.mdcRoot.classList.add(cssClasses.REQUIRED);
    }
  }

  protected _addMutationObserverForRequired() {
    const observerHandler = (attributesList: string[]) => {
      attributesList.some(attributeName => {
        if (VALIDATION_ATTR_WHITELIST.indexOf(attributeName) === -1) {
          return false;
        }

        if (this._selectedText) {
          if (this._selectedText.getAttribute('aria-required') === 'true') {
            this.mdcRoot.classList.add(cssClasses.REQUIRED);
          } else {
            this.mdcRoot.classList.remove(cssClasses.REQUIRED);
          }
        } else {
          if (this._nativeControl!.required) {
            this.mdcRoot.classList.add(cssClasses.REQUIRED);
          } else {
            this.mdcRoot.classList.remove(cssClasses.REQUIRED);
          }
        }

        return true;
      });
    };

    const getAttributesList = (mutationsList: MutationRecord[]): string[] => {
      return mutationsList
        .map(mutation => mutation.attributeName)
        .filter(attributeName => attributeName) as string[];
    };
    const observer = new MutationObserver(mutationsList =>
      observerHandler(getAttributesList(mutationsList)),
    );
    observer.observe(this.formElement, { attributes: true });
    this._validationObserver = observer;
  }

  protected _getSelectedIndex(): MDCListIndex {
    if (this.menuEl && this.listEl) {
      const selectedIndex = this.listEl.selectedIndex;

      if (typeof selectedIndex === 'number') {
        return selectedIndex;
      }

      if (selectedIndex.length === 0) {
        return -1;
      }

      return selectedIndex.length > 1 ? selectedIndex : selectedIndex[0];
    }

    if (this._nativeControl) {
      return this._nativeControl.selectedIndex;
    }

    return -1;
  }

  protected _setSelectedIndex(value: MDCListIndex) {
    // Return early if value is equal than current selected index
    if (JSON.stringify(this.selectedIndex) === JSON.stringify(value)) {
      return;
    }

    // Passing value as number due to foundation parameter type
    this.mdcFoundation.setSelectedIndex(value as number);
  }

  /**
   * Recomputes the outline SVG path for the outline element.
   */
  public layout() {
    this.mdcFoundation.layout();
  }

  /**
   * Recomputes Menu position
   */
  public menuLayout() {
    const menuRoot = this.menuEl!.shadowRoot!.querySelector('.mdc-menu')! as HTMLElement;
    const marginTop = getComputedStyle(this.mdcRoot).marginTop;
    const marginBottom = this.helperTextElement
      ? this.helperTextElement.getBoundingClientRect().height +
        Number((getComputedStyle(this.helperTextElement) as any).marginTop.replace('px', ''))
      : 0;

    menuRoot.style.marginTop = marginTop;
    menuRoot.style.marginBottom = `${marginBottom}px`;
  }
}

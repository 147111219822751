/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  property,
  observer,
  query,
  emit,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import { TabBase } from './mwc-tab-base';
import { TabScrollerBase } from './mwc-tab-scroller-base';
import MDCTabBarFoundation from '@material/tab-bar/foundation';
import { MDCTabBarAdapter } from '@material/tab-bar/adapter';
import { MDCTabInteractionEvent } from '@material/tab/types';

export const EVENTS = {
  activated: 'activated'
};

export class TabBarBase extends BaseElement {

  protected mdcFoundation!: MDCTabBarFoundation;

  protected readonly mdcFoundationClass = MDCTabBarFoundation;

  @query('.mdc-tab-bar')
  protected mdcRoot!: HTMLElement

  @query('gsk-mwc-tab-scroller')
  protected scrollerElement!: TabScrollerBase

  @query('slot')
  protected tabsSlot!: HTMLSlotElement

  /**
   * Optional. Default value is 0. Sets the active tab on initial render.
   * Enter the index number of the tab you want activated.
   * Index starts at 0.
   */
  @observer(async function (this: TabBarBase, value: number) {
    await this.updateComplete;
    // only provoke the foundation if we are out of sync with it, i.e.
    // ignore an foundation generated set.
    if (value !== this._previousActiveIndex) {
      this.mdcFoundation.activateTab(value);
    }
  })
  @property({ type: Number })
  activeIndex = 0;

  protected _previousActiveIndex = -1;

  protected _handleTabInteraction(e: MDCTabInteractionEvent) {
    this.mdcFoundation.handleTabInteraction(e);
  }

  protected _handleKeydown(e: KeyboardEvent) {
    this.mdcFoundation.handleKeyDown(e);
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   *
   * TODO(sorvell): can scroller be optional for perf?
   */
  render(): TemplateResult {
    return html`
      <div class="mdc-tab-bar" role="tablist" @interacted="${this._handleTabInteraction}" @keydown="${this._handleKeydown}">
        <gsk-mwc-tab-scroller>
          <slot></slot>
        </gsk-mwc-tab-scroller>
      </div>
      `;
  }

  // TODO(sorvell): probably want to memoize this and use a `slotChange` event
  protected _getTabs() {
    return this.tabsSlot.assignedNodes({ flatten: true }).filter((e: unknown) => e instanceof TabBase) as TabBase[];
  }

  protected _getTab(index) {
    return this._getTabs()[index];
  }

  /**
   * Create the adapter for the `mdcFoundation`.
   *
   * Override and return an object with the Adapter's functions implemented
   */
   /* istanbul ignore next */
  createAdapter(): MDCTabBarAdapter {
    return {
      scrollTo: (scrollX: number) => this.scrollerElement.scrollToPosition(scrollX),
      incrementScroll: (scrollXIncrement: number) => this.scrollerElement.incrementScrollPosition(scrollXIncrement),
      getScrollPosition: () => this.scrollerElement.getScrollPosition(),
      getScrollContentWidth: () => this.scrollerElement.getScrollContentWidth(),
      getOffsetWidth: () => this.mdcRoot.offsetWidth,
      isRTL: () => window.getComputedStyle(this.mdcRoot).getPropertyValue('direction') === 'rtl',
      setActiveTab: (index: number) => this.mdcFoundation.activateTab(index),
      activateTabAtIndex: (index: number, clientRect: ClientRect) => {
        const tab = this._getTab(index);
        if (tab !== undefined) {
          tab.activate(clientRect);
        }
        this._previousActiveIndex = index;
      },
      deactivateTabAtIndex: (index: number) => {
        const tab = this._getTab(index);
        if (tab !== undefined) {
          tab.deactivate()
        }
      },
      focusTabAtIndex: (index: number) => {
        const tab = this._getTab(index);
        if (tab !== undefined) {
          tab.focus();
        }
      },
      // TODO(sorvell): tab may not be able to synchronously answer `computeIndicatorClientRect`
      // if an update is pending or it has not yet updated. If this is necessary,
      // LitElement may need a `forceUpdate` method.
      getTabIndicatorClientRectAtIndex: (index: number) => {
        const tab = this._getTab(index);
        return tab !== undefined ? tab.computeIndicatorClientRect() : new DOMRect();
      },
      getTabDimensionsAtIndex: (index: number) => {
        const tab = this._getTab(index);
        return tab !== undefined ? tab.computeDimensions() :
          { rootLeft: 0, rootRight: 0, contentLeft: 0, contentRight: 0 };
      },
      getPreviousActiveTabIndex: () => {
        return this._previousActiveIndex;
      },
      getFocusedTabIndex: () => {
        const tabElements = this._getTabs();
        const activeElement = (this as any).getRootNode().activeElement;
        return tabElements.indexOf(activeElement);
      },
      getIndexOfTabById: (id: string) => {
        const tabElements = this._getTabs();
        for (let i = 0; i < tabElements.length; i++) {
          if (tabElements[i].id === id) {
            return i;
          }
        }
        return -1;
      },
      getTabListLength: () => this._getTabs().length,
      notifyTabActivated: (index: number) => {
        // Synchronize the tabs `activeIndex` to the foundation.
        // This is needed when a tab is changed via a click, for example.
        this.activeIndex = index;
        emit(this, EVENTS.activated, { index }, true);
      }
    };
  }

  /**
   * NOTE: Delay creating foundation until scroller is fully updated.
   * This is necessary because the foundation/adapter synchronously addresses
   * the scroller element.
   */
  firstUpdated() { }

  /**
   * Returns a Promise that resolves when the element has completed updating.
   * The Promise value is a boolean that is `true` if the element completed the update without triggering another update.
   * The Promise result is `false` if a property was set inside `updated()`.
   * If the Promise is rejected, an exception was thrown during the update.
   *
   * @returns {Promise} The Promise returns a boolean that indicates if the
   * update resolved without triggering another update.
   */
  get updateComplete(): any {
    return super.updateComplete
      .then(() => this.scrollerElement.updateComplete)
      .then(() => {
        if (this.mdcFoundation === undefined) {
          this.createFoundation();
        }
      });
  }

  /**
   * Scrolls the tab at the given index into view.
   */
  scrollIndexIntoView(index: number) {
    this.mdcFoundation.scrollIntoView(index);
  }
}

import { LinearProgressBase as MWCLinearProgress } from './mwc-linear-progress-base';
import { gskStyle as mwcStyle } from './mwc-linear-progress-css';
import { property, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-linear-progress-css';

export class LinearProgressBase extends MWCLinearProgress {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: String })
  label;

  render(): TemplateResult {
    return html`
    <div class="mdc-linear-progress__container">
      <div role="progressbar" class="mdc-linear-progress">
        <div class="mdc-linear-progress__buffering-dots"></div>
        <div class="mdc-linear-progress__buffer">
          <span class="mdc-linear-progress__buffer-inner"></span>
        </div>
        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
        <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
      </div>
      ${this._renderLabel()}
    </div>
    `;
  }

  protected _renderLabel() {
    return this.label
    ? html`<span class="mdc-linear-progress__label">${this.label}</span>`
    : html``;
  }
}

import { TopAppBarBase as MWCTopAppBar } from './mwc-top-app-bar-base';
import { gskStyle as mwcStyle } from './mwc-top-app-bar-css';
import { property, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-top-app-bar-css';

export class TopAppBarBase extends MWCTopAppBar {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: String })
  /**
   * Optional. Use this property to set up the media background image.
   */
  public image = '';

  /**
   * Optional. Use this property to set up how to display the top-app-bar.
   * Use any of the following type variants: 'fixed', 'prominent', 'short', 'shortCollapsed' and 'prominentFixed'
   */
  @property({ type: String })
  public type = '';

  /**
   * Default image path to be used is window.location.origin/assets/img/
   */
  public imgPath = `${window.location.origin}/assets/img/`;

  /**
   * This method is invoked whenever the top-app-bar is updated
   *
   * @param _changedProperties Map of changed properties with old values
   */
  public updated(changedProperties): void {
    super.updated(changedProperties);
    if ((this.type === 'prominent' || this.type === 'prominentFixed') && this.image !== '') {
      this.mdcRoot.style.backgroundImage = `url('${this.imgPath + this.image}')`;
    }
  }
}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { ButtonBase } from './gsk-button-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-button': Button;
  }
}

@customElementDefinition('gsk-button')
export class Button extends ButtonBase {}

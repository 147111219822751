import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { RippleBase } from './gsk-ripple-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-ripple': Ripple;
  }
}

@customElementDefinition('gsk-ripple')
export class Ripple extends RippleBase {}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  query,
  property,
  addHasRemoveClass,
  PropertyValues,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import { styleMap } from 'lit/directives/style-map.js';
import { MDCTooltipFoundation } from './foundation';
import { MDCTooltipAdapter } from './adapter';

export class TooltipBase extends BaseElement {
    @query('.mdc-tooltip')
    protected mdcRoot!: HTMLElement;

    /**
     * Optional. Use this property to specifies which element the tooltip is bound to
     */
    @property({ type: String })
    for = '';

    /**
     * Mandatory. Default value is 'Tooltip Placeholder'. Use this property to sets the text to display in the tooltip
     */
    @property({ type: String })
    text = 'Tooltip Placeholder';

    /**
     * Optional. Default value is 'below'. This is where the tooltip displays relative to the element triggering the tooltip hover. Options are: 'above', 'below', 'before' and 'after'
     */
    @property({ type: String })
    placement = 'below';

    /**
     * Optional. This property will be implemented in a next release
     */
    @property({ type: Boolean })
    open = false; //NOT IMPLEMENTED

    /**
     * Optional. Default value is 1500. This property value adds an additional delay on the hover of the tooltip once triggered.
     */
    @property({ type: Number })
    showDelay = 1500;

    /**
     * Optional. This property will be implemented in a next release
     */
    @property({ type: Number })
    hideDelay = 0; //NOT IMPLEMENTED

    /**
     * Optional. Default value is 20. Used this property to sets the margin space for the tooltip according to 'placement' choose
     */
    @property({ type: Number })
    gap = 20;

    /**
     * Optional. Default value is 0. This property is used to sets the offset distance between the tooltip and the element which is bound to
     */
    @property({ type: Number })
    offset = 0;

    protected mdcFoundation!: MDCTooltipFoundation;

    protected readonly mdcFoundationClass = MDCTooltipFoundation;

    protected _preventClose = false;
    protected controller_:HTMLElement|null = this.mdcRoot;
    protected _handleKeydown;
    protected _handleClick;
    protected createAdapter(): MDCTooltipAdapter {
        return {
            ...addHasRemoveClass(this.mdcRoot),
            addClass: (className) => this.mdcRoot.classList.add(className),
            removeClass: (className) => this.mdcRoot.classList.remove(className),
            getRootWidth: () => this.mdcRoot.offsetWidth,
            getRootHeight: () => this.mdcRoot.offsetHeight,
            getControllerWidth: () => this.controller_!.offsetWidth,
            getControllerHeight: () => this.controller_!.offsetHeight,
            getControllerBoundingRect: () => this.controller_!.getBoundingClientRect(),
            getClassList: () => this.classList,
            setStyle: (propertyName, value) => this.mdcRoot.style.setProperty(propertyName, value),
        }
    }

    /**
     * Invoked when the element is first updated. Implement to perform one time
     * work on the element after update.
     *
     * Setting properties inside this method will trigger the element to update
     * again after this update cycle completes.
     */
    firstUpdated() {
        super.firstUpdated();
        this.controller_ = this.for === '' ? this.parentElement : this.parentElement!.querySelector(`#${this.for}`);
        this.initListeners();
    }

    /**
     * This method is invoked whenever the Tooltip is updated
     *
     * @param _changedProperties Map of changed properties with old values
     */
    updated(_changedProperties: PropertyValues) {
        super.updated(_changedProperties);

        this.mdcFoundation.showDelay = this.showDelay;

        // this does not actually exist in the foundation...
        // this.mdcFoundation.hideDelay = this.hideDelay;

        this.mdcFoundation.gap = this.gap;
        this.mdcFoundation.placement = this.placement;
    }

    /**
     * Used to render the lit-html TemplateResult to the element's DOM
     */
    render(): TemplateResult {
        const {
            direction,
            offset
        } = this._getTransformOffset();

        const styles = {
            [direction]: offset
        };

        return html`
            <div class="mdc-tooltip" tabindex="-1" style="${styleMap(styles)}">
                ${this.text}
            </div>
        `;
    }

    protected _getTransformOffset(): { direction: string, offset: string } {
        switch(this.placement) {
            case 'before':
                return {
                    direction: 'marginLeft',
                    offset: `${this.offset * -1}px`
                };
            case 'above':
                return {
                    direction: 'marginTop',
                    offset: `${this.offset * -1}px`
                };
            case 'after':
                return {
                    direction: 'marginLeft',
                    offset: `${this.offset}px`
                };
            default:
                return {
                    direction: 'marginTop',
                    offset: `${this.offset}px`
                };
        }
    }

    /**
     * Used this method to show the tooltip
     */
    show() {
        this.mdcFoundation.show();
    }

    /**
     * Used this method to hide the tooltip
     */
    hide() {
        this.mdcFoundation.hide();
    }

    /**
     * Used this method to add all the event listeners to the tooltip
     */
    initListeners() {
        if (this.controller_ !== null) {
            this.controller_.addEventListener('blur', this.mdcFoundation.handleBlur.bind(this.mdcFoundation));
            this.controller_.addEventListener('click', this.mdcFoundation.handleClick.bind(this.mdcFoundation));
            this.controller_.addEventListener('focus', this.mdcFoundation.handleFocus.bind(this.mdcFoundation));
            this.controller_.addEventListener('mouseleave', this.mdcFoundation.handleMouseLeave.bind(this.mdcFoundation));
            this.controller_.addEventListener('mouseenter', this.mdcFoundation.handleMouseEnter.bind(this.mdcFoundation));
            this.controller_.addEventListener('touchstart', this.mdcFoundation.handleTouchStart.bind(this.mdcFoundation));
            this.controller_.addEventListener('touchend', this.mdcFoundation.handleTouchEnd.bind(this.mdcFoundation));
        }
    }

    /**
     * Used this method to remove all the listeners from the tooltip
     */
    destroy() {
        if (this.controller_ !== null) {
            this.controller_.removeEventListener('blur', this.mdcFoundation.handleBlur);
            this.controller_.removeEventListener('click', this.mdcFoundation.handleClick);
            this.controller_.removeEventListener('focus', this.mdcFoundation.handleFocus);
            this.controller_.removeEventListener('mouseenter', this.mdcFoundation.handleMouseEnter);
            this.controller_.removeEventListener('mouseleave', this.mdcFoundation.handleMouseLeave);
            this.controller_.removeEventListener('touchstart', this.mdcFoundation.handleTouchStart);
            this.controller_.removeEventListener('touchend', this.mdcFoundation.handleTouchEnd);
        }
    }

    /**
     * Gets the visible state of the tooltip
     */
    get visible() {
        return this.mdcFoundation.displayed_;
    }

    /**
     * Gets the width of the tooltip
     */
    get width(): number {
        this.mdcRoot.style.display = 'block';
        const width = this.mdcRoot.offsetWidth;
        this.mdcRoot.style.display = '';
        return width;
    }
}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  LitElement,
  query,
  classMap,
  property,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';

export class ListDividerBase extends LitElement {

  @query('.mdc-list-divider')
  protected mdcRoot!: HTMLElement;

  /**
   * Optional. Default value is false. Use this property in order to set a left margin to the divider
   */
  @property({ type: Boolean })
  public inset = false;

  /**
   * Optional. Default value is false. Use this property for the divider to not use the full width, leaving a little space on its left and right sides
   */
  @property({ type: Boolean })
  public padded = false;

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const classes = {
      'mdc-list-divider': true,
      'mdc-list-divider--inset': this.inset,
      'mdc-list-divider--padded': this.padded
    };

    return html`
      <div class="${classMap(classes)}"></div>
    `;
  }
}

import { LitElement, query, property, PropertyValues, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { TemplateResult, html } from 'lit';
import { state } from 'lit/decorators.js';
import { classMap } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-popover-css';
import { createPopper } from '@popperjs/core';

export class PopoverBase extends LitElement {
  public static get styles() {
    return unsafeCSS(gskStyle);
  }

  @query('.popover')
  protected mdcRoot!: HTMLElement;

  /**
   * Optional. Used this property to specifies which element the popover is bound to. Only supports ID names.
   */
  @property({ type: String })
  public for = '';

  @property({ type: String })
  public strategy: 'absolute' | 'fixed' = 'absolute';

  /**
   * Optional. Default value is 'auto'. Use this property to set the popover position,
   * where the value is a composition between 'verticalPosition' and 'horizontalPosition', for example: 'bottom-left'
   * Allow values for 'verticalPosition': top or bottom
   * Allow values for 'horizontalPosition': left, right or center
   *
   * '[vertPos]-[horizPos]' | 'auto'
   *
   */
  @property({ type: String })
  public position = 'auto';

  /**
   * Optional. Shows popover when any of the specified events are triggered
   * from the anchor element.
   *
   * Otherwise you'll need to call open yourself
   */
  @property({ type: Array })
  public showEvents: (keyof HTMLElementEventMap)[] = [];

  /**
   * Optional. Hides popover when any of the specified events are triggered
   * from the anchor element.
   *
   * Otherwise you'll need to call close yourself
   */
  @property({ type: Array })
  public hideEvents: (keyof HTMLElementEventMap)[] = [];

  public popper: any = null;

  /**
   * Optional. Default size is auto. You can set the value to 'sm', 'md' and 'lg' to control it.
   */
  @property({ type: String })
  public size: 'sm' | 'md' | 'lg' | 'fullscreen' | '' = '';

  /**
   * Optional. Default size is 8. Use it to control the space between the trigger and the popover.
   */
  @property({ type: Number })
  public gap = 8;

  @state()
  protected anchorElement: HTMLElement | null = null;

  // element that popover is anchored to
  @state()
  protected controller_: HTMLElement | null = this.mdcRoot;

  @state()
  protected popoverIsOpen: boolean = false;

  /**
   * converts our original popover position string to a popperjs equivalent
   * with a dumb strategy of converting:
   *
   * -left   --> -start
   * -right  --> -end
   * -center --> [removed]
   */
  public get popoverPosition(): any{
    return this.position
      .replace('-center', '')
      .replace('-left', '-start')
      .replace('-right', '-end');
  }

  protected show() {
    this.popoverIsOpen = true;

    this.popper.setOptions({
      modifiers: this.modifiers,
    });

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popper.update();
  }

  protected hide() {
    this.popoverIsOpen = false;

    this.popper.setOptions({
      modifiers: this.modifiers,
    });
  }

  protected get modifiers() {
    return [
      { name: 'eventListeners', enabled: this.popoverIsOpen },
      {
        name: 'offset',
        options: {
          offset: [0, this.gap],
        },
      },
    ];
  }

  /**
   * Use this method to close the popover
   */
  public close(): void {
    // todo: call popper function
    this.hide();
  }

  public get isOpen(): boolean {
    return this.popoverIsOpen;
  }

  public set isOpen(value: boolean) {
    this.popoverIsOpen = value;
    this.requestUpdate();
  }

  protected setAnchor() {
    if (this.anchorElement) {
      this.controller_ = this.anchorElement;
    } else {
      if (this.for === '') {
        this.controller_ = this.parentElement;
      } else {
        this.controller_ = this.parentElement!.querySelector(`#${this.for}`);
      }
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.setAnchor();
    this.setup();
  }

  /**
   * Use this method to set position for popover using PopperJs.
   */
  protected setup() {
    if (this.controller_) {
      const cont = this.controller_;
      this.popper = createPopper(this.controller_, this.mdcRoot, {
        placement: this.popoverPosition,
        strategy: this.strategy,
        modifiers: [
          {
            name: 'eventListeners',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: [0, this.gap],
            },
          },
        ],
      });

      this.showEvents.forEach(event => {
        cont.addEventListener(event, () => this.show());
      });

      this.hideEvents.forEach(event => {
        cont.addEventListener(event, () => this.hide());
      });
    }
  }

  /**
   * Use this method to open the popover.
   */
  public async open(): Promise<any> {
    this.show();
  }

  /**
   * @deprecated now this does nothing and will be removed in next major version
   */
  public setPosition() {}

  protected render(): TemplateResult {
    const { size } = this;

    const popoverClasses = {
      popover: true,
      'popover--open': this.popoverIsOpen,
      // 'popover--opening': opening,
      'popover--transition': true,
      'popover--sm': size === 'sm',
      'popover--md': size === 'md',
      'popover--lg': size === 'lg',
      'popover--fullscreen': size === 'fullscreen',
    };

    return html`
      <aside class="${classMap(popoverClasses)}">
        <slot></slot>
      </aside>
    `;
  }

  /**
   * Sets the element that the popover is anchored to.
   */
  public setAnchorElement(element: HTMLElement) {
    this.anchorElement = element;
    this.setAnchor();
    this.setup();
  }
}

import { CheckboxBase as MWCCheckbox } from './mwc-checkbox-base';
import { gskStyle as mwcStyle } from './mwc-checkbox-css';
import { property, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-checkbox-css';

export class CheckboxBase extends MWCCheckbox {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Optional. Default value is false. Removes the ripple effect on the Checkbox.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value is false. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = false;

  /**
   * Optional. Default value is false.
   * Use it to make the component required and activate native validation
   */
  @property({ type: Boolean, reflect: true })
  public required = false;

  /**
   * A read-only boolean property that returns true if the element is a candidate for constraint validation;
   * and false otherwise.
   */
  public get willValidate(): boolean {
    return this.formElement ? this.formElement.willValidate : false;
  }

  protected updated(changedProperties): void {
    super.updated(changedProperties);

    if (changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('mdc-checkbox--no-ripple', this.noripple);
    }

    if (changedProperties.has('primary')) {
      this.mdcRoot.classList.toggle('mdc-checkbox--primary', this.primary);
    }

    if (changedProperties.has('required')) {
      this.formElement.required = this.required;
    }
  }
}

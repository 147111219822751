

export const isServerSide = typeof window === 'undefined';

export function defineComponent(tagName: string, componentClass: any): void {
  if (!isServerSide) {
    window.customElements.define(tagName, componentClass);
  }
}
/*
export function importGskFonts(): void {
  const exists = document.getElementById('gskFontRef');
  if (!exists) {
    const gskFontEl = document.createElement('link');
    gskFontEl.id = 'gskFontRef';
    gskFontEl.rel = 'stylesheet';
    gskFontEl.href = 'https://i.icomoon.io/public/ec055fea87/GSKFont/style.css';
    document.head.appendChild(gskFontEl);
  }
}*/

/**
 * Get Active Element
 */
export function getActiveElement(target: ShadowRoot | Document = document, host = false) {
  if (target && !target.activeElement) {
    if (target instanceof Document) return document;
    return target.host;
  }

  let activeElement = target.activeElement;

  if (activeElement && activeElement.shadowRoot) {
    activeElement = getActiveElement(activeElement.shadowRoot);
    return host ? (activeElement!.getRootNode() as ShadowRoot).host : activeElement;
  }

  return activeElement;
}

// used for debugging load conflicts
const registryPath: Map<string, string> = new Map<string, string>();

export const ensureCustomElementLoaded = (tagName: string, currentTagName?: string) => (
  classDescriptor: any,
) => {
  if (!customElements.get(tagName)) {
    customElements.define(tagName, classDescriptor);
    if (currentTagName) {
      registryPath.set(tagName, currentTagName);
    }
    // console.log(`Component: ${tagName} registered by component ${currentTagName}`);
  }
};

export const customElementDefinition = (tagName: string, tags?: string[]) => (
  classDescriptor: any,
) => {
  if (tags) {
    tags.forEach(dependentTagName => {
      if (!customElements.get(dependentTagName)) {
        throw new Error(`Component: ${tagName} requires component ${dependentTagName}`);
      }
    });
  }
  if (!customElements.get(tagName)) {
    customElements.define(tagName, classDescriptor);
    // console.log(`${tagName} defined`);
    registryPath.set(tagName, 'self');
  } else {
    const parentTagName = registryPath.get(tagName) || 'unknown';
    console.log(`Component: ${tagName} was already registered by component ${parentTagName}`);
  }
};


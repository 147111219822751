/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  query,
  classMap,
  property,
  PropertyValues,
  observer,
  emit,
  findAssignedElements, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import  { html } from 'lit'
import { closest, matches } from '@material/dom/ponyfill';
import { MDCListAdapter } from '@material/list/adapter';
import { MDCListFoundation } from '@material/list/foundation';
import { MDCListIndex } from '@material/list/types';
import { cssClasses, strings } from '@material/list/constants';
import { ListItemBase as MWCListItem } from './mwc-list-item-base';
import { ListDividerBase as MWCListDivider } from './mwc-list-divider-base';
import { HTMLElementWithRipple } from '@gsk-tech/gsk-base/form-element';

export class ListBase extends BaseElement {

  @query('.mdc-list')
  protected mdcRoot!: HTMLElement;

  @query('slot')
  protected slotEl!: HTMLSlotElement;

  /**
   * Optional. Default value is false. Use this property to remove all the padding spaces for the list
   */
  @property({ type: Boolean })
  public group = false;

  /**
   * Optional. Default value is false. Use this property to remove all the padding spaces for the list
   */
  @property({ type: Boolean })
  public content = false;

  /**
   * Optional. Default value is 'single-line'. Use this property to set the maximum lines to be displayed for each list-item
   */
  @property({ type: String, reflect: true })
  public type = 'single-line';

  /**
   * Optional. The default value is set to false. Setting this property to true block any interaction with list items
   */
  @property({ type: Boolean })
  public nonInteractive = false;

  /**
   * Optional. Default value is false. This property helps to set up the avatar style for the list component
   */
  @property({ type: Boolean })
  public avatarList = false;

  /**
   * Optional. Default value is false. Adds a left space to the content of each list-item
   */
  @property({ type: Boolean })
  public offsetContent = false;

  /**
   * Optional. Default value is false. Adding this property sets the aria-orientation to 'horizontal'
   * Sets the list to an orientation causing the keys used for navigation to change.
   * true results in the Up/Down arrow keys being used.
   * false results in the Left/Right arrow keys being used
   */
  @property({ type: Boolean })
  @observer(function (this: ListBase, value: boolean) {
    if (!value) {
      this.removeAttribute(strings.ARIA_ORIENTATION);
    } else {
      this.setAttribute(
        strings.ARIA_ORIENTATION,
        strings.ARIA_ORIENTATION_HORIZONTAL
      );
    }

    this.mdcFoundation.setVerticalOrientation(!value);
  })
  public horizontal = false;

  /**
   * Optional. Default value is false. Sets the list to allow the up arrow on the first element to focus the last element of the list and vice versa.
   */
  @property({ type: Boolean })
  @observer(function (this: ListBase, value: boolean) {
    this.mdcFoundation.setWrapFocus(value);
  })
  public wrapFocus = false;

  /**
   * Optional. Default value is false. Sets the list to be a selection list. Enables the enter and space keys for selecting/deselecting a list item.
   */
  @property({ type: Boolean })
  @observer(function (this: ListBase, value: boolean) {
    this.mdcFoundation.setSingleSelection(value);
  })
  public singleSelection = false;

  /**
   * Gets the current selection state by returning selected index or list of indexes for checkbox based list.
   */
  /* istanbul ignore next */
  public get selectedIndex(): MDCListIndex {
    return this.mdcFoundation.getSelectedIndex();
  }

  /**
   * Sets the selection state to given index or list of indexes if it is checkbox based list.
   */
  public set selectedIndex(value: MDCListIndex) {
    this.mdcFoundation.setSelectedIndex(value);
    emit(this, 'action', { index: value }, true);
  }

  /**
   * Gets the slot list-item elements as an array
   */
  public get listElements(): MWCListItem[] {
    return this.slotEl && findAssignedElements(this.slotEl, 'gsk-mwc-list-item') as MWCListItem[];
  }

  /**
   * Gets the slot list-divider elements as an array
   */
  protected get listDividers(): MWCListDivider[] {
    return this.slotEl && findAssignedElements(this.slotEl, 'gsk-mwc-list-divider') as MWCListDivider[];
  }

  protected get selectors() {
    return {
      checkbox: 'gsk-mwc-checkbox',
      radio: 'gsk-mwc-radio',
      checkboxRadio: 'gsk-mwc-checkbox:not([disabled]), gsk-mwc-radio:not([disabled])',
      focusableChildElements: 'gsk-mwc-button:not([disabled]), gsk-mwc-radio:not([disabled]), gsk-mwc-checkbox:not([disabled]), a',
      childElementsToToggleTabIndex: 'gsk-mwc-button:not([disabled]), a'
    }
  }

  protected _handleKeyDown = this._onKeyDown.bind(this) as EventListenerOrEventListenerObject;

  protected _handleClick = this._onClick.bind(this) as EventListenerOrEventListenerObject;

  protected _handleFocusIn = this._onFocusIn.bind(this) as EventListenerOrEventListenerObject;

  protected _handleFocusOut = this._onFocusOut.bind(this) as EventListenerOrEventListenerObject;

  protected _handleSlotChange = this._onSlotChange.bind(this) as EventListenerOrEventListenerObject;

  protected mdcFoundation!: MDCListFoundation;

  protected readonly mdcFoundationClass = MDCListFoundation;

  /* istanbul ignore next */
  protected createAdapter(): MDCListAdapter {
    return {
      addClassForElementIndex: (index, className) => {
        const element = this.listElements[index];

        if (element) {
          switch (className) {
            case cssClasses.LIST_ITEM_SELECTED_CLASS:
              element.selected = true;
              break;
            case cssClasses.LIST_ITEM_ACTIVATED_CLASS:
              element.activated = true;
              break;
            case cssClasses.LIST_ITEM_DISABLED_CLASS:
              element.disabled = true;
              break;
            default:
              element.classList.add(className);
              break;
          }
        }
      },
      focusItemAtIndex: (index) => {
        const element = this.listElements[index] as HTMLElement | undefined;
        if (element) {
          element.focus();
        }
      },
      getAttributeForElementIndex: (index, attr) => this.listElements[index].getAttribute(attr),
      getFocusedElementIndex: () => {
        return this.listElements.indexOf(document.activeElement! as MWCListItem);
      },
      getListItemCount: () => this.listElements.length,
      hasCheckboxAtIndex: (index) => {
        const listItem = this.listElements[index];
        return !!listItem.querySelector(this.selectors.checkbox);
      },
      hasRadioAtIndex: (index) => {
        const listItem = this.listElements[index];
        return !!listItem.querySelector(this.selectors.radio);
      },
      isCheckboxCheckedAtIndex: (index) => {
        const listItem = this.listElements[index];
        const toggleEl = listItem.querySelector<HTMLInputElement>(this.selectors.checkbox);

        return toggleEl && toggleEl.parentElement === listItem
          ? toggleEl.checked
          : false;
      },
      isFocusInsideList: () => this.mdcRoot.contains(document.activeElement),
      isRootFocused: () => document.activeElement === this,
      notifyAction: (index) => emit(this, 'action', { index }, true),
      removeClassForElementIndex: (index, className) => {
        const element = this.listElements[index];

        if (element) {
          switch (className) {
            case cssClasses.LIST_ITEM_SELECTED_CLASS:
              element.selected = false;
              break;
            case cssClasses.LIST_ITEM_ACTIVATED_CLASS:
              element.activated = false;
              break;
            case cssClasses.LIST_ITEM_DISABLED_CLASS:
              element.disabled = false;
              break;
            default:
              element.classList.remove(className);
              break;
          }
        }
      },
      setAttributeForElementIndex: (index, attr, value) => {
        const element = this.listElements[index];
        if (element) {
          element.setAttribute(attr, value);
        }
      },
      setCheckedCheckboxOrRadioAtIndex: (index, isChecked) => {
        const currentSelectedIndex = this._getCheckboxSelectedIndex();
        const listItem = this.listElements[index];
        const toggleEl = listItem.querySelector<HTMLInputElement>(this.selectors.checkboxRadio);

        if (toggleEl && toggleEl.parentElement === listItem) {
          if (toggleEl!.shadowRoot) {
            const toggleRoot = toggleEl!.shadowRoot!.querySelector('.mdc-checkbox, .mdc-radio') as HTMLElementWithRipple;
            toggleRoot.ripple!.activate();
            toggleRoot.ripple!.deactivate();
          }

          toggleEl.checked = isChecked;

          const selectedIndex = this._getCheckboxSelectedIndex();

          if(currentSelectedIndex.join('|') != selectedIndex.join('|')) {
            emit(toggleEl, 'change', {index: selectedIndex}, true);
          }
        }
      },
      setTabIndexForListItemChildren: (listItemIndex, tabIndexValue) => {
        const element = this.listElements[listItemIndex];
        const listItemChildren = [ ...element.querySelectorAll(this.selectors.childElementsToToggleTabIndex) ];
        listItemChildren.forEach((el) => el.setAttribute('tabindex', tabIndexValue));
      },
    }
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    const classes = {
      'mdc-list': true,
      'mdc-list--group': this.group,
      'mdc-list--content': this.content,
      'mdc-list--two-line': this.type === 'two-line',
      'mdc-list--three-line': this.type === 'three-line',
      'mdc-list--non-interactive': this.nonInteractive,
      'mdc-list--avatar-list': this.avatarList
    }

    return html`
      <div class="${classMap(classes)}">
        <slot></slot>
      </div>
    `;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  public firstUpdated() {
    super.firstUpdated();

    this.addEventListener('keydown', this._handleKeyDown);
    this.addEventListener('click', this._handleClick);
    this.addEventListener('focusin', this._handleFocusIn);
    this.addEventListener('focusout', this._handleFocusOut);
    this.slotEl.addEventListener('slotchange', this._handleSlotChange);

    setTimeout(() => {
      this.layout();
      this.initializeListType();
    })
  }

  /**
   * This method is invoked whenever the List is updated
   *
   * @param _changedProperties Map of changed properties with old values
   */
  public updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('type')) {
      this.listElements.forEach(item => item.type = this.type);
    }

    if (_changedProperties.has('nonInteractive')) {
      this.listElements.forEach(item => item.nonInteractive = this.nonInteractive);
    }

    if (_changedProperties.has('avatarList')) {
      this.listElements.forEach(item => item.avatarList = this.avatarList);
    }

    if (_changedProperties.has('offsetContent')) {
      this.listElements.forEach(item => item.offsetContent = this.offsetContent);
    }
  }

  protected layout() {
    this.listDividers.forEach(item => item.setAttribute('role', 'separator'));
    if (this.listElements[0]) this.listElements[0].setAttribute('tabindex', '0');

    // List items need to have at least tabindex=-1 to be focusable.
    this.listElements
      .forEach(item => {
        item.setAttribute('role', 'option');

        if (!item.getAttribute('tabindex')) {
          item.setAttribute('tabindex', '-1');
        }
      });

    // List items with checkbox need to have role="checkbox"
    this.listElements
      .filter(item => item.querySelector(this.selectors.checkbox))
      .forEach(item => {
        item.setAttribute('role', 'checkbox');

        if (item.querySelector(this.selectors.checkbox)!['checked']) {
          item.setAttribute('aria-checked', 'true');
        } else {
          item.setAttribute('aria-checked', 'false');
        }
      });

    // List items with radio button need to have role="radio"
    this.listElements
      .filter(item => item.querySelector(this.selectors.radio))
      .forEach((item: Element) => {
        item.setAttribute('role', 'radio');

        if (item.querySelector(this.selectors.radio)!['checked']) {
          item.setAttribute('aria-checked', 'true');
        } else {
          item.setAttribute('aria-checked', 'false');
        }
      });

    // Child button/a elements are not tabbable until the list item is focused.
    this.listElements
      .map(item =>
        item.querySelectorAll(this.selectors.focusableChildElements)
      )
      .forEach(children =>
        children.forEach(child => child.setAttribute('tabindex', '-1'))
      );

    this.mdcFoundation.layout();
  }

  /**
   * Initialize selectedIndex value based on pre-selected checkbox list items, single selection or radio.
   */
  protected initializeListType() {
    const checkboxListItems = this.listElements.filter(item =>
      item.querySelector<HTMLInputElement>(this.selectors.checkbox)
    );
    const checkboxRadioListItems = this.listElements.filter(item =>
      item.querySelector<HTMLInputElement>(this.selectors.checkboxRadio)
    );
    const singleSelectedListItem = this.listElements.find(item =>
      item.activated || item.selected
    );
    const radioSelectedListItem = this.mdcRoot.querySelector(
      strings.ARIA_CHECKED_RADIO_SELECTOR
    ) as MWCListItem;

    if (checkboxListItems.length > 0) {
      this.selectedIndex = this._getCheckboxSelectedIndex();
    } else if (singleSelectedListItem) {
      if (singleSelectedListItem.activated) {
        this.mdcFoundation.setUseActivatedClass(true);
      }

      this.singleSelection = true;
      this.selectedIndex = this.listElements.indexOf(singleSelectedListItem);
    } else if (radioSelectedListItem) {
      this.selectedIndex = this.listElements.indexOf(radioSelectedListItem);
    }

    if (!this.singleSelection && checkboxRadioListItems.length === 0) {
      this.setAttribute('role', 'list');

      this.listElements
        .forEach(item => {
          item.setAttribute('role', 'listitem');
        });
    } else {
      this.setAttribute('role', 'listbox');
    }
  }

  protected _getCheckboxSelectedIndex() {
    return this.listElements
      .filter(item => item.getAttribute('aria-checked') === 'true')
      .map(item => this.listElements.indexOf(item));
  }

  protected _onSlotChange() {
    this.layout();
    this.initializeListType();
  }

  /**
   * Used to figure out which element was clicked
   * before sending the event to the foundation.
   */
  protected _onFocusIn(e: FocusEvent) {
    const index = this._getListItemIndex(e);
    this.mdcFoundation.handleFocusIn(e, index);
  }

  /**
   * Used to figure out which element was clicked before sending the event
   * to the foundation.
   */
  protected _onFocusOut(e: FocusEvent) {
    const index = this._getListItemIndex(e);
    this.mdcFoundation.handleFocusOut(e, index);
  }

  /**
   * Used to figure out which element was focused when keydown event occurred
   * before sending the event to the foundation.
   */
  protected _onKeyDown(e: KeyboardEvent) {
    const index = this._getListItemIndex(e);
    const target = this.listElements[index];
    const isEnter = e.key === 'Enter' || e.keyCode === 13;
    const isSpace = e.key === 'Space' || e.keyCode === 32;

    // Return earlier if target is disabled
    if (
      (isEnter || isSpace) &&
      target.disabled
    ) return;

    this.mdcFoundation.handleKeydown(e, target instanceof MWCListItem, index);
  }

  /**
   * Used to figure out which element was clicked before sending the event
   * to the foundation.
   */
  protected _onClick(e: MouseEvent) {
    const index = this._getListItemIndex(e);
    const target = this.listElements[index];

    if (!target) return;

    // Return earlier if target is disabled
    if (target.disabled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    };

    // Toggle the checkbox only if it's not the target of the event,
    // or the checkbox will have 2 change events.
    const toggleCheckbox = !matches(target, this.selectors.checkboxRadio);
    this.mdcFoundation.handleClick(index, toggleCheckbox);
  }

  /**
   * Used to figure out which list item this event is targetting.
   * Or returns -1 if there is no list item
   */
  protected _getListItemIndex(e: Event) {
    const eventTarget = e.target as Element;
    const nearestParent = closest(eventTarget, `gsk-mwc-list-item`);

    // Get the index of the element if it is a list item.
    if (nearestParent && matches(nearestParent, `gsk-mwc-list-item`)) {
      return this.listElements.indexOf(nearestParent as MWCListItem);
    }

    return -1;
  }

}

import { LitElement, property, query, classMap, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { observer } from '@gsk-tech/gsk-base/observer';
import { TextFieldBase as GSKTextField } from '@gsk-tech/gsk-textfield/gsk-textfield-base';
import { InputChipsBase as GSKInputChips } from '@gsk-tech/gsk-input-chips/gsk-input-chips-base';
import { MenuBase as GSKMenu } from '@gsk-tech/gsk-menu/gsk-menu-base';
import { ListItemBase as GSKListItem } from '@gsk-tech/gsk-list/gsk-list-base';
import { ChipBase as GSKChip } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { normalizeSync } from 'normalize-diacritics';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import {
  ConstraintValidationModel,
  createValidityObj,
  CustomValidityState,
  ValidityTransform,
} from '@gsk-tech/gsk-base/constraint-validation';
import ChoicesLoader from './choices-loader';
import { gskStyle } from './gsk-autocomplete-css';

export { ChoicesLoader };

export interface Choice {
  label: string;
  value: string;
}

export interface MenuSelectedEvent {
  item: GSKListItem;
}

export interface InputChipsRemovalEvent {
  item: GSKChip;
}

const composeObserver = (propName: string) =>
  function(this: AutocompleteBase, value: any) {
    if (this.formElement) {
      this.formElement[propName] = value;
    }
  };

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export class AutocompleteBase extends LitElement
  implements ConstraintValidationModel<Choice[] | string> {
  public static get styles() {
    return gskStyle;
  }

  // Elements

  @query('.autocomplete')
  protected _root?: HTMLElement;

  @query('.autocomplete__input')
  protected formElement?: GSKTextField;

  @query('gsk-textfield')
  protected textField?: GSKTextField;

  @query('gsk-input-chips')
  protected inputChips?: GSKInputChips;

  @query('.autocomplete__menu')
  protected menu?: GSKMenu;

  // Form Element Attribute Properties

  @property({ type: String })
  @observer(composeObserver('arialabel'))
  public arialabel = '';

  @property({ type: Boolean })
  @observer(composeObserver('fullWidth'))
  public fullWidth = false;

  @property({ type: String })
  @observer(composeObserver('helperTextContent'))
  public helperTextContent = '';

  @property({ type: String })
  @observer(composeObserver('validationMessage'))
  public validationMessage = '';

  @property({ type: Boolean })
  @observer(composeObserver('persistentHelperText'))
  public persistentHelperText = false;

  @property({ type: String })
  @observer(composeObserver('leadingIconAriaLabel'))
  public leadingIconAriaLabel = '';

  @property({ type: String })
  @observer(composeObserver('trailingIconAriaLabel'))
  public trailingIconAriaLabel = '';

  @property({ type: String })
  @observer(composeObserver('leadingIconContent'))
  public leadingIconContent = '';

  @property({ type: String })
  @observer(composeObserver('trailingIconContent'))
  public trailingIconContent = '';

  @property({ type: Boolean })
  @observer(composeObserver('trailingIconInteraction'))
  public trailingIconInteraction = false;

  @property({ type: Boolean, reflect: true })
  @observer(composeObserver('required'))
  public required = false;

  @property({ type: Number, reflect: true })
  public minValues: number = 0;

  @property({ type: Number, reflect: true })
  public maxValues: number = Infinity;

  // @property({ type: Number, reflect: true })
  // @observer(composeObserver('minLength'))
  // public minLength;
  //
  // @property({ type: Number, reflect: true })
  // @observer(composeObserver('maxLength'))
  // public maxLength;

  @property({ type: Boolean, reflect: true })
  @observer(composeObserver('disabled'))
  public disabled?: boolean;

  @property({ type: String, reflect: true })
  @observer(composeObserver('placeholder'))
  public placeholder = '';

  @property({ type: String })
  @observer(composeObserver('label'))
  public label = '';

  @property({ type: String, reflect: true })
  @observer(composeObserver('name'))
  public name = '';

  @property({ type: String })
  @observer(composeObserver('labeltooltiptext'))
  public labeltooltiptext = '';

  @property({ type: Boolean })
  @observer(composeObserver('labeltooltipinverted'))
  public labeltooltipinverted = false;

  @property({ type: String })
  @observer(composeObserver('buttonlabel'))
  public buttonlabel = '';

  @property({ type: String })
  @observer(composeObserver('buttonarialabel'))
  public buttonarialabel = '';

  @property({ type: String })
  @observer(composeObserver('buttonicon'))
  public buttonicon = '';

  @property({ type: Boolean })
  @observer(composeObserver('buttonleadingicon'))
  public buttonleadingicon = false;

  // Input Chips Attribute Properties

  @property({ type: String })
  @observer(composeObserver('breakchar'))
  public breakchar?: string;

  // Attribute Properties

  private _multiselect: boolean = false

  @property({ type: Boolean, reflect: true })
  public get multiselect(){
    return this._multiselect;
  }

  public set multiselect(_multiselect){
    const _oldValue = this._multiselect;
    this._multiselect = _multiselect
    this.requestUpdate('multiselect', _oldValue);
  }

  private _suggest: boolean = false

  @property({ type: Boolean, reflect: true })
  public get suggest(){
    return this._suggest;
  }

  public set suggest(_suggest){
    const _oldValue = this._suggest;
    this._suggest = _suggest
    this.requestUpdate('suggest', _oldValue);
  }

  private _async: boolean = false

  @property({ type: Boolean, reflect: true })
  public get async(){
    return this._async;
  }

  public set async(_async){
    const _oldValue = this._async;
    this._async = _async
    this.requestUpdate('async', _oldValue);
  }

  @property({ type: String })
  @observer(async function(this: AutocompleteBase, value: string) {
    if (value !== this._previousValue) {
      this._hasValuePropertyChanged = true;
      await this.updateComplete;
      this._updateValue();
      if (this.dirty) {
        this.reportValidity();
      }
    } else {
      await this.updateComplete;
      if (this.dirty) {
        this.reportValidity();
      }
    }
  })
  public value = '';

  public validityTransform: ValidityTransform<Choice[] | string> | null = null;

  /**
   * Optional, In case 'suggest' is used into the autocomplete component
   * otherwise, this property is 'required'.
   * It's default value is an empty array and is use to set up the array of choices
   */
  @property({ type: Array })
  @observer(function(this: AutocompleteBase, value: Choice[]) {
    if (!Array.isArray(value)) {
      try {
        this.choices = JSON.parse(value) as Choice[];
      } catch (e) {
        console.log(e);
      }
    }

    this._updateAvailableChoices();
  })
  public choices: Choice[] = [];

  @property({ attribute: false })
  public getChoices?: (value: string) => Promise<unknown>;

  @property({ type: String })
  public loadinglabel = 'Loading...';

  @property({ type: String })
  public noresultslabel = 'No Results.';

  @property({ type: Number })
  public debouncetime = 500;

  /**
   * Optional. Default is false.
   * If set, reportValidity method will be called on firstUpdated.
   */
  @property({ type: Boolean })
  public validateOnInitialRender = false;

  @property({ type: Boolean })
  // @observer(composeObserver('dirty'))
  public dirty = false;

  /**
   * Optional. Use it to force the validity state of the component,
   * even if the `required` prop is true
   */
  @property({ type: Boolean })
  @observer(composeObserver('valid'))
  public valid?: boolean = undefined;

  // Public properties

  protected _validity: ValidityState = createValidityObj();

  /**
   * A read-only property that returns a ValidityState object,
   * whose properties represent validation errors for the value of that element.
   */
  public get validity(): Readonly<ValidityState> {
    if (!this.willValidate) {
      return createValidityObj();
    }
    this._checkValidity();
    return this._validity;
  }

  public get text(): string {
    return this.formElement ? this.formElement.value : '';
  }

  public get selectedChoices(): Choice[] {
    return this._selectedChoices;
  }

  public set selectedChoices(value: Choice[]) {
    this._selectedChoices = value;

    this._updateAvailableChoices();

    // Update value based on selected choices
    const nextValue = this.selectedChoices.map(item => item.value).join(',');

    this._setValue(nextValue);
  }

  // Private Properties

  protected choicesLoader?: ChoicesLoader;

  protected get _inputValue() {
    if (this.textField) {
      return this.textField.value;
    }

    if (this.inputChips) {
      return this.inputChips.inputValue;
    }

    return '';
  }

  protected _textOnFocus: string = this.text;

  protected _tempSelectedItem?: GSKListItem;

  protected _tempRemovedChip?: GSKChip;

  protected _previousValue = '';

  protected _selectedChoices: Choice[] = [];

  protected _availableChoices: Choice[] = [];

  protected _filteredChoices: Choice[] = [];

  protected _listType = 'single-line';

  protected get _hasChoices() {
    const choices = this.suggest
      ? this._availableChoices
      : [...this.selectedChoices, ...this._availableChoices];

    return Boolean(choices.length);
  }

  protected get _hasChoicesToDisplay() {
    return Boolean(this._filteredChoices.length);
  }

  protected get _hasTextChanged() {
    return this._normalizeText(this._textOnFocus) !== this._normalizeText(this.text);
  }

  protected _hasValuePropertyChanged = false;

  protected _menuShouldStayClosed = false;

  // States

  protected _isLoading = false;

  protected _isFocused = false;

  /**
   * Gets FOCUS state
   */
  protected get isFocused() {
    return this._isFocused;
  }

  /**
   * Updates FOCUS state
   *
   * Returns early if value was already set.
   */
  protected set isFocused(value: boolean) {
    if (this._isFocused === value) {
      return; // Return early
    }

    this._isFocused = value;

    if (this._isFocused) {
      this._textOnFocus = this.text;
    }
  }

  protected _isMenuOpen = false;

  protected _menuWillClose = false;

  protected _hasLoadedChoices = false;

  // Listeners

  protected _handleFormElementMouseDown = this._onFormElementMouseDown.bind(this);

  protected _handleFormElementInput = this._onFormElementInput.bind(this);

  protected _handleFormElementChange = this._onFormElementChange.bind(this);

  protected _handleFormElementFocus = this._onFormElementFocus.bind(this);

  protected _handleFormElementBlur = this._onFormElementBlur.bind(this);

  protected _handleFormElementKeyDown = this._onFormElementKeyDown.bind(this);

  protected _handleInputChipsRemoval = this._onInputChipsRemoval.bind(this);

  protected _handleMenuOpened = this._onMenuOpened.bind(this);

  protected _handleMenuClosed = this._onMenuClosed.bind(this);

  protected _handleMenuSelected = this._onMenuSelected.bind(this);

  protected _handleMenuMouseDown = this._onMenuMouseDown.bind(this);

  protected _handleDocumentMouseUp = this._onDocumentMouseUp.bind(this);

  // Render

  /**
   * Returns a text field if multiselect is off,
   * otherwise returns an input chips.
   */
  protected _renderFormElement() {
    return this.multiselect ? this._renderInputChips() : this._renderTextField();
  }

  /**
   * Returns an input chips
   */
  protected _renderInputChips() {
    return html`
      <gsk-input-chips class="autocomplete__input" preventautoinput></gsk-input-chips>
    `;
  }

  /**
   * Returns a text field
   */
  protected _renderTextField() {
    return html`
      <gsk-textfield class="autocomplete__input"></gsk-textfield>
    `;
  }

  /**
   * Returns a menu
   */
  protected _renderMenu() {
    return html`
      <gsk-menu class="autocomplete__menu" wrap>
        ${this._renderList()}
      </gsk-menu>
    `;
  }

  /**
   * Returns list of choices
   */
  protected _renderList() {
    const classes = {
      'is-empty': !this._shouldRenderChoices(),
    };

    return html`
      ${this._shouldRenderLoading() ? this._renderLoader() : ''}
      <gsk-list class="${classMap(classes)}" type="${this._listType}">
        ${this._shouldRenderChoices() ? this._renderChoices() : ''}
      </gsk-list>
      ${this._shouldRenderNoResults() ? this._renderNoResults() : ''}
    `;
  }

  /**
   * Returns loader item
   */
  protected _renderLoader(): TemplateResult {
    return html`
      <gsk-list-item disabled>${this.loadinglabel}</gsk-list-item>
      ${this._shouldRenderChoices()
        ? html`
            <gsk-list-divider></gsk-list-divider>
          `
        : ''}
    `;
  }

  /**
   * Returns list of choices
   */
  protected _renderChoices() {
    return html`
      ${this._filteredChoices.map(choice => this._renderChoice(choice))}
    `;
  }

  /**
   * Returns a choice item
   */
  protected _renderChoice(choice: Choice) {
    return html`
      <gsk-list-item value="${choice.value}">
        ${choice.label}
      </gsk-list-item>
    `;
  }

  /**
   * Returns no results item
   */
  protected _renderNoResults() {
    return html`
      ${this._shouldRenderChoices()
        ? html`
            <gsk-list-divider></gsk-list-divider>
          `
        : ''}
      <gsk-list-item disabled>${this.noresultslabel}</gsk-list-item>
    `;
  }

  protected render(): TemplateResult {
    return html`
      <div class="autocomplete">
        ${this._renderFormElement()} ${this._renderMenu()}
      </div>
    `;
  }

  protected async firstUpdated() {
    this._addListeners();
    this._layout();
    this._setupChoicesLoader();

    if (this.valid !== undefined) {
      this.formElement!.valid = this.valid;
    }

    if (this.validateOnInitialRender) {
      await this.updateComplete;
      await this.formElement!.updateComplete;

      setTimeout(() => {
        this.reportValidity();
      });
    }
  }

  // Initializers

  /**
   * Add Listeners
   *
   * Form Element:
   *  - mousedown
   *  - input
   *  - focus
   *  - blur
   *  - removal (input chips)
   *  -
   *
   * Menu:
   *  - opened
   *  - closed
   *  - selected
   */
  protected _addListeners() {
    // form element

    this.formElement!.addEventListener('mousedown', this._handleFormElementMouseDown);
    this.formElement!.addEventListener('input', this._handleFormElementInput);
    this.formElement!.addEventListener('change', this._handleFormElementChange);
    this.formElement!.addEventListener('focus', this._handleFormElementFocus);
    this.formElement!.addEventListener('blur', this._handleFormElementBlur);
    this.formElement!.addEventListener('keydown', this._handleFormElementKeyDown);
    this.formElement!.addEventListener('removal', this
      ._handleInputChipsRemoval as EventListenerOrEventListenerObject);

    // menu

    this.menu!.addEventListener('opened', this._handleMenuOpened);
    this.menu!.addEventListener('closed', this._handleMenuClosed);
    this.menu!.addEventListener('selected', this
      ._handleMenuSelected as EventListenerOrEventListenerObject);
    this.menu!.addEventListener('mousedown', this._handleMenuMouseDown);
  }

  /**
   * Configures elements layout
   *
   * Menu:
   *  - Sets form element root as anchor element of the menu
   *  - Sets Corner.BOTTOM_LEFT as anchor corner of the menu
   */
  protected _layout() {
    // menu

    this.menu!.updateComplete.then(() => {
      const menuAnchorElement = this.formElement!.shadowRoot!.querySelector(
        '.mdc-text-field',
      ) as HTMLElement;
      const menuAnchorCorner = this.menu!.Corner.BOTTOM_LEFT;

      this.menu!.setAnchorCorner(menuAnchorCorner);
      this.menu!.setAnchorElement(menuAnchorElement);

      this._updateMenuPosition();
    });
  }

  /**
   * Fixes menu position based on form Element area
   */
  protected _updateMenuPosition() {
    const menuAnchorElement = this.formElement!.shadowRoot!.querySelector(
      '.mdc-text-field',
    ) as HTMLElement;
    const helperLine = this.formElement!.shadowRoot!.querySelector(
      '.mdc-text-field-helper-line',
    ) as HTMLElement;

    const menuRoot = this.menu!.shadowRoot!.querySelector('.mdc-menu')! as HTMLElement;
    let { marginTop } = getComputedStyle(menuAnchorElement) as any;

    marginTop = Number(marginTop.replace('px', ''));

    const marginBottom = helperLine
      ? helperLine.getBoundingClientRect().height +
        Number((getComputedStyle(helperLine) as any).marginTop.replace('px', ''))
      : 0;

    const margin = {
      top: marginTop,
      bottom: marginBottom,
    };

    menuRoot.style.marginTop = `${margin.top}px`;
    menuRoot.style.marginBottom = `${margin.bottom}px`;
  }

  /**
   * Sets-up choices loader
   */
  protected _setupChoicesLoader() {
    if (!this.async || !this.getChoices) {
      return; // Return early
    }

    this.choicesLoader = new ChoicesLoader(this.getChoices);
  }

  // Listener Handlers

  /**
   * Handles form element `input` event
   *
   * Opens menu
   */
  protected async _onFormElementInput() {
    if (!this.isFocused) {
      this.isFocused = true;
    }

    this.openMenu();

    await this._filterChoices();
    await this._loadChoices();

    await this.updateComplete;
    await this.formElement!.updateComplete;
  }

  /**
   * Handles form element `change` event
   *
   * Prevents change event from bubling
   */
  protected _onFormElementChange(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Handles form element `focus` event
   *
   * Opens menu and turns on FOCUSED state.
   *
   * Returns early if MENU SHOULD STAY CLOSED state is on.
   *
   * Returns early if FOCUSED state is on.
   */
  protected _onFormElementFocus() {
    if (this._menuShouldStayClosed) {
      return; // Return early
    }

    if (this._isFocused) {
      return; // Return early
    }

    this.isFocused = true;
    this.openMenu();
  }

  /**
   * Handles form element `mousedown` event
   *
   * Turns on FOCUSED state ahead of time in order to
   * open menu on document mouse up.
   *
   * Returns early if MENU SHOULD STAY CLOSED state is on.
   *
   * Returns early if MENU OPEN state is on, if so
   * turns off MENU WILL CLOSE state.
   */
  protected _onFormElementMouseDown() {
    if (this._menuShouldStayClosed) {
      return; // Return early
    }

    if (this._isMenuOpen) {
      this._menuWillClose = false;
      return; // Return early
    }

    this.isFocused = true;
    document.addEventListener('mouseup', this._handleDocumentMouseUp, { once: true });
  }

  /**
   * handles document `mouseup` event
   *
   * Opens menu.
   */
  protected async _onDocumentMouseUp() {
    await this._filterChoices();
    this.openMenu();
  }

  /**
   * Handles form element `blur` event
   *
   * Updates value and turns off FOCUSED state.
   *
   * Returns early if MENU WILL CLOSE state is on.
   */
  protected async _onFormElementBlur() {
    if (this._menuWillClose) {
      return; // Return early
    }

    this.dirty = true;
    this.isFocused = false;
    this._updateValue();

    await this.formElement!.updateComplete;
  }

  /**
   * Handles form element `keydown` event
   *
   * If 'Enter' is clicked, then closes menu, and blurs form element
   * in order to start update value flow, then focus it again.
   */
  protected _onFormElementKeyDown(event: KeyboardEvent) {
    // const isArrowUp = event.key === 'ArrowUp' || event.keyCode === 38;
    // const isArrowDown = event.key === 'ArrowDown' || event.keyCode === 40;
    const isEnter = event.key === 'Enter' || event.keyCode === 13;

    if (isEnter) {
      this.closeMenu(true);
      this.blur();
    }
  }

  /**
   * Handles input chips `removal` event
   *
   * Sets temp removed chip, then updates value, and
   * closes menu.
   */
  protected _onInputChipsRemoval(event: CustomEvent<InputChipsRemovalEvent>) {
    this.dirty = true;
    this._tempRemovedChip = event.detail.item;
    this._updateValue();
    this.isFocused = false;
    this._menuWillClose = false;
    this.closeMenu(true);
  }

  /**
   * Handles menu `opened` event
   *
   * Turns on MENU WILL CLOSE state.
   */
  protected _onMenuOpened() {
    this._menuWillClose = true;
  }

  /**
   * Handles menu `selected` event
   *
   * Sets temp selected item, then updates value.
   */
  protected _onMenuSelected(event: CustomEvent<MenuSelectedEvent>) {
    this.dirty = true;
    this._tempSelectedItem = event.detail.item;
  }

  /**
   * Handles menu `mousedown` event
   *
   * Prevents form element validity
   */
  protected _onMenuMouseDown(event: MouseEvent) {
    event.preventDefault();
  }

  /**
   * Handles menu `closed` event
   *
   * Turns off MENU OPEN, and also
   * turns off MENU WILL CLOSE, FOCUSED states only if
   * MENU WILL CLOSE is off.
   */
  protected _onMenuClosed() {
    this.dirty = true;
    this._isMenuOpen = false;

    if (!this._menuWillClose) {
      this._updateValue();
      return; // Return early
    }

    this._menuWillClose = false;
    this.isFocused = false;

    this._updateValue();
  }

  // Validations

  /**
   * A read-only boolean property that returns true if the element is a candidate for constraint validation;
   * and false otherwise.
   */
  public get willValidate(): boolean {
    return this.formElement ? this.formElement.willValidate : false;
  }

  public get isUiValid(): boolean {
    if (this.willValidate) {
      if (this.valid !== undefined) {
        return this.valid;
      }

      if (this.dirty || this.validateOnInitialRender) {
        return this.validity.valid;
      }
    }
    return true;
  }

  /**
   * Checks the element's value against its constraints and also reports the validity status;
   * if the value is invalid, it fires an invalid event at the element, returns false,
   * and then reports the validity status to the user in whatever way the user agent has available.
   * Otherwise, it returns true.
   */
  public reportValidity(): boolean {
    const isValid = this.checkValidity();
    this.formElement!.valid = isValid;
    this.dirty = true;
    return isValid;
  }

  /**
   * Checks the element's value against its constraints.
   * If the value is invalid, it fires an invalid event at the element and returns false;
   * otherwise it returns true.
   */
  public checkValidity(): boolean {
    const isValid = this._checkValidity();

    if (!isValid) {
      const invalidEvent = new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  protected _checkValidity() {
    let value: string | Choice[];
    const { selectedChoices, value: v } = this;
    if (this.suggest) {
      value = v;
    } else {
      value = selectedChoices;
    }
    const len: number = value.length;
    const isCustomError = this._validity.customError;
    const nativeValidity: CustomValidityState = createValidityObj({
      customError: isCustomError,
      valid: !isCustomError,
    });
    let valid = true;
    if (this.required && valid) {
      if (!(Boolean(value) && value.length)) {
        nativeValidity.valueMissing = true;
        valid = false;
      }
    }
    if (Array.isArray(value)) {
      if (this.minValues && valid) {
        if (len > 0 && len < this.minValues) {
          nativeValidity.rangeUnderflow = true;
          valid = false;
        }
      }
      if (this.maxValues && valid) {
        if (len > this.maxValues) {
          nativeValidity.rangeOverflow = true;
          valid = false;
        }
      }
    }
    nativeValidity.valid = nativeValidity.valid && valid;

    let validity = nativeValidity;
    if (this.validityTransform) {
      const transformedValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...transformedValidity };
    }

    this._validity = validity;
    return this._validity.valid;
  }

  public setCustomValidity(message: string): void {
    this.validationMessage = message;
    const isError = message !== '';
    this._validity = createValidityObj({
      customError: isError,
    });
    this.dirty = true;
    this.requestUpdate();
  }

  // Validations

  /**
   * Validates whether or not can open the menu
   *
   * Returns true if MENU OPEN state is off.
   */
  protected _canOpenMenu() {
    return !this._isMenuOpen;
  }

  /**
   * Validates whether or not can close the menu
   *
   * Returns true if MENU OPEN state is on and
   * if MENU WILL CLOSE state is off.
   */
  protected _canCloseMenu() {
    return this._isMenuOpen && !this._menuWillClose;
  }

  /**
   * Validates wheter or not should open the menu
   *
   * Returns true if FOCUSED state is on, and
   * if should render choices.
   */
  protected _shouldOpenMenu() {
    return this._isFocused && this._shouldRenderMenuContent();
  }

  /**
   * Validates wheter or not should close the menu
   *
   * Returns true if FOCUSED state is off, or
   * if there is no text nor choices to display.
   */
  protected _shouldCloseMenu() {
    return !this._isFocused || (!this.text && !this._shouldRenderChoices());
  }

  /**
   * Validates whether or not should render loading message
   *
   * Returns true if LOADING state is on.
   */
  protected _shouldRenderLoading() {
    return this._isLoading;
  }

  /**
   * Validates whether or not should render choices
   *
   * Returns true if there is choices to display.
   */
  protected _shouldRenderChoices() {
    return this._hasChoicesToDisplay;
  }

  /**
   * Validates whether or not should render no results message
   *
   * Returns true only if LOADING state is off, and has choices,
   * and there is choices to display.
   */
  protected _shouldRenderNoResults() {
    return (
      !this._isLoading &&
      (this._hasChoices || this._hasLoadedChoices) &&
      !this._hasChoicesToDisplay &&
      Boolean(this._inputValue)
    );
  }

  /**
   * Validates whether or not should render no results message
   *
   * Returns true if should render loading message, or
   * if should render choices, or
   * if should render no results message.
   */
  protected _shouldRenderMenuContent() {
    return (
      this._shouldRenderLoading() || this._shouldRenderChoices() || this._shouldRenderNoResults()
    );
  }

  /**
   * Validates wheter or not should update the value
   *
   * Returns `'selection'` if there is a temp selected item but its value
   * has not already selected, or `'removal'` if `multiselect` is on and
   * text has changed, or `'property'` if property has changed, or `'text'`
   * if `multiselect` is off and text has changed, or `'input'` if `multiselect`
   * is on and input value isn't empty, otherwise returns `'none'`.
   */
  protected _shouldUpdateValue(): 'selection' | 'property' | 'text' | 'removal' | 'input' | false {
    if (
      Boolean(this._tempSelectedItem) &&
      !this.selectedChoices.find(item => item.value === this._tempSelectedItem!.value)
    ) {
      return 'selection';
    }

    if (
      Boolean(this._tempRemovedChip) &&
      this.selectedChoices.find(item => item.value === this._tempRemovedChip!.value)
    ) {
      return 'removal';
    }

    if (this._hasValuePropertyChanged) {
      return 'property';
    }

    if (!this.multiselect && this._hasTextChanged) {
      return 'text';
    }

    if (this.multiselect && Boolean(this._inputValue)) {
      return 'input';
    }

    return false;
  }

  /**
   * Validates wheter or not should filter choices
   *
   * Returns true if `multiselect` is on and input value isn't empty,
   * or if text has changed, or if has choices to display
   */
  protected _shouldFilterChoices() {
    return (
      this.suggest ||
      (this.multiselect && Boolean(this._inputValue)) ||
      (this._hasTextChanged || this.isFocused) ||
      this._hasChoicesToDisplay
    );
  }

  /**
   * Validates wheter or no should load choices
   *
   * Returns true if `async` is on and getChoices is provided,
   * and if `multiselect` is on and input value isn't empty or
   * if text has changed.
   */
  protected _shouldLoadChoices() {
    return (
      this.async &&
      this.getChoices &&
      ((this.multiselect && Boolean(this._inputValue)) || this._hasTextChanged)
    );
  }

  /**
   * Validates wheter or not should update selected displayed choices
   *
   * Returns true if `multiselect` is off, and there are choices selected.
   */
  protected _shouldUpdateSelectedDisplayedChoices() {
    return !this.multiselect && Boolean(this.selectedChoices.length);
  }

  // Private Actions

  /**
   * Opens menu
   *
   * Turns on MENU OPEN state, and updates menu position.
   *
   * Returns early if MENU OPEN state is one.
   */
  private async _openMenu() {
    if (this._isMenuOpen) {
      return; // Return early if menu is already open
    }

    await this._filterChoices();
    this._updateMenuPosition();

    this._isMenuOpen = true;
    this.menu!.open = true;
  }

  /**
   * Closes menu
   *
   * Returns early if MENU OPEN state is off.
   */
  private _closeMenu() {
    if (!this._isMenuOpen) {
      return; // Return early if menu is already closed
    }

    this.menu!.open = false;
  }

  /**
   * Opens menu only if it SHOULD and CAN
   */
  protected openMenu() {
    if (this._shouldOpenMenu() && this._canOpenMenu()) {
      this._openMenu();
    }
  }

  /**
   * Closes menu only if it SHOULD and CAN
   */
  protected closeMenu(force = false) {
    if (force) {
      this.isFocused = false;
      this._menuWillClose = false;
    }

    if (this._shouldCloseMenu() && this._canCloseMenu()) {
      this._closeMenu();
    }
  }

  /**
   * Updates value, but only if it SHOULD
   *
   * Sets value based on type of update
   * if `selection`, sets value based on temp selected item
   * if `removal`, sets value based on temp removed chip
   * if `property`, sets value based on changed property
   * if `text` (single selection), sets value based on changed text
   * if `input` (multiselect), sets value based on changed input value
   */
  protected async _updateValue() {
    switch (this._shouldUpdateValue()) {
      case 'selection':
        this._setValueBySelection();
        break;
      case 'removal':
        this._setValueByRemoval();
        break;
      case 'property':
        this._setValueByProperty();
        break;
      case 'text':
        this._setValueByText();
        break;
      case 'input':
        this._setValueByInput();
        break;
      default:
        break;
    }

    await this.updateComplete;
    this._filterChoices();
  }

  /**
   * Sets value based on temp selected item
   *
   * Looks for a choice that matches selected item value on filtered choices, then
   * if `multiselect` is on, adds a new chip based on matched choice label, but
   * if `multiselect` is off, updates form element value based on matched choice label, then
   * if `suggest` is on, updates component value based on form element value, but
   * if `suggest` is off, updates selected choices with the new matched choice and
   * updates component value based on selected choices values.
   * Finally clears temp selected item.
   */
  protected _setValueBySelection() {
    const matchedChoice = this._filteredChoices.find(
      item => item.value === this._tempSelectedItem!.value,
    ) as Choice;

    if (this.multiselect) {
      // Adds a new chip based on matched choice label
      this._updateChipsAndValue(matchedChoice);
    } else {
      // Updates form element value based on matched choice label
      this.formElement!.value = matchedChoice.label;
    }

    if (this.suggest) {
      // Updates component value based on form element value
      this._setValue(this.formElement!.value as string);
    } else {
      // Updates Selected Choices
      this.selectedChoices = this.multiselect
        ? [...this.selectedChoices, matchedChoice]
        : [matchedChoice];
    }

    this._tempSelectedItem = undefined;
  }

  /**
   * Sets value based on temp removed chip
   *
   * Filters choice with a value that matches temp remove chip value,
   * and updates selected choices with the remaining ones, then updates
   * component value based on selected choices values.
   * Finally clears temp removed chip.
   */
  protected _setValueByRemoval() {
    const remainingChoices = this.selectedChoices.filter(
      item => item.value !== this._tempRemovedChip!.value,
    );

    if (this.suggest) {
      // Updates component value based on form element value
      this._setValue(this.formElement!.value as string);
    } else {
      // Updates Selected Choices
      this.selectedChoices = remainingChoices;
    }

    this._tempRemovedChip = undefined;
  }

  /**
   * Sets value based on changed property
   *
   * If `suggest` is on, form element value is updated based on component
   * value.
   *
   * If `suggest` is off, looks for value/values on available choices,
   * if a choice is found its added to a list of matched choices, then
   * selected choices is updated based on matched choices list, then
   * if `multiselect` is on, adds new chips based on selected choices, but
   * if `multiselect` is off, updates form element value based on selected
   * choices labels, then component value is updated based on form element
   * value.
   *
   * Returns early if matched choices list is empty, and updates component
   * value based on previous value.
   *
   * Returns early if value is empty, and updates component based on empty value.
   *
   * If missing values are found, a message per missing choice is logged
   * on the console.
   */
  protected async _setValueByProperty() {
    this._hasValuePropertyChanged = false;

    if (this.suggest) {
      this.formElement!.value = this.value;
    } else {
      const choices = [...this._selectedChoices, ...this._availableChoices];

      if (this.multiselect) {
        if (
          this.value
            .split(',')
            .join('')
            .trim() === ''
        ) {
          await this.inputChips!.updateComplete;

          this.inputChips!.clear();
          this.selectedChoices = [];
          return; // Return early
        }

        const values = this.value.split(',');
        const matchedChoices = choices.filter(item => values.includes(item.value));

        const missingValues = values.filter(
          value => !matchedChoices.find(item => item.value === value),
        );

        // Log missing values
        missingValues.forEach(value => {
          console.log(`Autocomplete Error: Missing choice for '${value}' value`);
        });

        // Validates if matched choices list isn't empty
        if (!matchedChoices.length) {
          // Updates component value based on previous value.
          this._setValue(this._previousValue);
          console.log(
            `Autocomplete Error: Value cannot be updated due to missing values on available choices`,
          );
          return; // Return early
        }

        await this.inputChips!.updateComplete;

        this.inputChips!.clear();

        // Adds new chips based on selected choices
        matchedChoices.forEach(item => this._updateChipsAndValue(item));

        // Updates Selected Choices
        this.selectedChoices = matchedChoices;
      } else {
        if (this.value.trim() === '') {
          this.formElement!.value = '';
          this.selectedChoices = [];
          return; // Return early
        }

        const matchedChoice = choices.find(item => this.value === item.value);

        if (!matchedChoice) {
          // Updates component value based on previous value.
          this._setValue(this._previousValue);
          console.log(
            `Autocomplete Error: Value cannot be updated due to missing value on available choices`,
          );
          return; // Return early
        }

        // Updates form element value based on selected choices label
        this.formElement!.value = matchedChoice!.label;

        // Updates Selected Choices
        this.selectedChoices = [matchedChoice];
      }
    }
  }

  /**
   * Sets value based on changed text
   *
   * Looks for choice with the same label as current text,
   * if a choice is found, form element value is updated based
   * on matched choice label.
   * If `suggest` is on, component value is updated based on
   * form element value.
   * If `suggest` is off, selected choices is updated with matched
   * choice, and component value is updated based on selected choices
   * values.
   *
   * If no choice is found, and `suggest` is on, form element value
   * is updated with new text value, but if `suggest` is off, form element
   * is updated back to its previous value, then component value is updated
   * based on form element value.
   */
  protected _setValueByText() {
    const matchedChoice = this._filteredChoices.find(item => {
      const normalizedItemLabel = this._normalizeText(item.label);
      const normalizedText = this._normalizeText(this.text);
      return normalizedItemLabel === normalizedText;
    });

    if (matchedChoice) {
      // Updates form element value based on matched choice label
      this.formElement!.value = matchedChoice.label;

      if (this.suggest) {
        // Updates value based on form element value
        this._setValue(this.formElement!.value as string);
      } else {
        // Updates Selected Choices
        this.selectedChoices = [matchedChoice];
      }
    } else {
      if (this.text!.trim() === '') {
        this.selectedChoices = [];
        this._setValue('');
        this.menu?.items?.forEach(item => item.selected = false);
        return; // Return earlu
      }

      // if `suggest` is on, updates form element value and
      // component value based on new text
      if (this.suggest) {
        this.formElement!.value = this.text;
        this._setValue(this.text as string);
        return; // Return early
      }

      this.formElement!.value = this._textOnFocus;

      const nextValue = this.selectedChoices.map(item => item.value).join(',');

      this._setValue(nextValue);
    }
  }

  /**
   * Sets value based on changed input value
   *
   * Looks for choice with the same label as the input value, if
   * a choice is found, a new chip using choices label is added, if not
   * but `suggest` is on a new chip using input value is added.
   *
   * If `suggest` is on, component value is updated based on form element value.
   *
   * If `suggest` is off, selected choices is updated with matched choice, and
   * value is updated based on selected choices values.
   */
  protected _setValueByInput() {
    const matchedChoice = this._filteredChoices.find(item => {
      const normalizedItemLabel = this._normalizeText(item.label);
      const normalizedInputValue = this._normalizeText(this._inputValue);
      return normalizedItemLabel === normalizedInputValue;
    });

    if (matchedChoice) {
      // Adds a new chip based on matched choice label
      this._updateChipsAndValue(matchedChoice);

      if (this.suggest) {
        // Updates value based on form element value
        this._setValue(this.formElement!.value as string);
      } else {
        // Updates Selected Choices
        this.selectedChoices = [...this.selectedChoices, matchedChoice];
      }
    } else if (this.suggest) {
      // Adds a new chip based on input value
      this._updateChipsAndValue(this._inputValue);

      // Updates value based on form element value
      this._setValue(this.formElement!.value as string);
    } else {
      // Clears input value
      this.inputChips!.clearValue();

      // Updates value based on selected choices values
      const nextValue = this.selectedChoices.map(item => item.value).join(',');

      this._setValue(nextValue);
    }
  }

  /**
   * Sets value
   */
  protected async _setValue(nextValue: string) {
    const shouldEmitChange = this.value !== nextValue;

    // eslint-disable-next-line no-multi-assign
    this.value = this._previousValue = nextValue;

    if (this.multiselect) {
      this._updateInputChipsListeners();
    }

    await this.updateComplete;

    if (shouldEmitChange) {
      const { value, text, selectedChoices } = this;
      const detail: {
        selectedChoice?: Choice;
        selectedChoices: Choice[];
        value: string;
        text: string;
      } = { value, text, selectedChoices };

      // selectedChoice deprecated
      if (!this.multiselect) {
        [detail.selectedChoice] = selectedChoices;
      }

      emit(this, 'change', detail);
    }
  }

  /**
   * Updates input chips listeners
   *
   * Adds mousedown listeners to input chips in order to avoid
   * conflicts with removal and menu.
   */
  protected _updateInputChipsListeners() {
    this.inputChips!.chips.forEach(chip => {
      chip.addEventListener('mousedown', () => {
        this._menuShouldStayClosed = true;

        document.addEventListener(
          'mouseup',
          () => {
            this._menuShouldStayClosed = false;
          },
          { once: true },
        );
      });
    });
  }

  /**
   * Gets choice by value
   *
   * @param value string, choice's value
   */
  protected _getChoiceByValue(value: string) {
    return this._filteredChoices.find(item => item.value === value);
  }

  /**
   * Gets choice by label
   *
   * @param value string, choice's label
   */
  protected _getChoiceByLabel(value: string) {
    return this._filteredChoices.find(item => item.label === value);
  }

  /**
   * Updates chips and value
   *
   * Adds a new chip based on label and custom chip props.
   */
  protected _updateChipsAndValue(choice: Choice | string) {
    const label = typeof choice === 'string' ? choice : choice.label;

    return this.inputChips!.updateChipsAndValue(label, this._getChipProps(choice));
  }

  /**
   * Updates available choices based on current choices,
   * then filters choices in order to keep everything in sync.
   */
  protected _updateAvailableChoices() {
    this._availableChoices = this.choices.filter(
      item => !this._selectedChoices.find(choice => choice.value === item.value),
    );

    this._filterChoices();
  }

  /**
   * Gets chip props
   *
   * Obtains custom chip props based on choice value.
   */
  protected _getChipProps(choice: Choice | string) {
    return {
      value: typeof choice === 'string' ? choice : choice.value,
      // breakchar: this.inputChips!.breakchar,
      // trailingIconContent: this.inputChips!.trailingIconContent,
      trailingIconInteraction: true,
    };
  }

  /**
   * Updates filtered choices
   *
   * Filters choices based on available and selected choices
   * that includes input value on its labels, then
   * updates selected displayed choices.
   *
   * If `multiselect` or `suggest` are on, filtered choices will be based
   * on available choices only.
   *
   * If `multiselect` and `suggest` are on, filtered choices will be based
   * on available choices without the ones with selected labels.
   *
   * Then if `multiselect` is on, updates selected displayed choices.
   *
   * Returns early if choices shouldn't be filtered, and updates filtered
   * choices with available choices.
   *
   * TODO(luissardon): Optimize filtering in order to avoid unnecesary calls.
   */
  protected async _filterChoices(): Promise<unknown> {
    this._isLoading = true;
    let choicesToFilter =
      this.suggest || this.multiselect
        ? this._availableChoices
        : [...this.selectedChoices, ...this._availableChoices];

    if (!this._shouldFilterChoices()) {
      this._filteredChoices = choicesToFilter;
      this._isLoading = false;
      return this.requestUpdate(); // Return early
    }

    if (this.suggest && this.multiselect) {
      // Remove choices with selected labels
      choicesToFilter = choicesToFilter.filter(choice => {
        const normalizedItemLabel = this._normalizeText(choice.label);
        const normalizedText = this._normalizeText(this.text);

        const values = normalizedText.split(',');

        return !values.find(value => choice.value === value || normalizedItemLabel.includes(value));
      });
    }

    // Filter based on choices that includes input value
    this._filteredChoices = choicesToFilter.filter(choice => {
      const normalizedItemLabel = this._normalizeText(choice.label);
      const normalizedInputValue = this._normalizeText(this._inputValue);

      return (
        choice.value === this._inputValue || normalizedItemLabel.includes(normalizedInputValue)
      );
    });

    if (!this.multiselect) {
      await this.updateComplete;

      this._isLoading = false;
      // Updates selected displayed choices
      return this._updateSelectedDisplayedChoices();
    }
    this._isLoading = false;
    return this.updateComplete;
  }

  /**
   * Updates selected displayed choices (Single selection only)
   *
   * Removes current selection in order to avoid menu from break on render,
   * then waits until lifecycle performs update, in order to set selection
   * based on the new rendered items.
   *
   * Returns early if shouldn't update selected displayed choices.
   */
  protected async _updateSelectedDisplayedChoices(): Promise<unknown> {
    if (!this._shouldUpdateSelectedDisplayedChoices()) {
      return this.requestUpdate(); // Return early
    }

    const currentSelectedItem = this.menu!.items.find(item => item.selected);

    if (currentSelectedItem) {
      currentSelectedItem.selected = false;
    }

    // Waits until lifecycle perform update
    await this.requestUpdate();

    const selectedItem = this.menu!.items.find(item =>
      this.selectedChoices.find(choice => choice.value === item.value),
    );

    setTimeout(() => {
      if (selectedItem) {
        selectedItem.selected = true;
      }
    }, 0);

    return this.requestUpdate();
  }

  /**
   * Loads async choices
   *
   * Get choices using async getChoices method, then
   * updates and filters choices with the new data.
   */
  protected _loadChoices() {
    if (this._shouldLoadChoices()) {
      this._isLoading = true;
      this.requestUpdate();

      this.choicesLoader!.debounce(this.debouncetime)
        .getChoices(this._inputValue)
        .then(async response => {
          const choices = response as Choice[];

          this._isLoading = false;

          // not sure what the goal of this was but I can't think of any reason to keep it
          // const nextChoices = choices.filter(
          //   item => !this.choices
          //     .find(choice => choice.value === item.value)
          // );
          const nextChoices = choices;

          // this.choices = [
          //   ...this.choices,
          //   ...nextChoices
          // ];
          this.choices = nextChoices;

          this._hasLoadedChoices = true;

          await this.updateComplete;
          await this.requestUpdate();

          this._filterChoices();
        });
    }

    return this.requestUpdate();
  }

  /**
   * Normalizes text using normalize-diacritics
   */
  protected _normalizeText(str): string {
    return str? normalizeSync(str.toLowerCase().trim()): '';
  }

  // Public Actions

  /**
   * Focuses form element
   */
  public focus() {
    this.formElement!.focus();
  }

  /**
   * Blurs form element
   */
  public blur() {
    this.dirty = true;
    this.formElement!.blur();
  }
}

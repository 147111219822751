import { property, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { IconButtonBase as MWCIconButton } from './icon-button-base';
import { gskStyle as mwcStyle } from './mwc-icon-button-css';
import { gskStyle } from './gsk-icon-button-css';

export class IconButtonBase extends MWCIconButton {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Optional. Default value is true. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = true;

  /**
   * Optional. Default value is true. Use to sets the primary color
   */
  @property({ type: Boolean })
  public secondary = false;

  /**
   * Optional. Default value sets to false. Styles an outlined icon-button that is flush with the surface.
   */
  @property({ type: Boolean })
  public outlined = false;

  /**
   * Optional. Default value is false. Use to sets the inverted color to be used on dark backgrounds
   */
  @property({ type: Boolean })
  public inverted = false;

  /**
   * Optional. Default value to false. Modifies the icon-button to a smaller size
   */
  @property({ type: Boolean })
  public mini = false;

  /**
   * Optional. Default value to false. Fills background color with the primary color.
   */
  @property({ type: Boolean })
  public filled = false;

  /**
   * Optional. Default value is false. To use the parent color for the gsk-icon-button
   */
  @property({ type: Boolean })
  public useparentcolor = false;

  /**
   * This method is invoked whenever the gsk-icon-button is updated
   * @param changedProperties Map of changed properties with old values
   */
  updated(changedProperties): void {
    super.updated(changedProperties);

    if (changedProperties.has('primary')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--primary', this.primary && !this.secondary);
    }

    if (changedProperties.has('secondary')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--secondary', this.secondary);
    }

    if (changedProperties.has('outlined')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--outlined', this.outlined);
    }

    if (changedProperties.has('inverted')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--inverted', this.inverted);
    }

    if (changedProperties.has('filled')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--filled', this.filled);
    }

    if (changedProperties.has('mini')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--mini', this.mini);
      this.mdcRoot.classList.toggle('mdc-icon-button--outlined', this.mini || this.outlined);
      this.mdcRoot.classList.toggle('mdc-icon-button--primary', this.mini || this.primary);
    }

    if (changedProperties.has('useparentcolor')) {
      this.mdcRoot.classList.toggle('mdc-icon-button--use-parent-color', this.useparentcolor);
    }
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    return html`
      <button
        class="mdc-icon-button"
        @click="${this.handleClick}"
        aria-label="${this.label}"
        ?disabled="${this.disabled}"
      >
        <div class="icon-container">
          <i class="material-icons mdc-icon-button__icon" aria-hidden="true">${this.offIcon}</i>
          <i
            class="material-icons mdc-icon-button__icon mdc-icon-button__icon--on"
            aria-hidden="true"
            >${this.icon}</i
          >
        </div>
      </button>
    `;
  }
}

import { query, property, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { ButtonBase as MWCButton } from './mwc-button-base';
// eslint-disable-next-line import/no-unresolved
import { gskStyle as mwcStyle } from './mwc-button-css';
// eslint-disable-next-line import/no-unresolved
import { gskStyle } from './gsk-button-css';

export class ButtonBase extends MWCButton {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @query('.mdc-button')
  protected formElement?: HTMLButtonElement;

  /**
   * Optional. Default value is 'none'. Use to sets the elevation of the button with values 'low', 'med' and 'high'
   */
  @property({ type: String })
  public elevation = 'none';

  /**
   * Optional. Default value is false. Use to sets the inverted color to be used on dark backgrounds
   */
  @property({ type: Boolean })
  public inverted = false;

  /**
   * Optional. Default value is false. Use to sets the secondary color
   */
  @property({ type: Boolean })
  public secondary = false;

  /**
   * Optional. Default value is false. To use the parent color for the gsk-button
   */
  @property({ type: Boolean })
  public useparentcolor = false;

  @property({ type: String, reflect: true })
  public type = 'button';

  /**
   * Optional. Used as id to submit a form when `type` is 'submit'
   */
  @property({ type: String })
  public form = '';

  /**
   * Root element for gsk-button component. This root element is use for MDC Foundation usage
   */
  @query('.mdc-button')
  public mdcRoot!: HTMLElement;

  protected _handleClick = this._onClick.bind(this);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected firstUpdated(_changedProperties: Map<PropertyKey, unknown>): void {
    this.addEventListener('click', this._handleClick);
  }

   /**
   * Optional. Default value sets to false. Styles a contained button that is elevated above the surface.
   */
  @property({ type: Boolean, reflect: true })
  public danger = false;

  /**
   * Optional. Default value sets to false. Styles a contained button that is elevated above the surface.
   */
  @property({ type: Boolean, reflect: true })
  public filled = false;

  /**
   * This method is invoked whenever the gsk-button is updated
   * @param changedProperties Map of changed properties with old values
   */
  protected updated(changedProperties): void {
    super.updated(changedProperties);

    if (changedProperties.has('elevation')) {
      const oldValue = changedProperties.get('elevation');
      this.mdcRoot.classList.remove(`mdc-button--elevation-${oldValue}`);

      if (this.elevation !== 'none') {
        this.mdcRoot.classList.add(`mdc-button--elevation-${this.elevation}`);
      }
    }

    if (changedProperties.has('inverted')) {
      this.mdcRoot.classList.toggle('mdc-button--inverted', this.inverted);
    }

    if (changedProperties.has('danger')) {
      this.mdcRoot.classList.toggle('mdc-button--danger', this.danger);
    }

    if (changedProperties.has('secondary')) {
      this.mdcRoot.classList.toggle('mdc-button--secondary', this.secondary);
    }

    if (changedProperties.has('useparentcolor')) {
      this.mdcRoot.classList.toggle('mdc-button--use-parent-color', this.useparentcolor);
    }

    if (changedProperties.has('type')) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.formElement!.type = this.type;
    }
  }

  protected _onClick(): void {
    if (this.type === 'submit' && Boolean(this.form)) {
      const doc = this.ownerDocument;
      if (doc) {
        const form = doc.querySelector<HTMLFormElement>(`#${this.form}`);

        if (form && form.submit) {
          form.submit();
        }
      }
    }
  }
}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  property,
  query,
  PropertyValues,
  classMap,
  SpecificEventListener,
  addHasRemoveClass,
  emit,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import MDCTopAppBarBaseFoundation from '@material/top-app-bar/foundation';
import MDCTopAppBarFoundation from '@material/top-app-bar/standard/foundation';
import MDCShortTopAppBarFoundation from '@material/top-app-bar/short/foundation';
import MDCFixedTopAppBarFoundation from '@material/top-app-bar/fixed/foundation';
import { MDCTopAppBarAdapter } from '@material/top-app-bar/adapter';

export const EVENTS = {
  nav: 'nav'
};

export class TopAppBarBase extends BaseElement {

  protected mdcFoundation!: MDCTopAppBarBaseFoundation;

  protected get mdcFoundationClass(): any {
    return this.type === 'fixed' || this.type === 'prominentFixed' ? MDCFixedTopAppBarFoundation :
      (this.type === 'short' || this.type === 'shortCollapsed' ? MDCShortTopAppBarFoundation : MDCTopAppBarFoundation);
  }

  @query('.mdc-top-app-bar')
  protected mdcRoot!: HTMLElement;

  @query('[name="navigationIcon"]')
  protected _navIconSlot!: HTMLSlotElement;

  @query('[name="actionItems"]')
  protected _actionItemsSlot!: HTMLSlotElement;

  /**
   * Optional. Use this property to set up how to display the top-app-bar.
   * Use any of the following type variants: 'fixed', 'prominent', 'short', 'shortCollapsed' and 'prominentFixed'
   */
  @property({ reflect: true })
  type = '';

  /**
   * Optional. Default value sets to false. Makes the top-app-bar text and container slightly smaller.
   */
  @property({ type: Boolean, reflect: true })
  dense = false;

  /**
   * Optional. Default value sets to false. Makes the top-app-bar's title centered
   */
  @property({ type: Boolean, reflect: true })
  centerTitle = false;

  protected _scrollTarget!: HTMLElement | Window;

  /**
   * Gets the scroll Target of the top-app-bar
   */
  get scrollTarget() {
    return this._scrollTarget || window as Window;
  }

  /**
   * Sets the scroll Target of the top-app-bar
   */
  set scrollTarget(value) {
    const old = this.scrollTarget;
    this._scrollTarget = value;
    this.requestUpdate('scrollTarget', old);
  }

  // TODO(sorvell): MDC decorates the navigation icon and action items with
  // ripples. Since these are slotted items here, the assumption is that the
  // user brings a web component with a ripple if rippling is desired.
  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const classes = {
      'mdc-top-app-bar--fixed': this.type === 'fixed' || this.type === 'prominentFixed',
      'mdc-top-app-bar--short': this.type === 'shortCollapsed' || this.type === 'short',
      'mdc-top-app-bar--short-collapsed': this.type === 'shortCollapsed',
      'mdc-top-app-bar--prominent': this.type === 'prominent' || this.type === 'prominentFixed',
      'mdc-top-app-bar--dense': this.dense,
      'gsk-mwc-top-app-bar--center-title': this.centerTitle
    };
    const alignStartTitle = !this.centerTitle ? html`
      <span class="mdc-top-app-bar__title">
        <slot name="title"></slot>
      </span>
    ` : '';
    const centerSection = this.centerTitle ? html`
      <div class="mdc-top-app-bar__section mdc-top-app-bar__section--align-center">
        <span class="mdc-top-app-bar__title">
          <slot name="title"></slot>
        </span>
      </div>` : '';
    return html`
      <header class="mdc-top-app-bar ${classMap(classes)}">
        <div class="mdc-top-app-bar__row">
          <div class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
            <slot name="navigationIcon"></slot>
            ${alignStartTitle}
          </div>
          ${centerSection}
          <div class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
            <slot name="actionItems"></slot>
          </div>
        </div>
      </header>`;
  }

  protected createAdapter(): MDCTopAppBarAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      setStyle: (property: string, value: string) => this.mdcRoot.style.setProperty(property, value),
      getTopAppBarHeight: () => this.mdcRoot.clientHeight,
      // TODO(sorvell): don't understand why the top-app-bar knows about navigation
      registerNavigationIconInteractionHandler: (type: string, handler: any) => {
        if (this._navIconSlot) {
          this._navIconSlot.addEventListener(type, handler);
        }
      },
      deregisterNavigationIconInteractionHandler: (type, handler) => {
        if (this._navIconSlot) {
          this._navIconSlot.removeEventListener(type, handler);
        }
      },
      notifyNavigationIconClicked: () => {
        emit(this, EVENTS.nav, {}, true);
      },
      registerScrollHandler: (handler: SpecificEventListener<'scroll'>) =>
        this.scrollTarget.addEventListener('scroll', handler as EventListenerOrEventListenerObject),
      deregisterScrollHandler: (handler: SpecificEventListener<'scroll'>) =>
        this.scrollTarget.removeEventListener('scroll', handler as EventListenerOrEventListenerObject),
      registerResizeHandler: (handler: SpecificEventListener<'resize'>) =>
        window.addEventListener('resize', handler),
      deregisterResizeHandler: (handler: SpecificEventListener<'resize'>) =>
        window.removeEventListener('resize', handler),
      getViewportScrollY: () => this.scrollTarget[this.scrollTarget === window ? 'pageYOffset' : 'scrollTop'],
      getTotalActionItems: () =>
        this._actionItemsSlot.assignedNodes({ flatten: true }).length,
    };
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   *
   * Override that prevents `super.firstUpdated` since we are controlling when `createFoundation` is called.
   */
  firstUpdated() { }

  /**
   * This method is invoked whenever the top-app-bar is updated
   *
   * @param _changedProperties Map of changed properties with old values
   */
  updated(_changedProperties: PropertyValues) {
    // update foundation if `type` or `scrollTarget` changes
    if (_changedProperties.has('type') || _changedProperties.has('scrollTarget')) {
      this.createFoundation();
    }
  }

  /**
   * Create and attach the MDC Foundation to the instance
   */
  createFoundation() {
    super.createFoundation();
    const windowScroller = this.scrollTarget === window;
    // we add support for top-app-bar's tied to an element scroller.
    this.mdcRoot.style.position = windowScroller ? '' : 'absolute';
    // TODO(sorvell): not sure why this is necessary but the MDC demo does it.
    this.mdcRoot.style.top = windowScroller ? '0px' : '';
  }
}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { FabBase } from './gsk-fab-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-fab': Fab;
  }
}

@customElementDefinition('gsk-fab')
export class Fab extends FabBase {}

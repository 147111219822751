import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { CircularProgressBase } from './gsk-circular-progress-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-circular-progress': CircularProgress;
  }
}

@customElementDefinition('gsk-circular-progress')
export class CircularProgress extends CircularProgressBase {}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { CardBase } from './gsk-card-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-card': Card;
  }
}

@customElementDefinition('gsk-card')
export class Card extends CardBase {}

import { property, query, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { MenuBase as MWCMenu } from './mwc-menu-base';
import { gskStyle as mwcStyle } from './mwc-menu-css';
import { ListBase as GSKList } from '@gsk-tech/gsk-list/mwc-list-base';
import { findAssignedElement, emit } from '@gsk-tech/gsk-base/utils_mwc';
import { classMap } from 'lit/directives/class-map.js';
import { gskStyle } from './gsk-menu-css';

export class MenuBase extends MWCMenu {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @query('.mdc-menu__content')
  protected menuContentEl!: HTMLElement;

  /**
   * Optional. Default value is `auto` and it will be calculated by the available area. Sets a `max-height` on the menu surface.
   */
  @property({ type: String })
  public maxheight = 'auto';

  @property({ type: Boolean, reflect: true })
  public wrap = false;

  /**
   * Gets the slot list element
   */
  public get list() {
    return this.slotEl && findAssignedElement(this.slotEl, 'gsk-list') as GSKList;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const classes = {
      'mdc-menu': true,
      'mdc-menu--wrap': this.wrap,
      'mdc-menu-surface': true
    }

    return html`
      <div class="${classMap(classes)}">
        <div class="mdc-menu__content">
          <slot></slot>
        </div>
      </div>
    `;
  }

  protected _onMenuSurfaceOpened(evt) {
    super._onMenuSurfaceOpened(evt);
    emit(this, 'opened', evt.detail, true);

    this._layout();
  }

  protected _onMenuSurfaceClosed(evt) {
    super._onMenuSurfaceClosed(evt);
    emit(this, 'closed', evt.detail, true);
  }

  protected _layout() {
    const menuMaxHeight = getComputedStyle(this.mdcRoot).maxHeight!.replace('px', '');
    const menuPaddingTop = getComputedStyle(this.mdcRoot).paddingTop!.replace('px', '');
    const menuPaddingBottom = getComputedStyle(this.mdcRoot).paddingBottom!.replace('px', '');
    const desiredMaxHeight = (
      !isNaN(Number(this.maxheight)) &&
      Number(this.maxheight) < Number(menuMaxHeight)
    ) ? Number(this.maxheight) : Number(menuMaxHeight);
    const verticalPadding = Number(menuPaddingTop) + Number(menuPaddingBottom);

    this.mdcRoot.style.maxHeight = `${desiredMaxHeight}px`;
    this.menuContentEl.style.maxHeight = `${desiredMaxHeight - verticalPadding}px`;
  }
}

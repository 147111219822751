import { ensureCustomElementLoaded, customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { RadioBase } from './gsk-radio-base';
import { RadioGroupBase as GSKRadioGroupBase } from './gsk-radio-group-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-radio-group': RadioGroup;
  }
}

@ensureCustomElementLoaded('gsk-radio', 'gsk-radio-group')
export class Radio extends RadioBase {}

@customElementDefinition('gsk-radio-group')
export class RadioGroup extends GSKRadioGroupBase {}

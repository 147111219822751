import {
  LitElement,
  query,
  property,
  TemplateResult,
  classMap,
  html,
  unsafeCSS
} from '@gsk-tech/gsk-base/base-element';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import { gskStyle } from './gsk-file-upload-css';
import { createValidityObj } from '@gsk-tech/gsk-base/constraint-validation';

export interface FileDetail {
  name: string;
  size: string;
  fileContent: string;
  showThumbnail: boolean;
}

export class FileUploadBase extends LitElement {
  public static get styles() {
    return unsafeCSS(gskStyle);
  }

  /**
   * Root element for file upload component
   */
  @query('.file-upload')
  public mdcRoot!: HTMLElement;

  /**
   * Optional. Use this property to set the formats accepted by the file-input
   */
  @property({ type: String })
  public accept = '';

  /**
   * Optional. It specifies which camera to use for capture of image or video data, if the 'accept' attribute indicates that the input should be of one of those types.
   */
  @property({ type: String })
  public capture = '';

  /**
   * Use this query to get the drop zone element
   */
  @query('.file-upload__drop-zone')
  public dropZone!: HTMLElement;

  /**
   * Use this query to get the file input element
   */
  @query('.file-upload__input')
  public fileInput!: HTMLInputElement;

  /**
   * Optional. Setter/getter for the file upload name
   */
  @property({ type: String })
  public name = '';

  @property({ type: Boolean })
  public condensed = false;

  /**
   * Optional. Default value is false. Use this property to upload multiple files
   */
  @property({ type: Boolean })
  public multiple = false;

  /**
   * Optional. Use this property to set the file upload placeholder
   */
  @property({ type: String })
  public placeholder;

  /**
   * Use this property to set the button type for the upload, being text or filled
   */
  @property({ type: String })
  public buttontype = 'text';

  /**
   * Optional. Default value is false.
   * Use it to make the component required and activate native validation
   */
  @property({ type: Boolean, reflect: true })
  public required = false;

  /**
   * Optional. Default is false. If set, reportValidity method will be called on firstUpdated.
   */
  @property({ type: Boolean })
  public validateoninitialrender = false;

  /**
   * Optional. Use it to force the validity state of the component,
   * even if the `required` prop is true
   */
  @property({ type: Boolean })
  public valid?: boolean;

  protected _output: FileDetail[] = [];

  protected _uploadedFiles!: File[];

  protected _validity: ValidityState = createValidityObj();

  /**
   * A read-only property that returns a ValidityState object,
   * whose properties represent validation errors for the value of that element.
   */
  get validity(): ValidityState {
    this._checkValidity();

    return this._validity;
  }

  /**
   * A read-only boolean property that returns true if the element is a candidate for constraint validation;
   * and false otherwise.
   */
  get willValidate(): boolean {
    return this.fileInput.willValidate;
  }

  /**
   * Checks the element's validity.
   * If the value is invalid, it fires an invalid event at the element and returns false;
   * otherwise it returns true.
   */
  checkValidity(): boolean {
    const isValid = this._checkValidity();

    if (!isValid) {
      const invalidEvent =
        new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  protected _checkValidity() {
    const objValidity = this.valid !== undefined
        ? { valid: this.valid }
        : Boolean(this.value.length)
          ? { valid: true }
          : this.fileInput.validity;

    const validity = createValidityObj(objValidity);

    this._validity = validity;

    return this._validity.valid;
  }

  /**
   * Get the array of uploaded HTML File Objects
   */
  public get files(): File[] {
    return this._uploadedFiles;
  }

  /**
   * DEPRECATED - Use .value instead
   *  Get the files output array
   */
  public get output() {
    return this._output;
  }

  /**
   * Get the array of uploaded FileDetails
   */
  public get value(): FileDetail[] {
    return this._output;
  }

  /**
   * Initialize the set of FileDetails - will create File Objects based on fileContent Property
   */
  public set value(files: FileDetail[]) {
    this._importFiles(files);
    this._updateOutput(files);
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  protected firstUpdated(): void {
    this.dropZone.addEventListener('dragover', this._handleDragOver.bind(this), false);
    this.dropZone.addEventListener('drop', this._handleDragAndDropFileSelection.bind(this), false);
    this.fileInput.addEventListener('change', this._handleInputFileSelection.bind(this), false);

    if (this.validateoninitialrender) {
      this.reportValidity();
    }
  }

  /**
   * This method is invoked whenever the gsk-checkbox is updated
   */
  protected updated(changedProperties): void {
    super.updated(changedProperties);

    if (changedProperties.has('required')) {
      this.fileInput.required = this.required;
    }
  }

  protected _extractFiles(files: FileList) {
    const newFiles = [...files];
    const fileList = [];

    for (const file in newFiles) {
      if (newFiles.hasOwnProperty(file)) {
        fileList.push(newFiles[file] as never);
      }
    }

    return fileList;
  }

  public static dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  // Imports FileDetail[] to File[]
  protected _importFiles(fileList: FileDetail[]) {
    this._updateFiles(fileList.map(
      (def: FileDetail): File => {
        const blob: Blob = FileUploadBase.dataURItoBlob(def.fileContent);
        const file = new File([blob], def.name);
        return file;
      },
    ));
  }

  // Converts from Native File[] to FileDetail[]
  protected _handleFiles(files: FileList): Promise<FileDetail[]> {
    const fileList = this._extractFiles(files);
    this._updateFiles(this.multiple ? [...(this.files || []), ...fileList] : [fileList[0]]);

    return this._returnFileListDetails(this.files, this.multiple);
  }

  protected async _removeFile(fileIndex: number) {
    this._updateFiles(this.files.filter((_, i: number) => i !== fileIndex));
    this._updateOutput(await this._returnFileListDetails(this.files, this.multiple));
  }

  /**
   *  Triggered after user selects a file
   */
  protected async _handleInputFileSelection() {
    const { files } = this.fileInput;

    if (files !== null) {
      this._updateOutput(await this._handleFiles(files));
    }
  }

  protected async _handleDragAndDropFileSelection(e) {
    e.stopPropagation();
    e.preventDefault();

    this.dropZone.classList.remove('file-upload__drop-zone--dragging');

    const { files } = e.dataTransfer;
    this._updateOutput(await this._handleFiles(files));
  }

  protected _handleDragOver(e): void {
    this.dropZone.classList.add('file-upload__drop-zone--dragging');
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }

  protected _triggerInputFileClick() {
    this.fileInput.click();
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  protected render(): TemplateResult {
    const fileUploadClasses = {
      'file-upload': true,
      'file-upload--condensed': this.condensed,
    };

    return html`
      <div class="${classMap(fileUploadClasses)}">
        <div class="file-upload__drop-zone">
          ${this.buttontype === 'text' ? this._renderUploadIcon() : null}
          ${this.placeholder || `Drag and drop file${this.multiple ? 's' : ''} or `}
          ${this.buttontype === 'filled' ? this._renderFilledButton() : this._renderTextButton()}

          <input
            type="file"
            accept="${this.accept}"
            capture="${this.capture}"
            class="file-upload__input"
            ?multiple=${this.multiple}
          />
        </div>
        ${this._renderFileOutput(this._output)}
      </div>
    `;
  }

  protected _renderUploadIcon() {
    return html`
      <div class="file-upload__upload-icon-wrapper">
        <gsk-icon-button
          useparentcolor
          label="Upload"
          class="file-upload__upload-icon"
          @click="${this._triggerInputFileClick}"
          icon="upload"
        ></gsk-icon-button>
      </div>
    `;
  }

  protected _renderTextButton() {
    return html`
      <button @click=${this._triggerInputFileClick} class="file-upload__btn">
        click to upload
      </button>
    `;
  }

  protected _renderFilledButton() {
    return html`
      <gsk-button
        class="file-upload__btn file-upload__btn--filled"
        filled
        @click=${this._triggerInputFileClick}
        label="Choose file${this.multiple ? 's' : ''}"
      >
      </gsk-button>
    `;
  }

  protected _renderFileOutput(files: FileDetail[]): TemplateResult {
    return html`
      <div class="file-upload__output">
        <ul class="file-upload__list">
          ${files ? this._renderFilesDetail(files) : null}
        </ul>
      </div>
    `;
  }

  protected _renderFilesDetail(files: FileDetail[]) {
    return files.map(
      (file: FileDetail, i: number): TemplateResult => {
        const listItemClasses = {
          'list-item': true,
          'list-item--with-image-thumbnail': file.showThumbnail,
        };

        if (this.condensed) {
          return html`
            <li class="${classMap(listItemClasses)}">
              ${file.showThumbnail ? this._renderThumbnail(file) : null}
              <span class="list-item__name">${file.name}</span>

              <div class="list-item__floating">
                <span class="list-item__file-size">${file.size}</span>
                <gsk-icon-button
                  mini
                  class="list-item__remove-item"
                  @click="${() => this._removeFile(i)}"
                  primary
                  icon="trash"
                >
                </gsk-icon-button>
              </div>
            </li>
          `;
        }

        return html`
          <li class="list-item">
            ${file.showThumbnail ? this._renderThumbnail(file) : null}

            <div class="list-item__layer">
              <gsk-icon-button
                useparentcolor
                class="list-item__remove-item"
                primary
                @click="${() => this._removeFile(i)}"
                icon="trash"
              >
              </gsk-icon-button>
            </div>
          </li>
        `;
      },
    );
  }

  protected _renderThumbnail(file: FileDetail) {
    const splitted = file.name.split('.');
    const extension = splitted.length ? splitted[splitted.length - 1].toLowerCase() : null;

    const extensionIconMap = {
      'pdf': 'file_pdf',
      'mp3': 'file_audio',
      'mp4': 'file_video',
      'docx': 'file_word',
      'doc': 'file_word',
      'xls': 'file_excel',
      'xlsx': 'file_excel',
      'txt': 'file_text',
    };

    if (file.showThumbnail && extension && Object.keys(extensionIconMap).includes(extension)) {
      return html`
        <gsk-icon class="list-item__thumbnail-icon">${extensionIconMap[extension]}</gsk-icon>
      `;
    }

    return html`
      <img
        src="${file.showThumbnail ? file.fileContent : ''}"
        alt="${file.name}"
        class="list-item__thumbnail"
      />
    `;
  }

  protected _renderImage(file: File): Promise<string> {
    return new Promise(
      (resolve): void => {
        if (file.type.match('image.*')) {
          const reader = new FileReader();

          reader.onload = (function() {
            return function(e): void {
              resolve(e.target.result);
            };
          })();

          reader.readAsDataURL(file);
        } else {
          resolve('');
        }
      },
    );
  }

  /**
   * Calls `checkValidity()` and also reports the validity status to the user
   * in whatever way the user needs.
   */
  reportValidity(): boolean {
    return this.checkValidity();
  }

  _checkShowThumbnail(file: File) {
    const allowedExtensions = ['.pdf', '.mp3', '.mp4', '.docx', '.doc', '.xls', '.xlsx', '.txt'];
    return !!file.type.match('image.*') || allowedExtensions.some(ext => file.name.toLowerCase().endsWith(ext));
  }

  protected async _createFileDetail(file: File) {
    const fileContent = await this._readFileContent(file);
    return {
      name: escape(file.name),
      size: `${(file.size / (1024 * 1024)).toFixed(1)}MB`,
      showThumbnail: this._checkShowThumbnail(file),
      fileContent,
    };
  }

  protected async _returnFileListDetails(files: File[], multiple: boolean): Promise<FileDetail[]> {
    const filesDetailList: FileDetail[] = [];

    for (let x = 0; x < files.length; x += 1) {
      // eslint-disable-next-line no-await-in-loop
      filesDetailList.push(await this._createFileDetail(files[x]));
      if (!multiple) {
        break;
      }
    }
    return filesDetailList;
  }

  protected async _readFileContent(file: File): Promise<string> {
    return new Promise(
      (resolve): void => {
        const reader = new FileReader();
        reader.onload = (function() {
          return function(e): void {
            resolve(e.target.result);
          };
        })();
        reader.readAsDataURL(file);
      },
    );
  }

  protected _updateOutput(files: FileDetail[]) {
    this._output = files;
    this.requestUpdate();
    emit(this, 'change', { value: files });
  }

  protected async _updateFiles(files: File[]) {
    this._uploadedFiles = files;

    await this.updateComplete;

    if (!Boolean(files.length)) this.fileInput.value = '';

    this.reportValidity();
    emit(this, 'select', { value: files });
  }
}

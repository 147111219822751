import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { IconButtonBase } from './gsk-icon-button-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-icon-button': IconButton;
  }
}

@customElementDefinition('gsk-icon-button')
export class IconButton extends IconButtonBase {}

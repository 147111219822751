import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { LinearProgressBase } from './gsk-linear-progress-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-linear-progress': LinearProgress;
  }
}

@customElementDefinition('gsk-linear-progress')
export class LinearProgress extends LinearProgressBase {}

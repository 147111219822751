import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { BadgeBase } from './gsk-badge-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-badge': Badge;
  }
}

@customElementDefinition('gsk-badge')
export class Badge extends BadgeBase {}
import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { AlertBase } from './gsk-alert-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-alert': Alert;
  }
}

@customElementDefinition('gsk-alert')
export class Alert extends AlertBase {}

import { classMap,  property, query, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { SnackbarBase as MWCSnackbar } from './mwc-snackbar-base';
import { gskStyle as mwcStyle } from './mwc-snackbar-css';
import { findAssignedElement } from '@gsk-tech/gsk-base/utils_mwc';
import { ButtonBase as GskOneButton } from '@gsk-tech/gsk-button/gsk-button-base';
import { IconButtonBase as GskOneIconButton } from '@gsk-tech/gsk-icon-button/gsk-icon-button-base';
import { gskStyle } from './gsk-snackbar-css';

  export class SnackbarBase extends MWCSnackbar {
    public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

    protected _types = ['information', 'warning', 'error', 'success'];

    @query('.mdc-snackbar__surface')
    protected surfaceEl!: HTMLElement

    @query('slot[name="action"]')
    protected slotActionEl!: HTMLSlotElement;

    @query('slot[name="dismiss"]')
    protected slotDismissEl!: HTMLSlotElement;

    @query('.dismiss-container')
    protected dismissContainerEl!: HTMLElement;

    /**
     * Optional. Default value is false. Removes the ripple effect on the Snackbar.
     */
    @property({ type: Boolean })
    public noripple = false;

    /**
     * Optional. Used to set the name of the icon to be displayed before the label.
     */
    @property({ type: String })
    public leadingIconContent = '';

    /**
     * Optional. Default value is information. Sets colors according the type value. Available values: information, warning, error and success
     */
    @property({ type: String })
    public type = 'information';

    /**
     * Used to render the lit-html TemplateResult with a label
     */
    protected _renderLabel(): TemplateResult {
      const hasLeadingIconContent = this.leadingIconContent.trim().length > 0
      const classes = {
        'mdc-snackbar__label': true,
        'mdc-snackbar__label--with-leading-icon': hasLeadingIconContent
      };

      return html`
        <div class="${classMap(classes)}" role="status" aria-live="polite">
          ${hasLeadingIconContent ? this._renderLeadingIcon() : ''}
          ${this.labelText}
        </div>
      `;
    }

    /**
     * Used to render the lit-html TemplateResult with a leading Icon
     */
    protected _renderLeadingIcon() {
      const classes = {
        'material-icons': true,
        'mdc-snackbar--leading-icon': true,
      };
      return html`
        <i class="${classMap(classes)}">${this.leadingIconContent}</i>
      `;
    }

    /**
     * Used to render the lit-html TemplateResult to the element's DOM
     */
    render(): TemplateResult {
      const classes = {
        'mdc-snackbar--stacked': this.stacked,
        'mdc-snackbar--leading': this.leading,
      };
      return html`
        <div class="mdc-snackbar ${classMap(classes)}" @keydown="${this._handleKeydown}">
          <div class="mdc-snackbar__surface">
            ${this._renderLabel()}
            <div class="mdc-snackbar__actions">
              <div class="action-container">
                <slot name="action" @click="${this._handleActionClick}"></slot>
              </div>
              <div class="dismiss-container">
                <slot name="dismiss" @click="${this._handleDismissClick}"></slot>
              </div>
            </div>
          </div>
        </div>`;
    }

    /**
     * This method is invoked whenever the gsk-snackbar is updated
     *
     * @param changedProperties Map of changed properties with old values
     */
    public updated(changedProperties): void {
      super.updated(changedProperties);

      if (changedProperties.has('type')) {
        if (this._isValidType(this.type)) {
          const oldValue = changedProperties.get('type');
          this.surfaceEl.classList.remove(`mdc-snackbar--${oldValue}`);

          this.surfaceEl.classList.add(`mdc-snackbar--${this.type}`);
          if(this._slottedAction) {
            this._slottedAction.inverted = this.type !== 'warning';
            this._slottedAction.secondary = this.type === 'warning';
          }
          if(this._slottedDismiss) {
            this._slottedDismiss.inverted = this.type !== 'warning';
          }
        }
      }
    }

    protected get _slottedAction(): GskOneButton {
      return this.slotActionEl && findAssignedElement(this.slotActionEl, 'gsk-button') as GskOneButton;
    }

    protected get _slottedDismiss(): GskOneIconButton {
      return this.slotDismissEl && findAssignedElement(this.slotDismissEl, 'gsk-icon-button') as GskOneIconButton;
    }

    protected _isValidType(type: String):boolean {
      let hasValidValue = false;
      for(let i=0; i<=this._types.length-1; i++) {
        if(this._types[i] === type.toLowerCase().trim()) {
          hasValidValue = true;
          break;
        }
      }
      return hasValidValue;
    }
  }

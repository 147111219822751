import { property, queryAll, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { ChipSetBase as MWCChipSet } from './mwc-chip-set-base';
import { gskStyle as mwcStyle } from './mwc-chip-set-css';
import { ChipBase as GSKChip } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { findAssignedElements } from '@gsk-tech/gsk-base/utils_mwc';
import { gskStyle } from './gsk-chip-set-css';

const FORWARDED_PROPS = new Set(['noripple', 'borderradius']);

export class ChipSetBase extends MWCChipSet {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @queryAll('gsk-chip')
  protected chipEls!: GSKChip[];

  /**
   * Optional. Default value is 16. Customize the border radius for all the chips.
   */
  @property({ type: Number })
  public borderradius = 16;

  /**
   * Optional. Default value is false. Removes the ripple effect on the Chip-set.
   */
  @property({ type: Boolean })
  public noripple = false;

  protected _chips: GSKChip[] = [];

  public get chips() {
    return [...this._chips];
  }

  protected get slottedChips(): GSKChip[] {
    return this.slotEl ? (findAssignedElements(this.slotEl, 'gsk-chip, gsk-chatbot-chip') as GSKChip[]) : [];
  }

  /**
   * This method is invoked whenever the gsk-chip-set is updated
   * @param changedProperties Map of changed properties with old values
   */
  protected updated(changedProperties): void {
    super.updated(changedProperties);

    // @ts-ignore
    changedProperties.forEach((oldValue, propName) => {
      if (FORWARDED_PROPS.has(propName)) {
        this.updateChildren(propName, this[propName]);
      }
    });
  }

  /**
   * This method updated each prop and value from the parent to children elements
   */
  public updateChildren(prop, value) {
    this.updateComplete.then(() => {
      this.chips.forEach(chip => (chip[prop] = value));
    });
  }
}

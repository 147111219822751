import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import {
  TabBarBase,
  TabBase,
  TabIndicatorBase,
  TabScrollerBase,
} from './gsk-tab-bar-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-tab': Tab;
    'gsk-tab-bar': TabBar;
    'gsk-tab-scroller': TabScroller;
    'gsk-tab-indicator': TabIndicator;
  }
}

@customElementDefinition('gsk-tab')
export class Tab extends TabBase {}

@customElementDefinition('gsk-tab-scroller')
export class TabScroller extends TabScrollerBase {}

@customElementDefinition('gsk-tab-indicator')
export class TabIndicator extends TabIndicatorBase {}

@customElementDefinition('gsk-tab-bar')
export class TabBar extends TabBarBase {}

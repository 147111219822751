import {
  property,
  classMap,
  query,
  unsafeCSS,
  observer,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import { findAssignedElement, emit } from '@gsk-tech/gsk-base/utils_mwc';
import { SelectBase as MWCSelect } from './mwc-select-base';
import { gskStyle as mwcStyle } from './mwc-select-css';
import { MenuBase as GSKMenu } from '@gsk-tech/gsk-menu/gsk-menu-base';
import { TextFieldBase as GSKTextField } from '@gsk-tech/gsk-textfield/gsk-textfield-base';
import {
  ListDividerBase as GSKListDivider,
  ListItemBase as GSKListItem,
} from '@gsk-tech/gsk-list/gsk-list-base';
import { CheckboxBase as GSKCheckbox } from '@gsk-tech/gsk-checkbox/gsk-checkbox-base';
import { normalizeSync } from 'normalize-diacritics';
import ResizeObserver from 'resize-observer-polyfill';
import {
  ConstraintValidationModel,
  createValidityObj,
  CustomValidityState,
  ValidityTransform,
} from '@gsk-tech/gsk-base/constraint-validation';
import { gskStyle } from './gsk-select-css';

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export class SelectBase extends MWCSelect implements ConstraintValidationModel {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @query('.select__filter')
  protected filterEl!: HTMLElement;

  @query('.select__select-all')
  protected selectAllEl!: HTMLElement;

  /**
   * Optional. Default value is false. Removes the ripple effect on the select.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Setter/getter for the select's name
   */
  @property({ type: String })
  @observer(function(this: SelectBase) {
    this._updateName();
  })
  public name = '';

  /**
   * Optional. Default value is 'text'. Use this property to specifying the type of control to render.
   */
  @property({ type: String, reflect: true })
  public type = 'text';

  /**
   * Optional. Use this property to specifying the tooltip text to be displayed for adjacent label
   */
  @property({ type: String })
  public labeltooltiptext = '';

  /**
   * Optional. Default value is set to 'Select All'. Works only when 'multiple' property is set to true.
   * Adds a text to the select all item
   */
  @property({ type: String })
  public selectalllabel = 'Select All';

  /**
   * Optional. Default value is set to 'Clear All'. Works only when 'multiple' property set to true.
   * Adds a label to the clear all button.
   */
  @property({ type: String })
  public clearalllabel = 'Clear All';

  /**
   * Optional. Default value is set to false. Works only when 'multiple' property set to true. Add this property when you want to display the filtering input.
   */
  @property({ type: Boolean })
  public filter = false;

  /**
   * Optional. Default is false. If set, reportValidity method will be called on firstUpdated.
   */
  @property({ type: Boolean })
  public validateOnInitialRender = false;

  @property({ type: Number })
  public minValues: number = 0;

  @property({ type: Number })
  public maxValues: number = Infinity;

  @property({ type: Function })
  public validityTransform: ValidityTransform<string | string[]> | null = null;

  protected _userValidity?: boolean;

  protected get _userValiditySet(): boolean {
    return this._userValidity !== undefined;
  }

  protected _useNativeValidation = true;

  /**
   * Optional. Use it to force the validity state of the component,
   * even if the `required` prop is true.
   */
  @property({ type: Boolean })
  public get valid(): boolean | undefined {
    return this._userValidity;
  }

  public set valid(isValid) {
    this._userValidity = isValid;
    if (isValid !== undefined) {
      const updateValidity = (): void => {
        this.mdcFoundation?this.mdcFoundation.setValid(isValid):'';
        this._setValidity(isValid);
        this._checkValidity();
        this.requestUpdate();
      };
      if (this.mdcFoundation) {
        updateValidity();
      } else {
        this.updateComplete.then(() => {
          updateValidity();
        });
      }
    } else {
      this.reportValidity();
    }
  }

  @property({ type: Boolean })
  public dirty = false;

  protected _validity: ValidityState = createValidityObj();

  /**
   * A read-only property that returns a ValidityState object,
   * whose properties represent validation errors for the value of that element.
   */
  public get validity(): Readonly<ValidityState> {
    if (!this.willValidate) {
      return createValidityObj();
    }
    this._checkValidity();
    return this._validity;
  }

  /**
   * A read-only boolean property that returns true if the element is a candidate for constraint validation;
   * and false otherwise.
   */
  public get willValidate(): boolean {
    const wv = (this.formElement as HTMLInputElement).willValidate as boolean | undefined;
    if (typeof wv === 'boolean') {
      return wv;
    }

    // TODO: make this better
    // there's more to it than this, but with no native form element to delegate to
    // this will have to do for now
    return !this.disabled;
  }

  protected get inputs(): any[] {
    return [...this.querySelectorAll('gsk-checkbox')];
  }

  /**
   * Checks the element's value against its constraints and also reports the validity status;
   * if the value is invalid, it fires an invalid event at the element, returns false,
   * and then reports the validity status to the user in whatever way the user agent has available.
   * Otherwise, it returns true.
   */
  public reportValidity(): boolean {
    const isValid = this.checkValidity();
    this.internalValidity = isValid;
    return isValid;
  }

  public get isUiValid(): boolean {
    if (this.willValidate) {
      if (this.valid !== undefined) {
        return this.valid;
      }

      if (this.dirty || this.validateOnInitialRender) {
        return this.validity.valid;
      }
    }
    return true;
  }

  /**
   * Checks the element's value against its constraints.
   * If the value is invalid, it fires an invalid event at the element and returns false;
   * otherwise it returns true.
   */
  public checkValidity(): boolean {
    const isValid = this._checkValidity();

    if (!isValid) {
      const invalidEvent = new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  public setCustomValidity(message: string) {
    this.validationMessage = message;
    const isError = message !== '';
    this._validity = createValidityObj({
      customError: isError,
    });
    this.dirty = true;
    this.internalValidity = !isError;
    this.requestUpdate();
  }

  protected _checkValidity(): boolean {
    const { value } = this;
    let v: string[];

    if (Array.isArray(value)) {
      v = value;
    } else if (value) {
      v = [value];
    } else {
      v = [];
    }

    const len: number = v.length;
    const isCustomError = this._validity.customError;
    const nativeValidity: CustomValidityState = createValidityObj({
      customError: isCustomError,
      valid: !isCustomError,
    });
    let { valid } = nativeValidity;
    if (this.required && valid) {
      if (len < 1) {
        nativeValidity.valueMissing = true;
        valid = false;
      }
    }
    if (this.minValues !== undefined && valid) {
      if (len && len < this.minValues) {
        nativeValidity.rangeUnderflow = true;
        valid = false;
      }
    }
    if (this.maxValues !== undefined && valid) {
      if (len > this.maxValues) {
        nativeValidity.rangeOverflow = true;
        valid = false;
      }
    }

    nativeValidity.valid = nativeValidity.valid && valid;

    let validity = nativeValidity;
    if (this.validityTransform) {
      const transformedValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...transformedValidity };
    }

    this._validity = validity;
    return this._validity.valid;
  }

  protected get menuEl(): GSKMenu {
    return findAssignedElement(this.slotEl, 'gsk-menu') as GSKMenu;
  }

  protected _hasSelectAll = false;

  protected _hasFilter = false;

  /**
   * Used to render the lit-html TemplateResult for the adjacent label
   */
  protected _renderAdjacentLabel() {
    const showIconLabel = this.labeltooltiptext && this.labeltooltiptext.trim().length > 0;
    const classes = {
      'mdc-floating-label--adjacent': true,
      'mdc-floating-label--adjacent-with-icon': showIconLabel,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.label} ${showIconLabel ? this._renderIconLabel() : ''}
      </div>
    `;
  }

  protected _formElementId = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  /**
   * Used to render the lit-html TemplateResult for the icon for adjacent label
   */
  protected _renderIconLabel() {
    const classes = {
      'mdc-floating-label--adjacent-icon': true,
    };

    return html`
      <div class="${classMap(classes)}">
        <gsk-icon class="icon-label-with-tooltip" id="${this._formElementId}"
          >info_outline</gsk-icon
        >
        <gsk-tooltip
          for="${this._formElementId}"
          text="${this.labeltooltiptext}"
          placement="above"
          offset="${-2}"
          showDelay="${500}"
        ></gsk-tooltip>
      </div>
    `;
  }

  protected _renderFilter() {
    return html`
      <gsk-textfield
        class="select__filter"
        placeholder="Search"
        fullwidth
        trailingIconContent="search"
      ></gsk-textfield>
    `;
  }

  protected _renderSelectAll() {
    return html`
      <gsk-list-item class="select__select-all" value="" role="checkbox">
        <gsk-checkbox secondary slot="graphic"></gsk-checkbox>
        Select All
        <gsk-button slot="meta" label="clear all" tabindex="0"></gsk-button>
      </gsk-list-item>
    `;
  }

  protected _renderDropdownIcon() {
    return html`
      <i class="material-icons mdc-select__dropdown-icon">chevron_down</i>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    return html`
      ${super.render()} ${this.multiple && this.filter ? this._renderFilter() : ''}
      ${this.multiple ? this._renderSelectAll() : ''}
    `;
  }

  protected updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('mdc-select--no-ripple', this.noripple);
    }

    if (changedProperties.has('type')) {
      this.mdcRoot.classList.toggle('mdc-select--text', this.type === 'text');
      this.mdcRoot.classList.toggle('mdc-select--chips', this.type === 'chips');
    }
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  public async firstUpdated() {
    if(this.disabled)
    this.mdcRoot.classList.toggle('mdc-select--disabled', this.disabled);
    await super.firstUpdated();
    this.addEventListener('chipschange', () => this.chipsLayout());
    this.mdcFoundation.isValid = () => this.isUiValid;

    if (this.menuEl) {
      this.menuEl.wrap = true;
    }

    this.updateComplete.then(() => {
      let resizeTimer = 0;
      const ro = new ResizeObserver(() => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => this._handleResizeObserver(), 10);
      });
      ro.observe(this.mdcRoot);

      if (this.validateOnInitialRender) {
        this.reportValidity();
      }
    });
  }

  protected _setTextContent(value: string) {
    if (this.type === 'chips' && this.multiple && Boolean(value)) {
      const chipsHTML = value
        .split(',')
        .filter(item => item)
        .map(
          (text, i) => `
          <gsk-chip style="order: ${i}">${text}</gsk-chip>
        `,
        )
        .join('');

      this._selectedTextInner!.innerHTML = `
        <gsk-chip-set>
          <span class="select__more-chips-ellipsis">...</span>
          ${chipsHTML}
        </gsk-chip-set>
        <span class="select__more-chips"></span>
      `;

      setTimeout(() => emit(this, 'chipschange', {}, false));
    } else {
      super._setTextContent(value);
    }

    if (this._placeholderEl) {
      this._placeholderEl.classList.toggle('mdc-select__placeholder--hidden', Boolean(value));
      this._selectedTextInner.classList.toggle(
        'mdc-select__selected-text-inner--filled',
        Boolean(value),
      );
    }
  }

  protected _onBlur(evt: any): void {
    this.dirty = true;
    super._onBlur(evt);
  }

  /**
   * Update layout adding styles when chips space needed will be longer than input
   */
  public chipsLayout() {
    const chips = [...this._selectedTextInner.querySelectorAll('gsk-chip')] as HTMLElement[];

    if (chips.length === 0) return;

    const moreChips = this._selectedTextInner.querySelector('.select__more-chips') as HTMLElement;
    const remainingChips = chips.filter(item => item.offsetTop > 8);
    moreChips.classList.toggle('select__more-chips--active', remainingChips.length > 0);
    moreChips.textContent = `+${remainingChips.length}`;

    const moreChipsEllipsis = this._selectedTextInner.querySelector(
      '.select__more-chips-ellipsis',
    ) as HTMLElement;
    const ellipsisOrder = chips.length - remainingChips.length;
    moreChipsEllipsis.style.order = ellipsisOrder.toString();
    moreChipsEllipsis.classList.toggle(
      'select__more-chips-ellipsis--active',
      remainingChips.length > 0,
    );
  }

  protected _enhancedSelectSetup() {
    super._enhancedSelectSetup();

    if (this.selectAllEl) {
      this._hasSelectAll = true;
      this._selectAllSetup();
      this._updateSelectAll();
    }

    if (this.filterEl) {
      this._hasFilter = true;
      this._filterSetup();
    }
  }

  protected _selectAllSetup() {
    const divider = document.createElement('gsk-list-divider');
    const menuEl = this.menuEl!;

    divider.classList.add('select__divider');

    menuEl.prepend(divider);
    menuEl.prepend(this.selectAllEl);

    const selectAllEl = menuEl.querySelector('.select__select-all') as GSKListItem;
    const checkboxEl = selectAllEl.querySelector('gsk-checkbox') as GSKCheckbox;
    const clearAllButton = selectAllEl.querySelector('gsk-button') as HTMLElement;

    let buttonFocused = false;

    selectAllEl.addEventListener('click', e => {
      e.preventDefault();
      e.stopImmediatePropagation();
      setTimeout(() => this._onSelectAll(!checkboxEl.checked || checkboxEl.indeterminate));
    });

    selectAllEl.addEventListener('keydown', e => {
      const isEnter = e.key === 'Enter' || e.keyCode === 13;
      const isSpace = e.key === 'Space' || e.keyCode === 32;
      const isTab = e.key === 'Tab' || e.keyCode === 9;

      if (isEnter || isSpace) {
        e.preventDefault();
        e.stopImmediatePropagation();

        this._onSelectAll(!checkboxEl.checked);
      }

      if (isTab && clearAllButton.style.display !== 'none' && !buttonFocused) {
        e.preventDefault();
        e.stopImmediatePropagation();

        clearAllButton.focus();
      }
    });

    selectAllEl.style.padding = '2px';

    clearAllButton.addEventListener('click', e => {
      e.preventDefault();
      e.stopImmediatePropagation();

      this._onSelectAll(false);
    });

    clearAllButton.addEventListener('focus', () => {
      buttonFocused = true;
    });
    clearAllButton.addEventListener('blur', () => {
      buttonFocused = false;
    });
  }

  /**
   * Handle menu closed event
   */
  protected _onMenuClosed(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { activeElement } = (this as any).getRootNode();

    // _isMenuOpen is used to track the state of the menu opening or closing since the menu.open function
    // will return false if the menu is still closing and this method listens to the closed event which
    // occurs after the menu is already closed.
    this._isMenuOpen = false;
    this._selectedText!.removeAttribute('aria-expanded');

    if (activeElement !== this) {
      this.mdcFoundation.handleBlur();
    }

    this.reportValidity();
  }

  /**
   * TODO(luissardon): Remove deprecated
   */
  protected async _setValue(value: string | string[]): Promise<void> {
    const dirty = JSON.stringify(this._previousValue) !== JSON.stringify(value);
    super._setValue(value);
    if (dirty) {
      this.dirty = true;
      this.reportValidity();
    }
  }

  protected _filterSetup(): void {
    const divider = document.createElement('gsk-list-divider');
    const menuSurfaceEl = this.menuEl!.shadowRoot!.querySelector(
      '.mdc-menu-surface',
    ) as HTMLElement;

    divider.classList.add('select__divider');

    menuSurfaceEl.prepend(divider);
    menuSurfaceEl.prepend(this.filterEl);

    const filterEl = menuSurfaceEl.querySelector('.select__filter') as GSKTextField;
    const dividerEl = menuSurfaceEl.querySelector('.select__divider') as GSKTextField;

    // Prevents menu from closing when focus change
    filterEl.addEventListener('click', e => {
      e.preventDefault();
      e.stopImmediatePropagation();
    });

    filterEl.addEventListener('input', () => {
      this._filterBy(filterEl.value);
    });

    filterEl.style.position = 'absolute';
    dividerEl.style.position = 'absolute';
    dividerEl.style.width = '100%';

    this.menuEl!.addEventListener('MDCMenuSurface:opened', () => {
      const filterHeight = filterEl.clientHeight;

      if (menuSurfaceEl.style.bottom) {
        filterEl.style.bottom = '0';
        filterEl.style.top = '';

        dividerEl.style.bottom = `${filterHeight}px`;
        dividerEl.style.top = '';

        menuSurfaceEl.style.paddingBottom = `${filterHeight + 1}px`;
        menuSurfaceEl.style.paddingTop = '';
      } else {
        filterEl.style.bottom = '';
        filterEl.style.top = '0';

        dividerEl.style.bottom = '';
        dividerEl.style.top = `${filterHeight}px`;

        menuSurfaceEl.style.paddingBottom = '';
        menuSurfaceEl.style.paddingTop = `${filterHeight + 1}px`;
      }

      filterEl.focus();
    });

    this.menuEl!.addEventListener('MDCMenuSurface:closed', () => {
      filterEl.valid = true;
      filterEl.value = '';
      this._filterBy('');
    });
  }

  protected _filterBy(value: string): void {
    const normalizedValue = normalizeSync(value.toLowerCase().trim());
    const normalizeSelectAllText = normalizeSync(this.selectalllabel.toLowerCase().trim());
    const dividerEl = this.menuEl!.querySelector('.select__divider') as GSKListDivider;

    const listItems2Hide = this.listItems.filter(item => {
      const normalizedText = normalizeSync(item.textContent!.toLowerCase().trim());
      item.style.display = '';

      return !normalizedText.includes(normalizedValue) && normalizedText !== normalizeSelectAllText;
    });

    dividerEl.style.display = '';
    if (this.listEl) {
      this.listEl.style.padding = '';
    }

    if (normalizedValue !== '') {
      listItems2Hide.forEach(item => {
        item.style.display = 'none';
      });

      if (listItems2Hide.length === this.listItems.length) {
        dividerEl.style.display = 'none';
        if (this.listEl) {
          this.listEl.style.padding = '0';
        }
      }
    }
  }

  protected _handleResizeObserver(): void {
    this.chipsLayout();
  }

  /**
   * (Override) Handle menu change event
   */
  protected async _onMenuChange(e: CustomEvent): Promise<void> {
    super._onMenuChange(e);

    await this.updateComplete;

    if (this.multiple) {
      this._updateSelectAll();
    }
  }

  protected _onSelectAll(checked: boolean): void {
    this.selectedIndex = checked ? this.listItems.map((_, i) => i) : [];
  }

  protected _updateSelectAll(): void {
    if (!this._hasSelectAll) return;

    const selectedIndex = this.selectedIndex as number[];
    const allSelectedCount = this.listItems.length;
    const matchAll = selectedIndex.length === allSelectedCount;
    const matchNone = this.selectedIndex === -1;
    const selectAllEl = this.menuEl!.querySelector('.select__select-all') as GSKListItem;
    const checkboxEl = selectAllEl.querySelector('gsk-checkbox') as GSKCheckbox;

    if (matchAll) {
      checkboxEl.indeterminate = false;
      checkboxEl.checked = true;
    } else if (!matchNone) {
      checkboxEl.indeterminate = true;
      checkboxEl.checked = true;
    } else {
      checkboxEl.indeterminate = false;
      checkboxEl.checked = false;
    }

    if (!matchNone) {
      this._showClearAll(true);
    } else {
      this._showClearAll(false);
    }
  }

  protected _showClearAll(show: boolean): void {
    const selectAllEl = this.menuEl!.querySelector('.select__select-all') as GSKListItem;

    if (selectAllEl) {
      const clearAllButon = selectAllEl.querySelector('gsk-button') as HTMLElement;
      clearAllButon.style.display = show ? '' : 'none';
    }
  }

  /**
   * Updates inputs names
   */
  protected _updateName(): void {
    if (this.name !== undefined && this.multiple) {
      this.inputs.forEach(input => {
        input.name = this.name;
      });
    }
  }
}

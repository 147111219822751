import { LitElement, classMap, property, query, TemplateResult, html, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import { observer } from '@gsk-tech/gsk-base/observer';
import {
  ConstraintValidationModel,
  createValidityObj,
  CustomValidityState,
  ValidityTransform,
} from '@gsk-tech/gsk-base/constraint-validation';
import { cssClasses } from './constants';
import { gskStyle } from './gsk-radio-group-css';
import { RadioBase } from './gsk-radio-base';

export class RadioGroupBase extends LitElement implements ConstraintValidationModel {
  public static get styles() {
    return unsafeCSS(gskStyle);
  }

  @query('slot')
  protected slotEl!: HTMLSlotElement;

  @query('.radio-group')
  protected mdcRoot!: HTMLElement;

  @query('.radio-group-helper-text')
  protected helperTextEl!: HTMLElement;

  /**
   * Optional. Default value is false. Use this property in order to display the radio buttons in the same line
   */
  @property({ type: Boolean })
  public inline = false;

  /**
   * Optional. Setter/getter for the radio group's label
   */
  @property({ type: String })
  public label = '';

  @property({ type: String, reflect: true })
  @observer(function(this: RadioGroupBase) {
    this._updateName();
  })
  public name?: string;

  @property({ type: Boolean, reflect: true })
  @observer(function(this: RadioGroupBase) {
    this._updateDisabled();
  })
  public disabled?: boolean;

  // TODO: Comments are missing here
  @property({ type: Boolean, reflect: true })
  @observer(function(this: RadioGroupBase) {
    this._updateRequired();
  })
  public required?: boolean;

  /**
   * Optional. This text appears below of the radio-group when it gets focused
   */
  @property({ type: String })
  public helperText = '';

  /**
   * Optional. Default value is false. Use this property to display always the helper text
   */
  @property({ type: Boolean })
  public persistentHelperText = false;

  /**
   * Optional. This property is use to display a message when radio-group is not valid
   */
  @property({ type: String })
  public validationMessage = '';

  /**
   * Optional. Default is false. If set, reportValidity method will be called on firstUpdated.
   */
  @property({ type: Boolean })
  public validateOnInitialRender = false;

  @property({ type: Boolean })
  public dirty = false;

  public get isUiValid(): boolean {
    if (this.willValidate) {
      if (this.valid !== undefined) {
        return this.valid;
      }

      if (this.dirty || this.validateOnInitialRender) {
        return this.validity.valid;
      }
    }
    return true;
  }

  /**
   * Optional. Use it to force the validity state of the component,
   * even if the `required` prop is true.
   */
  @property({ type: Boolean })
  public valid?: boolean = undefined;

  protected get inputs(): RadioBase[] {
    return [...this.querySelectorAll('gsk-radio')];
  }

  public get value(): string {
    const selected = this.inputs.filter(input => Boolean(input.value)).find(input => input.checked);
    return selected ? selected.value : '';
  }

  public set value(value: string) {
    this.inputs
      .filter(input => Boolean(input.value))
      .forEach(input => {
        input.checked = !!value && value === input.value;
      });
    // _checkValidity is called because the validity needs to be updated from programmatic change to the value
    // but we don't want to emit an invalid event
    this._checkValidity();
    this.requestUpdate();
  }

  protected _validity: ValidityState = createValidityObj();

  public validityTransform: ValidityTransform | null = null;

  public get validity(): Readonly<ValidityState> {
    if (!this.willValidate) {
      return createValidityObj();
    }
    this._checkValidity();
    return this._validity;
  }

  protected async firstUpdated(_changedProperties: Map<PropertyKey, unknown>): Promise<void> {
    await super.firstUpdated(_changedProperties);
    // wait for the children to render
    await this.updateComplete;
    if (this.validateOnInitialRender) {
      this.reportValidity();
    } else {
      this._checkValidity();
    }
  }

  /**
   * Used to render the lit-html TemplateResult for the helper-text
   */
  protected _renderHelperText() {
    const isValidationMessage = !this.isUiValid;
    const classes = {
      'radio-group-helper-text': true,
      [cssClasses.PERSISTENT]: this.persistentHelperText,
      [cssClasses.VALIDATION_MSG]: isValidationMessage,
    };
    const message = isValidationMessage ? this.validationMessage || 'invalid' : this.helperText;

    return this.helperText || isValidationMessage
      ? html`
          <p class="${classMap(classes)}">${message}</p>
        `
      : null;
  }

  protected _renderLabel() {
    return html`
      <label class="radio-group__label"
        >${this.label}${this.required
          ? html`
              <span>*</span>
            `
          : ''}</label
      >
    `;
  }

  protected render(): TemplateResult {
    const classes = {
      'radio-group': true,
      [cssClasses.INLINE]: this.inline,
      [cssClasses.INVALID]: !this.isUiValid,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.label ? this._renderLabel() : ''}
        <div class="radio-group__content">
          <slot
            @slotchange="${this._handleSlotChange}"
            @change="${this._handleInteraction}"
            @blur="${this._handleInteraction}"
          ></slot>
        </div>
        ${this._renderHelperText()}
      </div>
    `;
  }

  /**
   * Handles interaction events
   */
  protected _handleInteraction(evt): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    this.dirty = true;
    this.reportValidity();
    emit(this, evt.type, { value: this.value });
  }

  /**
   * Handles slot change event
   */
  protected _handleSlotChange(): void {
    this._updateName();
  }

  /**
   * Updates inputs disabled states
   */
  protected _updateDisabled() {
    if (this.disabled !== undefined) {
      [...this.children].forEach(item => {
        if (this.disabled) {
          item.setAttribute('disabled', '');
        } else {
          item.removeAttribute('disabled');
        }
      });
    }
  }

  /**
   * Updates inputs disabled states
   */
  protected _updateRequired() {
    if (this.required !== undefined) {
      this.inputs.forEach(item => {
        if (this.required) {
          item.setAttribute('required', '');
        } else {
          item.removeAttribute('required');
        }
      });
    }
  }

  /**
   * Updates inputs names
   */
  protected _updateName(): void {
    const { name } = this;
    if (name !== undefined) {
      this.inputs.forEach(input => {
        input.name = name;
      });
    }
  }

  public focus(): void {
    if (this.inputs.length > 0) {
      this.inputs[0].focus();
    }
  }

  // VALIDATION
  public get willValidate(): boolean {
    return this.inputs.some(box => box.willValidate);
  }

  public setCustomValidity(message: string): void {
    if (this.willValidate) {
      this.validationMessage = message;
      const isError = message !== '';
      this._validity = createValidityObj({
        customError: isError,
      });
      this.dirty = true;
      this.requestUpdate();
    }
  }

  public checkValidity(): boolean {
    const isValid = this.validity.valid;

    if (!isValid) {
      const invalidEvent = new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  protected _checkValidity(): boolean {
    const { value } = this;
    const isCustomError = this._validity.customError;
    const nativeValidity: CustomValidityState = createValidityObj({
      customError: isCustomError,
      valid: !isCustomError,
    });
    let valid = true;
    if (this.required) {
      if (!value) {
        nativeValidity.valueMissing = true;
        valid = false;
      }
    }
    nativeValidity.valid = nativeValidity.valid && valid;

    let validity = nativeValidity;
    if (this.validityTransform) {
      const transformedValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...transformedValidity };
    }

    this._validity = validity;
    return this._validity.valid;
  }

  public reportValidity(): boolean {
    const isValid = this.checkValidity();
    this.dirty = true;
    this.requestUpdate();
    return isValid;
  }
}

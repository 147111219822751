// @ts-ignore
import { SwitchBase as MWCSwitch } from './mwc-switch-base';
import { gskStyle as mwcStyle } from './mwc-switch-css';
import { property, PropertyDeclarations, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { gskStyle } from './gsk-switch-css';

export class SwitchBase extends MWCSwitch {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Optional. Setter/getter for the Switch's name
   */
  @property({ type: String })
  public name = '';

  /**
   * Optional. Default value is false. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = false

  /**
   * Invoked when the element is first updated. Implement to perform one time work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   */
  public firstUpdated(): void {
    super.firstUpdated();

    this.formElement.addEventListener('change', () => {
      this._handleFormElementChange();
    });
  }

  protected _handleFormElementChange(): void {
    const { formElement: target, checked } = this;

    const detail = {
      target,
      checked,
    };

    const event = new CustomEvent('change', { detail });
    this.dispatchEvent(event);
  }

  /**
   * Gets a PropertyDeclarations object
   */
  public static get properties(): PropertyDeclarations {
    return {
      disabled: { type: Boolean },
      change: { type: Function },
    };
  }

  /**
   * An instance method use to set the initial values for gsk-switch
   */
  public constructor() {
    super();
    this.disabled = false;
  }

  /**
   * This method is invoked whenever the gsk-switch is updated
   *
   * @param changedProperties Map of changed properties with old values
   */
  public updated(changedProperties): void {
    if (changedProperties.has('checked')) {
      this.mdcRoot.classList.toggle('mdc-switch--checked', this.checked);
    }

    if (changedProperties.has('disabled')) {
      this.mdcRoot.classList.toggle('mdc-switch--disabled', this.disabled);
    }

    if (changedProperties.has('primary')) {
      this.mdcRoot.classList.toggle('mdc-switch--primary', this.primary);
    }
  }
}

import { unsafeCSS } from 'lit';
import { RippleBase as MWCRipple } from './mwc-ripple-base';
import { gskStyle as mwcStyle } from './mwc-ripple-css';
import { gskStyle } from './gsk-ripple-css';

export class RippleBase extends MWCRipple {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }
}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  FormElement,
  property,
  observer,
  query,
  HTMLElementWithRipple,
  addHasRemoveClass,
  html
} from '@gsk-tech/gsk-base/form-element';
import MDCSwitchFoundation from '@material/switch/foundation';
import { MDCSwitchAdapter } from '@material/switch/adapter';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import { TemplateResult } from '@gsk-tech/gsk-base/base-element';

export class SwitchBase extends FormElement {

  @query('.mdc-switch')
  protected mdcRoot!: HTMLElement;

  @query('input')
  protected formElement!: HTMLInputElement;

  @query('.mdc-switch__thumb-underlay')
  protected rippleNode!: HTMLElementWithRipple;

  /**
   * Optional. Default value is false. Setter/getter for the switch's checked state
   */
  @property({ type: Boolean })
  @observer(function (this: SwitchBase, value: boolean) {
    this.mdcFoundation.setChecked(value);
  })
  checked = false;

  /**
   * Optional. Setter/getter for the switch's name
   */
  @property({ type: String })
  name = ''

  /**
   * Optional. Setter/getter for the switch's value
   */
  @property({ type: String })
  value = ''

  /**
   * Optional. Setter/getter for the switch's label
   */
  @property({ type: String })
  label = ''

  /**
   * Optional. Setter/getter for the switch's label
   */
   @property({ type: Boolean })
   defaultSwitchType = false;

  /**
   * Optional. Default value is false. Setter/getter for the switch's disabled state
   */
  @property({ type: Boolean })
  @observer(function (this: SwitchBase, value: boolean) {
    this.mdcFoundation.setDisabled(value);
  })
  disabled = false;

  protected mdcFoundation!: MDCSwitchFoundation;

  /**
   * Handles the change event for the switch
   */
  protected _changeHandler(e: Event) {
    this.mdcFoundation.handleChange(e);
    // catch "click" event and sync properties
    this.checked = this.formElement.checked;
  };

  protected readonly mdcFoundationClass = MDCSwitchFoundation;

  /**
   * Create the adapter for the `mdcFoundation`.
   * Override and return an object with the Adapter's functions implemented
   */
  protected createAdapter(): MDCSwitchAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      setNativeControlChecked: (checked: boolean) => {
        this.formElement.checked = checked;
      },
      setNativeControlDisabled: (disabled: boolean) => {
        this.formElement.disabled = disabled;
      }
    }
  }

  /**
   * Ripple getter for Ripple integration
   */
  get ripple() {
    return this.rippleNode.ripple;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {

    return html`
      <div class="mdc-switch ${this.defaultSwitchType? 'basic': 'new'}">
        <div class="mdc-switch__track"></div>
        <div class="mdc-switch__thumb-underlay" .ripple="${ripple({ interactionNode: this })}">
          <div class="mdc-switch__thumb">
            <input
              type="checkbox"
              id="basic-switch"
              class="mdc-switch__native-control"
              aria-label="${this.label}"
              role="switch"
              aria-checked="${this.checked}"
              .checked="${this.checked}"
              .value="${this.value}" @change="${this._changeHandler}">
          </div>
        </div>
      </div>
      <slot></slot>`;
  }
}

// @ts-ignore
import { TooltipBase as MWCTooltip } from './mwc-tooltip-base';
import { gskStyle as mwcStyle } from './mwc-tooltip-css';
import { property, unsafeCSS, classMap, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { styleMap } from 'lit/directives/style-map.js';
import { gskStyle } from './gsk-tootip-css';

export class TooltipBase extends MWCTooltip {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Optional. Default value is 250. This property value adds an additional delay on the hover of the tooltip once triggered.
   */
  @property({ type: Number })
  public showDelay = 250;

  /**
   * Optional. Default value is false. Use to sets the inverted color to be used on dark backgrounds
   */
  @property({ type: Boolean })
  public inverted = false;

  /**
   * Optional. Default value is 4. This property is used to sets the the distance between the tooltip and the element which is bound to
   */
  @property({ type: Number })
  public offset = 4;

  /**
   * Optional. Default value is 13. Used this property to sets the margin space for the tooltip according to 'placement' choose
   */
  @property({ type: Number })
  public gap = 13;

  @property({ type: Boolean })
  public truncate = false;

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const { direction, offset } = this._getTransformOffset();

    const styles = {
      [direction]: offset,
    };

    const classes = {
      'mdc-tooltip': true,
      'mdc-tooltip--inverted': this.inverted,
      truncate: this.truncate,
    };

    return html`
      <div class="${classMap(classes)}" tabindex="-1" style="${styleMap(styles)}">
        <div class="text">${this.text}</div>
        <span class="mdc-tooltip__caret mdc-tooltip__caret--${this.placement}"></span>
      </div>
    `;
  }
}

// @ts-ignore
import { TabBarBase as MWCTabBar } from './mwc-tab-bar-base';
import { TabBase as MWCTab } from './mwc-tab-base';
import { TabIndicatorBase as MWCTabIndicator } from './mwc-tab-indicator-base';
import { TabScrollerBase as MWCTabScroller } from './mwc-tab-scroller-base';
import { property, query, unsafeCSS, classMap, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
// @ts-ignore
import MDCTabBarFoundation from '@material/tab-bar/foundation';
import { MDCTabInteractionEvent } from '@material/tab/types';
// @ts-ignore
import { observer } from '@gsk-tech/gsk-base/base-element';

import { gskStyle as mwcTabStyle } from './mwc-tab-css';
import { gskStyle as mwcTabBarStyle } from './mwc-tab-bar-css';
import { gskStyle as mwcTabIndicatorStyle } from './mwc-tab-indicator-css';
import { gskStyle as mwcTabScrollerStyle } from './mwc-tab-scroller-css';

import { gskStyle as gskTabStyle } from './gsk-tab-css';
import { gskStyle as gskTabBarStyle } from './gsk-tab-bar-css';
import { gskStyle as gskTabIndicatorStyle } from './gsk-tab-bar-indicator-css';

export class TabBase extends MWCTab {
  public static get styles() {
    return unsafeCSS(mwcTabStyle.cssText + gskTabStyle.cssText);
  }

  @query('gsk-tab-indicator')
  // @ts-ignore
  protected tabIndicator!: TabIndicatorBase;

  /**
   * Optional. Default value is false. Removes the ripple effect on the tab
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value to false. Removes ability to interacted with and have no visual interaction effect
   */
  @property({ type: Boolean })
  public disabled?: boolean;

  /**
   * Invoked whenever the element is updated. Implement to perform post-updating tasks via DOM APIs, for example, focusing an element.
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   *
   * @param _changedProperties Map of changed properties with old values
   */
  protected updated(changedProperties): void {
    super.updated(changedProperties);
    /* istanbul ignore else */ 
    if (changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('mdc-tab--no-ripple', this.noripple);
    }
    /* istanbul ignore else */ 
    if (changedProperties.has('icon')) {
      const hasIcon = this.icon && this.icon.trim().length > 0;
      const hasLabel = this.label && this.label.trim().length > 0;
      this.mdcRoot.classList.toggle('mdc-tab--icon-only', Boolean(hasIcon && !hasLabel));
    }
  }

  /**
   * Create the adapter for the `mdcFoundation`.
   * Override and return an object with the Adapter's functions implemented
   */
  public createAdapter(): any {
    return {
      ...super.createAdapter(),
      activateIndicator: (previousIndicatorClientRect: ClientRect) => {
        this.tabIndicator.activate(previousIndicatorClientRect);
      },
      deactivateIndicator: () => this.tabIndicator.deactivate(),
    };
  }

  protected _renderTabIndicator(isTabDisabled: boolean) {
    return html`
      <gsk-tab-indicator
        .icon="${this.indicatorIcon}"
        .fade="${this.isFadingIndicator}"
        ?disabled=${isTabDisabled}>
      </gsk-tab-indicator>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    const isTabDisabled = Boolean(this.disabled);

    const classes = {
      'mdc-tab': true,
      'mdc-tab--min-width': this.minWidth,
      'mdc-tab--stacked': this.stacked
    };

    return html`
      <button ?disabled="${isTabDisabled}" @click="${this._handleClick}" class="${classMap(classes)}" role="tab" aria-selected="false" tabindex="-1">
        
          <span class="mdc-tab__content">
          <slot></slot>
          ${this.icon ? html`<span class="mdc-tab__icon material-icons">${this.icon}</span>` : ''}
          ${this.label ? html`<span class="mdc-tab__text-label">${this.label}</span>` : ''}
          ${this.isMinWidthIndicator ? this._renderTabIndicator(isTabDisabled) : ''}
          <span class="mdc-tab__ripple" .ripple="${ripple({ interactionNode: this, unbounded: false })}"></span>
          </span>
          ${this.isMinWidthIndicator ? '' : this._renderTabIndicator(isTabDisabled)}
      </button>`;
  }
}

export class TabScrollerBase extends MWCTabScroller {
  public static get styles(): any {
    return mwcTabScrollerStyle;
  }
}

export class TabBarBase extends MWCTabBar {
  public static get styles() {
    return unsafeCSS(mwcTabBarStyle.cssText + gskTabBarStyle.cssText);
  }


  @query('gsk-tab-scroller')
  protected scrollerElement!: TabScrollerBase;

  // @ts-ignore
  protected _previousGskActiveIndex = -1;

  /**
   * Optional. Default value is false. Removes the ripple effect on the tab-bar
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value to false. Removes ability to interacted with and have no visual interaction effect
   */
  @property({ type: Boolean })
  public disabled?: boolean;

  /**
   * Optional. Default value is 0. Sets the active tab on initial render.
   * Enter the index number of the tab you want activated.
   * Index starts at 0.
   */
  @observer(async function(this: TabBarBase, value: number) {
    await this.updateComplete;
    // only provoke the foundation if we are out of sync with it, i.e.
    // ignore an foundation generated set.
    /* istanbul ignore else */ 
    if (value !== this._previousGskActiveIndex) {
      this.mdcFoundation.activateTab(value);
    }
  })
  @property({ type: Number })
  public activeIndex = 0;

  /**
   * Define a new Set instance of forwarded properties
   */
  public forwardedProps = new Set(['noripple', 'disabled']);

  /**
   * Tab's children elements
   */
  public tabs = [...this.children];

  /**
   * An instance method use to set the initial values for gsk-tab-bar
   */
  public constructor() {
    super();
    this.noripple = false;
  }

  /**
   * This method updated each prop and value from the parent to children elements
   */
  public updateChildren(prop, value): void {
    this.tabs.forEach(tab => {
      tab[prop] = value;
    });
  }

  protected updated(changedProperties): void {
    super.updated(changedProperties);
    // @ts-ignore Ignoring unused but necessary prop
    changedProperties.forEach((oldValue, propName) => {
      if (this.forwardedProps.has(propName)) {
        this.updateChildren(propName, this[propName]);
      }
    });
  }

  protected _handleGskTabInteraction(e: MDCTabInteractionEvent): void {
    this.mdcFoundation.handleTabInteraction(e);
  }

  protected _handleGskKeydown(e: KeyboardEvent): void {
    this.mdcFoundation.handleKeyDown(e);
  }

  protected _getGskTabs(): TabBase[] {
    return this.tabsSlot
      .assignedNodes({ flatten: true })
      .filter((e: Node) => e instanceof TabBase) as TabBase[];
  }

  protected _getGskTab(index): TabBase {
    return this._getGskTabs()[index];
  }

  /**
   * Create the adapter for the `mdcFoundation`.
   *
   * Override and return an object with the Adapter's functions implemented
   */
  public createAdapter(): any {
    return {
      ...super.createAdapter(),
      activateTabAtIndex: (index: number, clientRect: ClientRect) => {
        const tab = this._getGskTab(index);
        /* istanbul ignore else */ 
        if (tab !== undefined) {
          tab.activate(clientRect);
        }
        this._previousGskActiveIndex = index;
      },
      deactivateTabAtIndex: (index: number) => {
        const tab = this._getGskTab(index);
        if (tab !== undefined) {
          tab.deactivate();
        }
      },
      getPreviousActiveTabIndex: () => {
        return this._previousGskActiveIndex;
      },
      notifyTabActivated: (index: number) => {
        // Synchronize the tabs `activeIndex` to the foundation.
        // This is needed when a tab is changed via a click, for example.
        this.activeIndex = index;
        this.dispatchEvent(
          new CustomEvent(MDCTabBarFoundation.strings.TAB_ACTIVATED_EVENT, {
            detail: { index },
            bubbles: true,
            cancelable: true,
          }),
        );
      },
      setActiveTab: (index: number) => this.mdcFoundation.activateTab(index),
    };
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    const classes = {
      'mdc-tab-bar': true,
      'mdc-tab-bar--disabled': Boolean(this.disabled),
    };

    return html`
      <div
        class="${classMap(classes)}"
        role="tablist"
        @interacted="${this._handleGskTabInteraction}"
        @keydown="${this._handleGskKeydown}"
      >
        <gsk-tab-scroller><slot></slot></gsk-tab-scroller>
      </div>
    `;
  }
}
export class TabIndicatorBase extends MWCTabIndicator {
  public static get styles() {
    return unsafeCSS(mwcTabIndicatorStyle.cssText + gskTabIndicatorStyle.cssText);
  }

  /**
   * Optional. Default value to false. Removes ability to interacted with and have no visual interaction effect
   */
  @property({ type: Boolean })
  disabled = false;


  /**
   * Invoked whenever the element is updated. Implement to perform post-updating tasks via DOM APIs, for example, focusing an element.
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   *
   * @param _changedProperties Map of changed properties with old values
   */
  public updated(changedProperties): void {
    super.updated(changedProperties);
    /* istanbul ignore else */ 
    if (changedProperties.has('disabled')) {
      this.mdcRoot.classList.toggle('mdc-tab-indicator--disabled', this.disabled);
    }
  }
}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { SliderBase } from './gsk-slider-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-slider': Slider;
  }
}

@customElementDefinition('gsk-slider')
export class Slider extends SliderBase {}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  LitElement,
  query,
  classMap,
  property,
  emit,
  findAssignedElement,
  observer,
  html,
  TemplateResult
} from '@gsk-tech/gsk-base/base-element';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';

interface List extends HTMLElement {
  content: Boolean;
  offsetContent: Boolean;
  listElements: ListItemBase[];
}

/* istanbul ignore next */
export class ListItemBase extends LitElement {

  @query('.mdc-list-item')
  protected mdcRoot!: HTMLElement;

  @query('slot[name="graphic"]')
  protected graphicSlot!: HTMLSlotElement;

  @query('slot[name="meta"]')
  protected metaSlot!: HTMLSlotElement;

  @query('slot[name="content"]')
  protected contentSlot!: HTMLSlotElement;

  @query('.mdc-list-item-wrapper')
  protected listItemWrapper!: HTMLElement;

  @query('.mdc-list-item-content')
  protected listItemContent!: HTMLElement;

  @query('.mdc-list-item-content__inner')
  protected listItemContentInner!: HTMLElement;

  /**
   * Optional. Default value is 'single-line'. Use this property to set the maximum lines to be displayed in the list-item
   */
  @property({ type: String, reflect: true })
  public type = 'single-line';

  /**
   * Optional. The default value is set to false. Setting this property to true block any interaction with the list-item
   */
  @property({ type: Boolean })
  public nonInteractive = false;

  /**
   * Optional. The default value is set to false. This property allow us to select a list item in the list
   */
  @property({ type: Boolean })
  public selected = false;

  /**
   * Optional. The default value is set to false. This property helps us to highlight a list item despite you can select another one
   */
  @property({ type: Boolean })
  public activated = false;

  /**
   * Optional. Default value is false. Adds a left space to the content of the list-item
   */
  @property({ type: Boolean })
  public offsetContent = false;

  /**
   * Optional. Sets/gets the value of the list-item
   */
  @property({ type: String })
  public value = '';

  /**
   * Optional. Default value is set to false. Use this property to whether or not the list-item should be disabled.
   */
  @property({ type: Boolean, reflect: true })
  @observer(function(this: ListItemBase, value: boolean) {
    if (this.expandable) {
      if (value) {
        if (this.expanded) {
          this.collapse();
        }

        this._removeExpandableListeners();
      } else {
        this._addExpandableListeners();
      }
    }
  })
  public disabled?: boolean;

  /**
   * Optional. Default value is false. This property helps to set up the avatar style for the list-item component
   */
  @property({ type: Boolean })
  public avatarList = false;

  /**
   * Optional. Default value is false. This property applies an expandable style to a list-item and allows us to display/hide its sub items when clicking on it.
   * Child items inside the 'slot="content"' and the child list-items will be hidden when unexpanded
   */
  @property({ type: Boolean, reflect: true })
  public expandable = false;

  /**
   * Optional. Default value is false. Use this property to display all the list-items expanded
   */
  @property({ type: Boolean })
  @observer(function(this: ListItemBase, value: boolean) {
    this.expanded = value;
    this.collapsed = !value;
  })
  public startExpanded = false;

  protected get graphicElement(): HTMLElement {
    return findAssignedElement(this.graphicSlot, '*') as HTMLElement;
  }

  protected get metaElement(): HTMLElement {
    return findAssignedElement(this.metaSlot, '*') as HTMLElement;
  }

  protected get contentElement(): List {
    return findAssignedElement(this.contentSlot, '*') as List;
  }

  protected set expanded(value: boolean) {
    this._expanded = value;

    if (value) {
      this.setAttribute('expanded', 'true');
    } else {
      this.setAttribute('expanded', 'false');
    }
  }

  protected get expanded(): boolean {
    return this._expanded;
  }

  protected set expanding(value: boolean) {
    this._expanding = value;

    if (value) {
      this.setAttribute('expanding', 'true');
    } else {
      this.setAttribute('expanding', 'false');
    }
  }

  protected get expanding(): boolean {
    return this._expanding;
  }

  protected set collapsed(value: boolean) {
    this._collapsed = value;

    if (value) {
      this.setAttribute('collapsed', 'true');
    } else {
      this.setAttribute('collapsed', 'false');
    }
  }

  protected get collapsed(): boolean {
    return this._collapsed;
  }

  protected set collapsing(value: boolean) {
    this._collapsing = value;

    if (value) {
      this.setAttribute('collapsing', 'true');
    } else {
      this.setAttribute('collapsing', 'false');
    }
  }

  protected get collapsing(): boolean {
    return this._collapsing;
  }

  protected _expanded!: boolean;

  protected _expanding!: boolean;

  protected _collapsing!: boolean;

  protected _collapsed = !this._expanded;

  protected _expandable = false;

  protected _withGraphic = false;

  protected _withMeta = false;

  protected _handleFocus = this._onFocus.bind(this);

  protected _handleBlur = this._onBlur.bind(this);

  protected _handleClick = this._onClick.bind(this);

  protected _handleKeyDown = this._onKeyDown.bind(this);

  protected _handleExpandTransitionEnd = this._onExpandTransitionEnd.bind(this);

  protected _handleCollapseTransitionEnd = this._onCollapseTransitionEnd.bind(this);

  protected _renderArrowIcon(): TemplateResult {
    return html`
      <gsk-mwc-icon>keyboard_arrow_down</gsk-mwc-icon>
    `;
  }

  protected _renderExpandableListItem() {
    const classes = {
      'mdc-list-item-wrapper': true,
      'mdc-list-item-wrapper--collapsed': this.collapsed,
      'mdc-list-item-wrapper--expanded': this.expanded,
      'mdc-list-item-wrapper--collapsing': this.collapsing,
      'mdc-list-item-wrapper--expanding': this.expanding,
    }

    return html`
      <div class="${classMap(classes)}">
        ${this._renderListItem()}

        <div class="mdc-list-item-content">
          <div class="mdc-list-item-content__inner">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    `;
  }

  protected _renderListItem() {
    const classes = {
      'mdc-list-item': true,
      'mdc-list-item--two-line': this.type === 'two-line',
      'mdc-list-item--three-line': this.type === 'three-line',
      'mdc-list-item--non-interactive': this.nonInteractive && !this.expandable,
      'mdc-list-item--selected': this.selected,
      'mdc-list-item--activated': this.activated,
      'mdc-list-item--disabled': Boolean(this.disabled),
      'mdc-list-item--avatar-list': this.avatarList,
      'mdc-list-item--with-graphic': this._withGraphic,
      'mdc-list-item--with-meta': this._withMeta,
      'mdc-list-item--offset-content': this.offsetContent
    }

    return html`
      <div class="${classMap(classes)}" .ripple="${ripple({ unbounded: false })}">
        <div class="mdc-list-item__graphic">
          <slot name="graphic"></slot>
        </div>
        <div class="mdc-list-item__text">
          <slot></slot>
          <div class="mdc-list-item__primary-text">
            <slot name="primary"></slot>
          </div>
          <div class="mdc-list-item__secondary-text">
            <slot name="secondary"></slot>
          </div>
        </div>
        <div class="mdc-list-item__meta">
          <slot name="meta"></slot>
          ${this.expandable ? this._renderArrowIcon() : ''}
        </div>
      </div>
    `;
  }

  protected render(): TemplateResult {
    return this.expandable
      ? this._renderExpandableListItem()
      : this._renderListItem();
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  public firstUpdated() {
    this.addEventListener('focus', this._handleFocus);
    this.addEventListener('blur', this._handleBlur);

    if(this.expandable && this.disabled === undefined){
      this._addExpandableListeners();
    }

    this.updateComplete
      .then(() => {
        this._withGraphic = !!this.graphicElement;
        this._withMeta = !!this.metaElement || !!this.expandable;

        if (this.expandable) {
          if (this.contentElement) {
            this.contentElement.content = true;
            this.contentElement.offsetContent = this._withGraphic;
          }

          this._disableTabIndex();
        }

        this.requestUpdate();
      })
  }

  protected _onFocus(e: FocusEvent) {
    emit(this.mdcRoot, e.type, undefined, false);
  }

  protected _onBlur(e: FocusEvent) {
    emit(this.mdcRoot, e.type, undefined, false);
  }

  protected _onClick() {
    this.toggle();
  }

  protected _onKeyDown(e: KeyboardEvent) {
    const isEnter = e.code === 'Enter' || e.keyCode === 13;
    const isSpace = e.code === 'Space' || e.keyCode === 32;

    if (isEnter || isSpace) {
      if (this.contentElement) {
        this.toggle();

        if (this.expanding) {
          this.contentElement.focus();
        }
      }
    }
  }

  protected _onExpandTransitionEnd() {
    this.listItemContent.removeEventListener('transitionend', this._handleExpandTransitionEnd);
    this.listItemContent.style.height = 'auto';
    this.style.transitionProperty = '';
    this.expanding = false;
    this.expanded = true;
    this.requestUpdate();
    emit(this.mdcRoot, 'expandTransitionEnd', {});
  }

  protected _onCollapseTransitionEnd() {
    this.listItemContent.removeEventListener('transitionend', this._handleCollapseTransitionEnd);
    this.style.transitionProperty = '';
    this.collapsing = false;
    this.collapsed = true;
    this.requestUpdate();
    emit(this.mdcRoot, 'collapseTransitionEnd', {});
  }

  protected _addExpandableListeners() {
    this.addEventListener('keydown', this._handleKeyDown);
    this.mdcRoot.addEventListener('click', this._handleClick);
  }

  protected _removeExpandableListeners() {
    this.removeEventListener('keydown', this._handleKeyDown);
    this.mdcRoot.removeEventListener('click', this._handleClick);
  }

  protected _enableTabIndex() {
    if (this.contentElement) {
      this.contentElement.listElements[0].tabIndex = 0;
    }
  }

  protected _disableTabIndex() {
    if (this.contentElement) {
      this.contentElement.listElements.forEach(item => item.tabIndex = -1);
    }
  }

  /**
   * Use to expand a expandable list-item
   */
  public expand() {
    if (this.collapsing) return;

    const contentHeight = this.listItemContentInner.getBoundingClientRect().height;

    this.expanding = true;
    this.collapsed = false;

    const siblings = [...this.parentElement!.children];
    const currentIndex = siblings.indexOf(this);
    if (currentIndex > 0) {
      const previousSibling: HTMLElement = siblings[currentIndex - 1] as HTMLElement;
      if (previousSibling.getAttribute('expanded') === 'true') {
        this.style.borderTop = 'none';
        this.style.marginTop = '0';
        this.style.paddingTop = '0';
      }
    }

    if (currentIndex < siblings.length - 1) {
      const nextSibling: HTMLElement = siblings[currentIndex + 1] as HTMLElement;
      if (nextSibling.getAttribute('expanded') === 'true' || nextSibling.getAttribute('expanding') === 'true') {
        this.style.transitionProperty = 'padding-top, margin-top, border-top-width';
        nextSibling.style.borderTop = '0';
        nextSibling.style.marginTop = '0';
        nextSibling.style.paddingTop = '0';
      }
    }

    this.listItemContent.style.height = `${contentHeight}px`;
    this.listItemContent.addEventListener("transitionend", this._handleExpandTransitionEnd);

    this._enableTabIndex();
    this.requestUpdate();
  }

  /**
   * Use to collapse a expandable list-item
   */
  public collapse() {
    if (this.expanding) return;

    this.collapsing = true;
    this.expanded = false;

    this.style.transitionProperty = '';
    this.style.borderTop = '';
    this.style.marginTop = '';
    this.style.paddingTop = '';

    const siblings = [...this.parentElement!.children];
    const currentIndex = siblings.indexOf(this);
    if (currentIndex < siblings.length - 1) {
      const nextSibling: HTMLElement = siblings[currentIndex + 1] as HTMLElement;
      if (nextSibling.getAttribute('expanded') === 'true' || nextSibling.getAttribute('expanded') === 'true') {
        this.style.transitionProperty = 'padding-top, margin-top, border-top-width';
        nextSibling.style.borderTop = '';
        nextSibling.style.marginTop = '';
        nextSibling.style.paddingTop = '';
      }
    }

    const contentHeight = this.listItemContentInner.getBoundingClientRect().height;
    const listItemContentTransition = this.listItemContent.style.transition;
    this.listItemContent.style.transition = '';

    requestAnimationFrame(() => {
        this.listItemContent.style.height = contentHeight + 'px';
        this.listItemContent.style.transition = listItemContentTransition;

        requestAnimationFrame(() => {
            this.listItemContent.style.height = '';
        });
    });

    this.listItemContent.addEventListener("transitionend", this._handleCollapseTransitionEnd);

    this._disableTabIndex();
    this.requestUpdate();
  }

  /**
   * Use to toggle the list-item in order to expand or collapse its child items
   */
  public toggle() {
    if (this.collapsed || this.collapsing) {
      this.expand();
    } else {
      this.collapse();
    }
  }
}

import { LitElement, unsafeCSS, html, TemplateResult, classMap } from '@gsk-tech/gsk-base/base-element';
import { DrawerBase as MWCDrawer } from './mwc-drawer-base';
import { gskStyle as mwcStyle } from './mwc-drawer-css';
import { gskStyle } from './gsk-drawer-css';

export class DrawerBase extends MWCDrawer {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  public updated(changedProperties: Map<PropertyKey, unknown>): void {
    super.updated(changedProperties);
    this.adjustSlottedTopAppBars();
  }

  /**
   * adds class to adjust width of any slotted top app bars
   */
  protected adjustSlottedTopAppBars(): void {
    const appBars: LitElement[] = Array.from(this.querySelectorAll('gsk-top-app-bar'));
    const isModal = this.type === 'modal';
    const isDismissible = this.type === 'dismissible';
    const isFixed = !isModal && !isDismissible;
    const adjustWidth = isFixed || (isDismissible && this.open);
    for (const appBar of appBars) {
      /* istanbul ignore next */
      appBar.updateComplete.then(() => {
        const sr = appBar.shadowRoot;
        if (sr) {
          const headerEl: HTMLElement | null = sr.querySelector('.mdc-top-app-bar');
          if (headerEl) {
            headerEl.classList.toggle('in-open-drawer-adjust', adjustWidth);
          }
        }
      });
    }
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    const dismissible = this.type === 'dismissible' || this.type === 'modal';
    const modal = this.type === 'modal';
    const classes = {
      'mdc-drawer': true,
      'mdc-drawer--dismissible': dismissible,
      'mdc-drawer--modal': modal,
    };

    const header = this.hasHeader
      ? html`
          <div class="mdc-drawer__header">
            <slot name="header"></slot>
          </div>
        `
      : '';

    return html`
      <aside class="${classMap(classes)}">
        ${header}
        <div class="mdc-drawer__content">
          <slot></slot>
        </div>
      </aside>
      ${modal
        ? html`
            <div class="mdc-drawer-scrim" @click="${this._handleScrimClick}"></div>
          `
        : ''}
      <div class="mdc-drawer-app-content">
        <slot name="appContent"></slot>
      </div>
    `;
  }
}

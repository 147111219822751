import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { CheckboxBase } from './gsk-checkbox-base';
import { CheckboxGroupBase } from './gsk-checkbox-group-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-checkbox': Checkbox;
    'gsk-checkbox-group': CheckboxGroup;
  }
}

@customElementDefinition('gsk-checkbox-group')
export class CheckboxGroup extends CheckboxGroupBase {}

@customElementDefinition('gsk-checkbox')
export class Checkbox extends CheckboxBase {}

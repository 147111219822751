import { property, PropertyValues, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { ChipBase as MWCChip, EVENTS } from './mwc-chip-base';
import { gskStyle as mwcStyle } from './mwc-chip-css';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import { gskStyle } from './gsk-chip-css';

export interface GSKChipSet {
  choice: boolean;
  filter: boolean;
  input: boolean;
}

export class ChipBase extends MWCChip {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: String })
  public value = '';

  /**
   * Optional. Default value is false. Removes the ripple effect on the Chip.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value is false. Removes ability to click the Chip.
   */
  @property({ type: Boolean })
  public disabled = false;

  protected _focused = false;

  /**
   * This function returns the focused's state of the Chip
   */
  public get focused() {
    return this._focused;
  }

  // Overriding Parent MWC Method as the Class check fails
  public setParentType(parentElement = this.parentElement) {
    if (parentElement && parentElement.nodeName === 'GSK-CHIP-SET') {
      const parent = (parentElement as unknown) as GSKChipSet;
      this._isChoice = Boolean(parent!.choice);
      this._isFilter = Boolean(parent!.filter);
      this._isInput = Boolean(parent!.input);
      this._isDefault = !this._isChoice && !this._isFilter && !this._isInput;

      this.requestUpdate();
    }
  }

  protected _handleFocus = this._onFocus.bind(this) as EventListenerOrEventListenerObject;

  protected _handleBlur = this._onBlur.bind(this) as EventListenerOrEventListenerObject;

  /**
   * This method is invoked whenever the gsk-chip is updated
   * @param changedProperties Map of changed properties with old values
   */
  updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('chip--no-ripple', this.noripple);
    }

    if (changedProperties.has('disabled')) {
      this.mdcRoot.classList.toggle('mdc-chip--disabled', this.disabled);
    }
  }

  /**
   * Performs element initialization.
   */
  async firstUpdated() {
    await super.firstUpdated();
    this.mdcRoot.addEventListener('focus', this._handleFocus);
    this.mdcRoot.addEventListener('blur', this._handleBlur);

    super._customInitialize();
  }

  /**
   * Handling focus event on the root element
   */
  public focus() {
    this.mdcRoot.focus();
  }

  /**
   * Handling forceExit which leads to removal of the chip
   */
  public forceExit() {
    this._onNotifyRemoval();
  }

  /**
   * Handling onFocus event setting focused's state to true
   */
  protected _onFocus() {
    this._focused = true;
  }

  /**
   * Handling onBlur event setting focused's state to false
   */
  protected _onBlur() {
    this._focused = false;
  }

  protected _onNotifyRemoval() {
    if (!this.disabled) {
      emit(this, EVENTS.removal, { chipId: this.id, root: this.mdcRoot, item: this }, true);
    }
  }

  protected _onNotifyInteraction() {
    if (!this.disabled) {
      super._onNotifyInteraction();
    }
  }

  protected _onNotifySelection(selected) {
    if (!this.disabled) {
      super._onNotifySelection(selected);
    }
  }
}

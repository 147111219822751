export class PromiseCall {
  protected aborted?: boolean;

  constructor(
    protected getChoices: (value: string) => Promise<unknown>,
    protected value: string
  ) {}

  protected callGetChoices() {
    return new Promise((resolve, reject) => {
      this.getChoices(this.value)
        .then(
          response => {
            if (this.aborted) return; // Return early
            resolve(response);
          }
        )
        .catch(
          reason => {
            if (this.aborted) return; // Return early
            reject(reason);
          }
        );
    })
  }

  public open() {
    return {
      abort: this.abort.bind(this),
      promise: this.callGetChoices()
    };
  }

  // TODO(luissardon): implement a way to notify closing
  protected abort() {
    this.aborted = true;
  }
}

export default class ChoicesLoader {
  protected _abortOpenPromiseCall?: Function;
  protected _debounceTimer?: number;
  protected _originalGetChoices: (value: string) => Promise<unknown>;

  constructor(getChoices: (value: string) => Promise<unknown>) {
    this._originalGetChoices = getChoices;
  }

  protected promiseCallFactory(value: string) {
    return new PromiseCall(this._originalGetChoices, value)
  }

  public debounce(wait = 100) {
    const promise = new Promise(resolve => {
      clearTimeout(this._debounceTimer);
      this._debounceTimer = setTimeout(resolve, wait);
    });

    return {
      getChoices: async (value: string) => {
        await promise;
        return this.getChoices(value);
      }
    }
  }

  public getChoices(value: string) {
    if (this._abortOpenPromiseCall) {
      this._abortOpenPromiseCall();
    }

    const {
      abort,
      promise
    } = this.promiseCallFactory(value).open();

    this._abortOpenPromiseCall = abort;

    return promise;
  }

  public abort() {
    if (this._abortOpenPromiseCall) {
      this._abortOpenPromiseCall();
      this._abortOpenPromiseCall = undefined;
    }
  }
}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { SnackbarBase } from './gsk-snackbar-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-snackbar': Snackbar;
  }
}

@customElementDefinition('gsk-snackbar')
export class Snackbar extends SnackbarBase {}

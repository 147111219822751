import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { DialogBase } from './gsk-dialog-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-dialog': Dialog;
  }
}

@customElementDefinition('gsk-dialog')
export class Dialog extends DialogBase {}

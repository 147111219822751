import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { DrawerBase } from './gsk-drawer-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-drawer': Drawer;
  }

  interface Document {
    $blockingElements: {
      push(HTMLElement): void;
      remove(HTMLElement): Boolean;
    }
  }

  interface HTMLElement {
    inert: Boolean;
  }
  
}

@customElementDefinition('gsk-drawer')
export class Drawer extends DrawerBase {}

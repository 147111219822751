import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { MenuBase } from './gsk-menu-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-menu': Menu;
  }
}

@customElementDefinition('gsk-menu', [
  'gsk-list'
])
export class Menu extends MenuBase {}

import {
  property,
  findAssignedElements,
  PropertyValues,
  emit,
  unsafeCSS,
  html,
  TemplateResult
} from '@gsk-tech/gsk-base/base-element';
import { ListBase as MWCList } from './mwc-list-base';
import { ListItemBase as MWCListItem } from './mwc-list-item-base';
import { ListDividerBase as MWCListDivider } from './mwc-list-divider-base';
import { ListGroupBase as MWCListGroup } from './mwc-list-group-base';
import { ListGroupHeaderBase as MWCListGroupHeader } from './mwc-list-group-header-base';
import { MDCListAdapter } from '@material/list/adapter';
import { closest, matches } from '@material/dom/ponyfill';
import { observer } from '@gsk-tech/gsk-base/observer';

import { gskStyle as mwcListStyle } from './mwc-list-css';
import { gskStyle as mwcListItemStyle } from './mwc-list-item-css';
import { gskStyle as mwcListDividerStyle } from './mwc-list-divider-css';
import { gskStyle as mwcListGroupStyle } from './mwc-list-group-css';
import { gskStyle as mwcListGroupHeaderStyle } from './mwc-list-group-header-css';

import { gskStyle as listStyle } from './gsk-list-css';
import { gskStyle as listItemStyle } from './gsk-list-item-css';
import { gskStyle as listDividerStyle } from './gsk-list-divider-css';
import { gskStyle as listGroupStyle } from './gsk-list-group-css';
import { gskStyle as listGroupHeaderStyle } from './gsk-list-group-header-css';

export class ListBase extends MWCList {
  public static get styles() {
    return unsafeCSS(mwcListStyle.cssText + listStyle.cssText);
  }

  /**
   * Optional. Default value is false. Removes the ripple effect on the list.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Removes ability to use the List element
   */
  @property({ type: Boolean, reflect: true })
  @observer(function(this: ListBase) {
    this._updateDisabled();
  })
  public disabled?: boolean;

  /**
   * Gets the slot list-item elements as an array
   */
  public get listElements(): MWCListItem[] {
    return this.slotEl && findAssignedElements(this.slotEl, 'gsk-list-item') as MWCListItem[];
  }

  /**
   * Gets the slot list-divider elements as an array
   */
  protected get listDividers(): MWCListDivider[] {
    return this.slotEl && findAssignedElements(this.slotEl, 'gsk-list-divider') as MWCListDivider[];
  }

  protected get selectors() {
    return {
      checkbox: 'gsk-checkbox',
      radio: 'gsk-radio',
      checkboxRadio: 'gsk-checkbox:not([disabled]), gsk-radio:not([disabled])',
      focusableChildElements: 'gsk-radio:not([disabled]), gsk-checkbox:not([disabled]), a',
      childElementsToToggleTabIndex: 'a'
    }
  }

  protected createAdapter(): MDCListAdapter {
    return {
      ...super.createAdapter(),
      setCheckedCheckboxOrRadioAtIndex: (index, isChecked) => {
        const listItem = this.listElements[index];
        const toggleEl = listItem.querySelector<HTMLInputElement>(this.selectors.checkboxRadio);
        const radioEl = listItem.querySelector<HTMLInputElement>(this.selectors.radio);

        if (toggleEl && toggleEl.parentElement === listItem) {
          if (isChecked && radioEl) {
            radioEl.click();
            radioEl.blur();
          } else {
            toggleEl.checked = isChecked;
          }

          emit(toggleEl, 'change', { index }, true);
        }
      },
    }
  }

  protected update(_changedProperties: PropertyValues) {
    super.update(_changedProperties);

    if (_changedProperties.has('noripple')) {
      this.listElements.forEach(item => item['noripple'] = this.noripple);
    }
  }

  /**
   * Used to figure out which list item this event is targetting.
   * Or returns -1 if there is no list item
   */
  protected _getListItemIndex(e: Event) {
    const eventTarget = e.target as Element;
    const nearestParent = closest(eventTarget, `gsk-list-item`);

    // Get the index of the element if it is a list item.
    if (nearestParent && matches(nearestParent, `gsk-list-item`)) {
      return this.listElements.indexOf(nearestParent as MWCListItem);
    }

    return -1;
  }

  /**
   * Initialize selectedIndex value based on pre-selected checkbox list items, single selection or radio.
   */
  protected initializeListType() {
    super.initializeListType();

    this.listElements
      .filter(item =>
        item.querySelector(this.selectors.checkboxRadio)
      )
      .map(item => item.querySelector(this.selectors.checkboxRadio))
      .forEach(item => item!['secondary'] = true);
  }

  protected _updateDisabled() {
    [...this.children].forEach(item => {
      if (Boolean(this.disabled)) {
        item.setAttribute('disabled', '');
      } else {
        item.removeAttribute('disabled');
      }
    });
  }
}

export class ListItemBase extends MWCListItem {
  public static get styles() {
    return unsafeCSS(mwcListItemStyle.cssText + listItemStyle.cssText);
  }

  protected get selectors() {
    return {
      checkbox: 'gsk-checkbox',
      radio: 'gsk-radio',
      checkboxRadio: 'gsk-checkbox:not([disabled]), gsk-radio:not([disabled])',
      focusableChildElements: 'gsk-radio:not([disabled]), gsk-checkbox:not([disabled]), a',
      childElementsToToggleTabIndex: 'a'
    }
  }

  /**
   * Optional. Default value is false. Removes the ripple effect on the list-item.
   */
  @property({ type: Boolean })
  public noripple = false;

  @property({ type: String, reflect: true })
  public name?: string;

  protected _renderArrowIcon(): TemplateResult {
    return html`
      <gsk-icon>more_vertical</gsk-icon>
    `;
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    const checkbox = this.querySelector(this.selectors.checkboxRadio) as HTMLInputElement;

    if (_changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('mdc-list--no-ripple', this.noripple);
    }

    if (_changedProperties.has('disabled')) {
      [...this.children].forEach((item) => {
        if (Boolean(this.disabled)) {
          item.setAttribute('disabled', '');
        } else {
          item.removeAttribute('disabled');
        }
      });
    }

    if (_changedProperties.has('value')) {
      if (checkbox) {
        checkbox.value = this.value;
      }
    }

    if (_changedProperties.has('name')) {
      if (checkbox && this.name !== undefined) {
        checkbox.name = this.name;
      }
    }
  }

  protected set expanded(value: boolean) {
    this._expanded = value;

    if (value) {
      this.setAttribute('expanded', 'true');
    } else {
      this.setAttribute('expanded', 'false');
    }

    if (this.expandable) {
      this.activated = this._expanded;
    }
  }
}

export class ListDividerBase extends MWCListDivider {
  public static get styles() {
    return unsafeCSS(mwcListDividerStyle.cssText + listDividerStyle.cssText);
  }
}

export class ListGroupBase extends MWCListGroup {
  public static get styles() {
    return unsafeCSS(mwcListGroupStyle.cssText + listGroupStyle.cssText);
  }
}

export class ListGroupHeaderBase extends MWCListGroupHeader {
  public static get styles() {
    return unsafeCSS(mwcListGroupHeaderStyle.cssText + listGroupHeaderStyle.cssText);
  }
}

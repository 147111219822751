import { customElementDefinition, ensureCustomElementLoaded } from '@gsk-tech/gsk-base/utils';
import { InputChipsBase } from './gsk-input-chips-base';
import { ChipBase } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { ChipSetBase } from '@gsk-tech/gsk-chip-set/gsk-chip-set-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-input-chips': InputChips;
  }
}

@ensureCustomElementLoaded('gsk-chip', 'gsk-chatbot-popover')
export class Chip extends ChipBase {}

@ensureCustomElementLoaded('gsk-chip-set', 'gsk-chatbot-popover')
export class ChipSet extends ChipSetBase {}

@customElementDefinition('gsk-input-chips')
export class InputChips extends InputChipsBase {}

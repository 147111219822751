import { TextFieldBase as MWCTextField } from './mwc-textfield-base';
import { gskStyle as mwcStyle } from './mwc-textfield-css';
import { classMap, emit, observer, query, unsafeCSS, property, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import {
  ConstraintValidationModel,
  createValidityObj,
} from '@gsk-tech/gsk-base/constraint-validation';
import { gskStyle } from './gsk-textfield-css';

export class TextFieldBase extends MWCTextField implements ConstraintValidationModel {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Use this query to get the button element
   */
  @query('.text-field__button')
  protected buttonEl!: HTMLElement;

  /**
   * Optional. Setter/getter for the textfield's name
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (this.formElement) {
      this.formElement.name = value;
    }
  })
  public name!: string;

  /**
   * Optional. Setter/getter for the textfield's autocomplete
   */
  @property({ type: String, reflect: true })
  @observer(function(this: TextFieldBase, value: string) {
    if (this.formElement) {
      this.formElement.autocomplete = value;
    }
  })
  public autocomplete!: string;

  /**
   * Optional. Default value is  'vertical'. You need to set up property "type='textarea'"" in order to use 'resize' property in the textfield
   */
  @property({ type: String })
  public resize = 'vertical';

  /**
   * Optional. You need to set up a 'label' value in order to been able to use 'labelTooltipText' property in the tooltip
   */
  @property({ type: String })
  public labeltooltiptext = '';

  /**
   * Optional. Default value is false. Use to sets the inverted color property in the tooltip
   */
  @property({ type: Boolean })
  public labeltooltipinverted = false;

  /**
   * Optional. Indicates the element containing the button's text label
   */
  @property({ type: String })
  public buttonlabel = '';

  /**
   * Optional. Indicates the element containing the button's aria-label
   */
  @property({ type: String })
  public buttonarialabel = '';

  /**
   * Optional. Indicates the element containing the button's icon name
   */
  @property({ type: String })
  public buttonicon = '';

  /**
   * Optional. Default value is false. Use to display an icon before the button's text label
   */
  @property({ type: Boolean })
  public buttonleadingicon = false;

  protected _userValidity?: boolean;

  protected get _userValiditySet(): boolean {
    return this._userValidity !== undefined;
  }

  protected _useNativeValidation = true;

  @property({ type: Boolean })
  public get valid(): boolean | undefined {
    return this._userValidity;
  }

  public set valid(isValid) {
    this._userValidity = isValid;
    if (isValid !== undefined) {
      const updateValidity = (): void => {
        this.mdcFoundation.setUseNativeValidation(false);
        this.mdcFoundation.setValid(isValid);
        this._setValidity(isValid);
        this._checkValidity(this.value);
        this.requestUpdate();
      };
      if (this.mdcFoundation) {
        updateValidity();
      } else {
        this.updateComplete.then(() => {
          updateValidity();
        });
      }
    } else {
      this.mdcFoundation.setUseNativeValidation(false);
      this.reportValidity();
    }
  }

  @property({ type: Boolean })
  public dirty = false;

  protected _validity: ValidityState = createValidityObj();

  /**
   * A read-only property that returns a ValidityState object,
   * whose properties represent validation errors for the value of that element.
   */

  public get validity(): Readonly<ValidityState> {
    if (!this.willValidate) {
      return createValidityObj();
    }
    this._checkValidity(this.value);
    return this._validity;
  }
  public reportValidity(): boolean {
    const isValid = this.checkValidity();
    this.internalValidity = isValid;
    return isValid;
  }

  protected _onInputBlur() {
    this._isFocus = false;
    this.dirty = true;
    this.value = this.formElement.value;
    if (!this._userValiditySet) {
      this.reportValidity();
    }
  }

  protected _checkValidity(value: string) {
    const nativeValidity = this.formElement.validity;

    let validity = createValidityObj(nativeValidity);

    if (this.validityTransform) {
      const customValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...customValidity };
      this.mdcFoundation.setUseNativeValidation(false);
    } else if (!this._userValiditySet) {
      this.mdcFoundation.setUseNativeValidation(true);
    }
    this._validity = validity;
    return this._validity.valid;
  }

  public get willValidate(): boolean {
    if (this.formElement) {
      return this.formElement.willValidate;
    }
    return false;
  }

  public get isUiValid(): boolean {
    if (this.willValidate) {
      if (this.valid !== undefined) {
        return this.valid;
      }

      if (this.dirty || this.validateOnInitialRender) {
        return this.validity.valid;
      }
    }
    return true;
  }

  public set isUiValid(v) {
    this.dirty = v || true;
  }

  protected _handleButtonClick = this._onButtonClick.bind(this);

  /**
   * Used to render the lit-html TemplateResult for the adjacent label
   */
  protected _renderAdjacentLabel(): TemplateResult {
    const showIconLabel = this.labeltooltiptext && this.labeltooltiptext.trim().length > 0;
    const classes = {
      'mdc-floating-label--adjacent': true,
      'mdc-floating-label--adjacent-with-icon': showIconLabel,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.label} ${showIconLabel ? this._renderIconForAdjacentLabel() : ''}
      </div>
    `;
  }

  protected _formElementId = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  /**
   * Used to render the lit-html TemplateResult for the icon for adjacent label
   */
  protected _renderIconForAdjacentLabel() {
    const classes = {
      'mdc-floating-label--adjacent-icon': true,
    };

    return html`
      <div class="${classMap(classes)}">
        <gsk-icon class="icon-label-with-tooltip" id="${this._formElementId}"
          >info_outline</gsk-icon
        >
        <gsk-tooltip
          for="${this._formElementId}"
          ?inverted="${this.labeltooltipinverted}"
          text="${this.labeltooltiptext}"
          placement="above"
          offset="${-2}"
          showDelay="${500}"
        ></gsk-tooltip>
      </div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult for the input
   */
  protected _renderInput(): TemplateResult {
    const shouldDisplayButton = this.buttonlabel || this.buttonicon;
    return html`
      ${super._renderInput()} ${shouldDisplayButton ? this._renderButton() : ''}
    `;
  }

  /**
   * Used to render the lit-html TemplateResult for the button
   */
  protected _renderButton() {
    const hasButtonLabel = this.buttonlabel && this.buttonlabel.trim().length > 0;
    const hasButtonIcon = this.buttonicon && this.buttonicon.trim().length > 0;
    const hasLabelAndIcon = hasButtonLabel && hasButtonIcon;
    const hasButtonLeadingIcon = this.buttonleadingicon;

    const classes = {
      'mdc-button': true,
      'mdc-button--raised': true,
      'mdc-button--primary': true,
      'text-field__button': true,
      'text-field__button--with-label': hasButtonLabel && !hasButtonIcon,
      'text-field__button--with-label-and-icon': hasLabelAndIcon,
      'text-field__button--with-leading-icon': hasButtonLeadingIcon,
    };

    return html`
      <button
        type="button"
        .ripple="${ripple({ unbounded: false })}"
        class="${classMap(classes)}"
        aria-label="${this.buttonarialabel || this.buttonlabel || this.buttonicon}"
      >
        ${this.buttonleadingicon && this.buttonicon ? this._renderInvertedIcon() : ''}
        ${hasButtonLabel ? this._renderButtonLabel() : ''}
        ${!this.buttonleadingicon && this.buttonicon ? this._renderInvertedIcon() : ''}
      </button>
    `;
  }

  protected _renderButtonLabel() {
    return html`
      <span class="mdc-button__label">${this.buttonlabel}</span>
    `;
  }

  protected _renderInvertedIcon() {
    return html`
      <i class="material-icons mdc-button__icon">${this.buttonicon}</i>
    `;
  }

  /**
   * This method is invoked whenever the gsk-textfield is updated
   *
   * @param changedProperties Map of changed properties with old values
   */
  public updated(changedProperties): void {
    this.setAttribute('outlined', '');

    super.updated(changedProperties);

    if (changedProperties.has('resize') && this.type === 'textarea') {
      this.formElement.style.resize = this.resize;
    }

    if (changedProperties.has('disabled') && this.disabled === false) {
      // Fixes focus style after removing disabled state on IE11
      setTimeout(() => {
        this.mdcFoundation.deactivateFocus();
      });
    }

    if (changedProperties.has('required')) {
      this.mdcRoot.classList.toggle('mdc-text-field--required', Boolean(this.required));
    }
  }

  protected _onButtonClick(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();

    emit(this, 'submit', { input: this.value }, true);
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time work on the element after update.
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   */
  public async firstUpdated() {
    super.firstUpdated();

    if (this.buttonEl) {
      this.buttonEl!.addEventListener('click', this._handleButtonClick);
    }

    await this.updateComplete;
    // Fixes textarea initial focus style on IE11
    setTimeout(() => {
      // Jeff - Note - the following 'deactivateFocus' causes the fields to show up as invalid
      // this.mdcFoundation.deactivateFocus();
      if (this.validateOnInitialRender) {
        this.reportValidity();
      }
    });
  }
}

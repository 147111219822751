import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { TopAppBarBase } from './gsk-top-app-bar-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-top-app-bar': TopAppBar;
  }
}

@customElementDefinition('gsk-top-app-bar')
export class TopAppBar extends TopAppBarBase {}

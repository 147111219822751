import { findAssignedElements } from '@gsk-tech/gsk-base/base-element';
import { PropertyValues, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { CardBase as MWCCard } from './mwc-card-base';
import { gskStyle as mwcStyle } from './mwc-card-css';
import { gskStyle } from './gsk-card-css';

export class CardBase extends MWCCard {
    public static get styles() {
        return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
    }

    /**
     * Root element for gsk-card component.
     */
    @query('.mdc-card')
    protected mdcRoot!: HTMLElement;

    /**
     * Optional. Media area that displays a custom background-image with background-size: cover
     */
    @query('.mdc-card__media')
    protected mediaEl!: HTMLElement;

    /**
     * Optional. Adds a media background image.
     */
    @property({ type: String, reflect: true })
    public image = '';

    protected get actionButtons() {
        return this.slotActionButtonsEl && findAssignedElements(this.slotActionButtonsEl, 'gsk-button');
    }

    protected get actionIcons() {
        return this.slotActionIconsEl && findAssignedElements(this.slotActionIconsEl, 'gsk-icon-button');
    }

    /**
     * This method is invoked whenever the gsk-card is updated
     * @param _changedProperties Map of changed properties with old values
     */
    public updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        if (_changedProperties.has('image')) {
            if (this.mediaEl instanceof HTMLElement) {
                if (this.image) {
                    this.mediaEl.style.backgroundImage = `url('${this.image}')`;
                } else {
                    this.mediaEl.style.backgroundImage = '';
                }
            }
        }
    }

    public firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);

        const _hasHeaderEls = this.headerEls.length > 0;
        const _hasMediaEls = this.mediaEls.length > 0;
        const _hasContentEls = this.contentEls.length > 0;
        const _hasPrimaryAction = _hasHeaderEls || _hasMediaEls || _hasContentEls;

        if (_hasPrimaryAction) {
            this.mdcRoot.classList.add('mdc-card--with-primary-action');
        }
    }
}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  property,
  query,
  classMap,
  addHasRemoveClass,
  emit,
  html,
  TemplateResult, LitElement,
} from '@gsk-tech/gsk-base/base-element';
import { TabIndicatorBase } from './mwc-tab-indicator-base';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import MDCTabFoundation from '@material/tab/foundation';
import { MDCTabAdapter } from '@material/tab/adapter';

// used for generating unique id for each tab
let tabIdCounter = 0;

export const EVENTS = {
  interacted: 'interacted'
};

export class TabBase extends BaseElement {
  static shadowRootOptions = { ...LitElement.shadowRootOptions, delegatesFocus: true };

  protected mdcFoundation!: MDCTabFoundation;

  protected readonly mdcFoundationClass = MDCTabFoundation;

  @query('.mdc-tab')
  protected mdcRoot!: HTMLElement;

  @query('gsk-mwc-tab-indicator')
  protected tabIndicator!: TabIndicatorBase;

  /**
   * Optional. Indicates the text label of the tab
   */
  @property()
  label = '';

  /**
   * Optional. Indicates a leading icon in the tab
   */
  @property()
  icon = '';

  /**
   * Optional. Default value is false. Sets up the tab indicator to fade in on activation and fade out on deactivation.
   */
  @property({ type: Boolean })
  isFadingIndicator = false;

  /**
   * Optional. Default value is false.
   * Use this property to indicates that the tab should shrink in size to be as narrow as possible without causing text to wrap.
   */
  @property({ type: Boolean })
  minWidth = false;

  /**
   * Optional. Default value is false.
   * Indicates that the tab indicator should shrink in size to be as narrow as possible without causing text to wrap.
   * When used by itself, the tab is it's full normal width and the indicator is narrow.
   */
  @property({ type: Boolean })
  isMinWidthIndicator = false;

  /**
   * Optional. Sets the Tab indicator to be an icon of your choice. This replaces the default tab indicator which is an underline on the selected tab
   */
  @property()
  indicatorIcon = '';

  /**
   * Optional. Indicates that the tab icon and label should flow vertically instead of horizontally
   */
  @property({ type: Boolean })
  stacked = false;

  /**
   * Other properties
   * indicatorContent <slot>
   * previousIndicatorClientRect (needed?)
   * onTransitionEnd (needed?)
   */

  @query('gsk-mwc-tab-indicator')
  protected _tabIndicator!: HTMLElement;

  @query('.mdc-tab__content')
  protected _contentElement!: HTMLElement;

  protected _handleClick() {
    this.mdcFoundation.handleClick();
  }

  /**
   * Invoked each time the custom element is appended into the DOM.
   * This will happen each time the node is moved, and may happen before the element's contents have been fully parsed
   */
  connectedCallback() {
    this.dir = document.dir;
    super.connectedCallback();
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const classes = {
      'mdc-tab--min-width': this.minWidth,
      'mdc-tab--stacked': this.stacked
    };
    return html`
      <button @click="${this._handleClick}" class="mdc-tab ${classMap(classes)}" role="tab" aria-selected="false" tabindex="-1">
        <span class="mdc-tab__content">
          <slot></slot>
          ${this.icon ? html`<span class="mdc-tab__icon material-icons">${this.icon}</span>` : ''}
          ${this.label ? html`<span class="mdc-tab__text-label">${this.label}</span>` : ''}
          ${this.isMinWidthIndicator ? this.renderIndicator() : ''}
        </span>
        ${this.isMinWidthIndicator ? '' : this.renderIndicator()}
        <span class="mdc-tab__ripple" .ripple="${ripple({ interactionNode: this, unbounded: false })}"></span>
      </button>`;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  firstUpdated() {
    super.firstUpdated();

    // create a unique id
    this.id = this.id || `mdc-tab-${++tabIdCounter}`;
  }

  /**
   * Used to render the lit-html TemplateResult with a tab-indicator
   */
  renderIndicator() {
    return html`<gsk-mwc-tab-indicator .icon="${this.indicatorIcon}" .fade="${this.isFadingIndicator}"></gsk-mwc-tab-indicator>`;
  }

  /**
   * Create the adapter for the `mdcFoundation`.
   *
   * Override and return an object with the Adapter's functions implemented
   */
   /* istanbul ignore next */
  createAdapter(): MDCTabAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      setAttr: (attr: string, value: string) => this.mdcRoot.setAttribute(attr, value),
      activateIndicator: (previousIndicatorClientRect: ClientRect) =>
        (this._tabIndicator as TabIndicatorBase).activate(previousIndicatorClientRect),
      deactivateIndicator: () =>
        (this._tabIndicator as TabIndicatorBase).deactivate(),
      notifyInteracted: () => {
        emit(this, EVENTS.interacted, { tabId: this.id }, true);
      },
      getOffsetLeft: () => this.offsetLeft,
      getOffsetWidth: () => this.mdcRoot.offsetWidth,
      getContentOffsetLeft: () => this._contentElement.offsetLeft,
      getContentOffsetWidth: () => this._contentElement.offsetWidth,
      focus: () => this.mdcRoot.focus(),
    }
  }

  /**
   * Activates the indicator 'clientRect' is an optional argument.
   */
  activate(clientRect: ClientRect) {
    this.mdcFoundation.activate(clientRect);
  }

  /**
   * Deactivates the indicator
   */
  deactivate() {
    this.mdcFoundation.deactivate();
  }

  /**
   * Returns the dimensions of the Tab
   */
  computeDimensions() {
    return this.mdcFoundation.computeDimensions();
  }

  /**
   * Returns the bounding client rect of the indicator
   */
  computeIndicatorClientRect() {
    return this.tabIndicator.computeContentClientRect();
  }

  /**
   * Applies focus to the root element
   *
   * NOTE: needed only for ShadyDOM where delegatesFocus is not implemented
   */
  focus() {
    this.mdcRoot.focus();
  }

}

import { customElementDefinition, ensureCustomElementLoaded } from '@gsk-tech/gsk-base/utils';
import { SelectBase } from './gsk-select-base';
import { IconBase } from '@gsk-tech/gsk-icon/gsk-icon-base';
import { TooltipBase } from '@gsk-tech/gsk-tooltip/gsk-tooltip-base';
import { TextFieldBase } from '@gsk-tech/gsk-textfield/gsk-textfield-base';
import { CheckboxBase } from '@gsk-tech/gsk-checkbox/gsk-checkbox-base';
import { ButtonBase } from '@gsk-tech/gsk-button/gsk-button-base';
import { ChipBase } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { ChipSetBase } from '@gsk-tech/gsk-chip-set/gsk-chip-set-base';
import {
  ListBase,
  ListGroupHeaderBase,
  ListGroupBase,
  ListDividerBase,
  ListItemBase
} from '@gsk-tech/gsk-list/gsk-list-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-select': Select;
  }
}

@ensureCustomElementLoaded('gsk-icon', 'gsk-select')
export class Icon extends IconBase {}

@ensureCustomElementLoaded('gsk-tooltip', 'gsk-select')
export class Tooltip extends TooltipBase {}

@ensureCustomElementLoaded('gsk-textfield', 'gsk-select')
export class TextField extends TextFieldBase {}

@ensureCustomElementLoaded('gsk-textfield', 'gsk-select')
export class Checkbox extends CheckboxBase {}

@ensureCustomElementLoaded('gsk-button', 'gsk-select')
export class Button extends ButtonBase {}

@ensureCustomElementLoaded('gsk-chip', 'gsk-select')
export class Chip extends ChipBase {}

@ensureCustomElementLoaded('gsk-chip-set', 'gsk-select')
export class ChipSet extends ChipSetBase {}

@ensureCustomElementLoaded('gsk-list-item', 'gsk-select')
export class ListItem extends ListItemBase {}

@ensureCustomElementLoaded('gsk-list-divider', 'gsk-select')
export class ListDivider extends ListDividerBase {}

@ensureCustomElementLoaded('gsk-list-group', 'gsk-select')
export class ListGroup extends ListGroupBase {}

@ensureCustomElementLoaded('gsk-list-group-header', 'gsk-select')
export class ListGroupHeader extends ListGroupHeaderBase {}

@ensureCustomElementLoaded('gsk-list', 'gsk-select')
export class List extends ListBase {}

// Define Element
@customElementDefinition('gsk-select')
export class Select extends SelectBase {}

import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { SwitchBase } from './gsk-switch-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-switch': Switch;
  }
}

@customElementDefinition('gsk-switch')
export class Switch extends SwitchBase {}

export type CustomValidityState = { -readonly [P in keyof ValidityState]: ValidityState[P] };

/**
 * @see {@link https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#the-constraint-validation-api}
 */
export const createValidityObj = (customValidity: Partial<ValidityState> = {}): ValidityState => {
  const objectifiedCustomValidity: Partial<CustomValidityState> = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const propName in customValidity) {
    objectifiedCustomValidity[propName as keyof CustomValidityState] =
      customValidity[propName as keyof ValidityState];
  }

  return {
    badInput: false,
    customError: false,
    patternMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valid: true,
    valueMissing: false,
    ...objectifiedCustomValidity,
  };
};

export type ValidityTransform<T = string> = (
  value: T,
  nativeValidity: ValidityState,
) => Partial<ValidityState>;

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Constraint_validation}
 */
export interface ConstraintValidationModel<T = string> {
  valid?: boolean;
  validationMessage: string;
  validateOnInitialRender: boolean;
  validityTransform: ValidityTransform<T> | null;
  readonly validity: ValidityState;
  readonly willValidate: boolean;
  checkValidity(): boolean;
  reportValidity(): boolean;
  setCustomValidity(message: string): void;
}

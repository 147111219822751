import { property, query, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import { DialogBase as MWCDialog } from './mwc-dialog-base';
import { gskStyle as mwcStyle } from './mwc-dialog-css';
import { gskStyle } from './gsk-dialog-css';
// import { strings } from '@material/dialog/constants'

export class DialogBase extends MWCDialog {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @query('.mdc-dialog__button[data-mdc-dialog-action="accept"]')
  protected acceptButton!: HTMLButtonElement;

  @query('.mdc-dialog__button[data-mdc-dialog-action="cancel"]')
  protected cancelButton!: HTMLButtonElement;

  @query('.mdc-dialog__actions')
  protected footerActions!: HTMLButtonElement;

  @query('.mdc-dialog__surface')
  protected surface!: HTMLButtonElement;

  /**
   * Optional. Default value is false. Hides the actions buttons of the dialog.
   */
  @property({ type: Boolean })
  public hideActionButtons = false;

  /**
   * Optional. Default value is sets to `false`. Disables success/accept button.
   */
  @property({ type: Boolean })
  public acceptdisabled = false;

  /**
   * Optional. Default value is sets to `false`. Disables cencel/decline button.
   */
  @property({ type: Boolean })
  public declinedisabled = false;

  protected _renderButton(label: string, action: string): TemplateResult {
    this.hideActionButtons = this.popover ? true : this.hideActionButtons;

    if (this.hideActionButtons) return html``;

    return super._renderButton(label, action);
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  public firstUpdated() {
    super.firstUpdated();
    if (this.acceptButton) {
      // this.acceptButton.classList.add('mdc-button--raised');
    }
  }

  /**
   * This method is invoked whenever the gsk-dialog is updated
   *
   * @param changedProperties Map of changed properties with old values
   */
  public updated(changedProperties): void {
    // not calling super.updated breaks @observer decorated properties
    super.updated(changedProperties);
    if (changedProperties.has('hideActionButtons')) {
      this.footerActions.classList.toggle('mdc-dialog__actions--no-format', this.hideActionButtons);
    }

    if (changedProperties.has('scrollable')) {
      this.surface.classList.toggle('mdc-dialog__surface--scrollable', this.scrollable);
    }

    if (changedProperties.has('acceptdisabled') && this.acceptButton) {
      // this.acceptButton.classList.add('mdc-button--raised');

      if (this.acceptdisabled) {
        this.acceptButton.setAttribute('disabled', '');
      } else {
        this.acceptButton.removeAttribute('disabled');
      }
    }

    if (changedProperties.has('declinedisabled') && this.cancelButton) {
      if (this.declinedisabled) {
        this.cancelButton.setAttribute('disabled', '');
      } else {
        this.cancelButton.removeAttribute('disabled');
      }
    }
  }
}

import { unsafeCSS } from 'lit';
import { IconBase as MWCIcon } from './mwc-icon-base';
import { gskStyle as mwcStyle } from './mwc-icon-host-css';
import { gskStyle } from './gsk-icon-css';

export class IconBase extends MWCIcon {
  /* istanbul ignore next */
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }
}

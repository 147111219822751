import {
  unsafeCSS,
  CSSResult,
  LitElement,
  TemplateResult,
  html,
} from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// eslint-disable-next-line import/no-unresolved
import { gskStyle } from './gsk-alert-css';

export class AlertBase extends LitElement {
  public static get styles(): CSSResult {
    return unsafeCSS(gskStyle.cssText);
  }

  protected static readonly iconMap = Object.freeze({
    info: 'info',
    warning: 'warning',
    danger: 'alert',
    tip: 'eye',
  });

  @property({ type: String })
  public title: string = '';

  @property({ type: String })
  public text: string = '';

  private _type: keyof typeof AlertBase.iconMap = 'info';

  @property({ type: String })
  get type() {
    return this._type;
  }
  set type(value) {
    const oldValue = this._type;
    if (!AlertBase.iconMap[value]) {
      console.warn(`AlertBase: Alert type "${value}" has been defaulted to "info"`);
      this._type = 'info';
    } else {
      this._type = value;
    }
    this.requestUpdate('type', oldValue);
  }

  protected renderTitle(): TemplateResult | void {
    if (this.title) {
      return html`
        <div class="gsk-alert__title">${this.title}</div>
      `;
    }
  }

  protected render(): TemplateResult | void {
    const classes = {
      'gsk-alert': true,
      [`gsk-alert--${this.type}`]: true,
    };
    return html`
      <div class="${classMap(classes)}">
        <div class="gsk-alert__icon">
          <gsk-icon>${AlertBase.iconMap[this.type]}</gsk-icon>
        </div>
        <div>
          ${this.renderTitle()}
          <div class="gsk-alert__text">
            <slot>${this.text}</slot>
          </div>
        </div>
      </div>
    `;
  }
}

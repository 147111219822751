import { property, unsafeCSS, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import {
  CircularProgressBase as MWCCircularProgress
} from './mwc-circular-progress-base';
import { gskStyle as mwcStyle } from './mwc-circular-progress-css'
import { gskStyle } from './gsk-circular-progress-css';

export class CircularProgressBase extends MWCCircularProgress {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @property({ type: String })
  public label;

  @property({ type: Boolean })
  public mini;


  get size() {
    return this.mini ? 24 : 44;
  }

  // size is not finely adjustable in our system
  set size(_: 44 | 24) {
    // noop
  }

  thickness = 4;

  render(): TemplateResult {
    return html`
      <div class="mwc-circular-progress__container">
        ${super.render()}
        ${this._renderLabel()}
      </div>
    `;
  }

  protected _renderLabel(): TemplateResult {
    return this.label
    ? html`<span class="mwc-circular-progress__label">${this.label}</span>`
    : html``;
  }
}

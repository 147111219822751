import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { TooltipBase } from './gsk-tooltip-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-tooltip': Tooltip;
  }
}

@customElementDefinition('gsk-tooltip')
export class Tooltip extends TooltipBase {}

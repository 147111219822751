import { property, query, unsafeCSS, styleMap, classMap, html, TemplateResult } from '@gsk-tech/gsk-base/base-element';
import {
  AutocompleteBase as GSKAutocompleteBase,
  Choice as AutocompleteChoice,
  ChoicesLoader,
} from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';
import { ChipBase as GSKChip } from '@gsk-tech/gsk-chips/gsk-chip-base';
// @ts-ignore
import { PopoverBase as GSKPopoverBase } from '@gsk-tech/gsk-popover/gsk-popover-base';
import { gskStyle } from './gsk-people-picker-css';

/* eslint-disable no-bitwise */

export interface PersonEntryItem {
  source_key: string;
  title: string;
  link: string;
  id: string;
  reference: string;
  updated: string;
  summary: string;
  content: PersonEntryItemContent;
  relevance: string;
  startIndex: string;
}

export interface PersonEntryItemContent extends Record<string, any> {
  EMAIL: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  PICTUREURL: string;
  JOBTITLE: string;
  LOCATION: string;
  MUDID: string;
}

export interface Choice extends AutocompleteChoice {
  entry: PersonEntryItem;
}

export interface PersonDisplayData {
  email?: string;
  jobTitle?: string;
  location?: string;
}

export class PeoplePickerBase extends GSKAutocompleteBase {
  public static get styles() {
    return unsafeCSS(GSKAutocompleteBase.styles.cssText + gskStyle.cssText);
  }

  @query('.people-picker__popover')
  protected popover?: GSKPopoverBase;

  /**
   * Required. Use this property to sets the token needed for any API request
   */
  @property({ type: String })
  public apitoken = '';

  /**
   * Optional. Use this property to specifies the search API url to be used
   */
   @property({ type: String })
   public apiurl = 'https://api.gsk.com/tools/search/query';


  /**
   * Optional. Use this property to specify the app name on search request payload
   */
  @property({ type: String })
  public appname = 'EnterpriseSearchApp';

  /**
   * Optional. Use this property to request specific extra fields from the API
   */
  @property({ type: String })
  public apiextrafields = '';

  /**
   * Optional. Use this property to limit the number of results from the API.
   */
  @property({ type: Number })
  public apilimit = 10;

  /**
   * Optional. Use this property to sets the popoverposition
   */
  @property({ type: String })
  protected popoverposition = 'bottom-start';

  @property({ type: String })
  protected popovermode: 'click' | 'hover' = 'hover';

  /**
   * Optional. Use this property to use the inverted version of the popover
   */
  @property({ type: Boolean })
  protected popoverinverted = false;

  /**
   * Optional. Use this property to set the src value for the search api
   */
  @property({ type: String })
  public source = 'people';

  /**
   * Optional. Default value of avatar colors are: ['#40488d', '#e49b13', '#bc1077', '#f36633', '#008a00']
   * Use this property to set the array of avatar colors
   */
  @property({ type: Array })
  public avatarColors = ['#40488d', '#e49b13', '#bc1077', '#f36633', '#008a00'];

  @property({ attribute: false })
  protected imageSrc!: any;

  @property({ type: Boolean })
  protected singleSelect: boolean = false;

  public get suggest() {
    return false;
  }

  // eslint-disable-next-line no-empty-function
  public set suggest(_) {}

  public get async() {
    return true;
  }

  // eslint-disable-next-line no-empty-function
  public set async(_) {}

  public get multiselect() {
    return true;
  }

  // eslint-disable-next-line no-empty-function
  public set multiselect(_) {}

  public getChoices = this._search.bind(this);

  /**
   * set to false so that the search api will determine what appears in the
   * choice list, no additional filtering is needed
   */
  protected _shouldFilterChoices(): boolean {
    return false;
  }

  protected get popoverAvatar(): HTMLElement {
    return this.popover!.querySelector('.people-picker__popover-avatar') as HTMLElement;
  }

  protected _popoverData?: Choice;

  protected get popoverData(): Choice | undefined {
    return this._popoverData
      ? ([...this._selectedChoices, ...this.choices].find(
          item => item.value === this._popoverData!.value,
        ) as Choice)
      : undefined;
  }

  protected set popoverData(value: Choice | undefined) {
    this._popoverData = value;
  }

  protected _listType = 'two-line';

  protected _choicesLoaders: ChoicesLoader[] = [];

  /**
   * (Override) Returns loader item
   */
  protected _renderLoader(): TemplateResult {
    return html`
      <gsk-list-item disabled>
        <gsk-circular-progress mini slot="graphic"></gsk-circular-progress>
        Loading...
      </gsk-list-item>
      ${this._shouldRenderChoices()
        ? html`
            <gsk-list-divider></gsk-list-divider>
          `
        : ''}
    `;
  }

  /**
   * (Override) Returns a choice item
   */
  protected _renderChoice(choice: Choice) {
    const { value } = choice;

    return html`
      <gsk-list-item value="${value}" avatarlist type="two-line">
        ${this._renderChoiceAvatar(choice)} ${this._renderChoiceText(choice)}
      </gsk-list-item>
    `;
  }

  /**
   * Returns avatar element
   */
  protected _renderChoiceAvatar(choice: Choice) {
    const { label, entry } = choice;
    const {
      reference,
      content: { EMAIL },
    } = entry;
    const avatarColor = reference ? this._getAvatarColor(reference) : '';
    const avatarImage = EMAIL ? this._getAvatarImageURL(EMAIL) : '';
    const avatarInitials = this._getInitials(label);
    const graphicStyle = {
      backgroundColor: avatarColor,
      color: avatarColor ? this._getAvatarTextColor(avatarColor) : '',
    };
    const _handleImageLoad = event => {
      event.target.parentElement.classList.add('people-picker__list-item-graphic--img-loaded');
    };
    const avatarImageElement = avatarImage
      ? html`
          <img src="${avatarImage}" @load="${_handleImageLoad}" alt="${label}" />
        `
      : '';

    return html`
      <span
        slot="graphic"
        style="${styleMap(graphicStyle)}"
        class="people-picker__list-item-graphic"
        data-text="${avatarInitials}"
      >
        ${avatarImageElement}
      </span>
    `;
  }

  /**
   * Returns choice's text
   */
  protected _renderChoiceText(choice: Choice) {
    const { label, entry } = choice;
    const { email, jobTitle, location } = this._getPersonDisplayData(entry);

    const pipeTemplate = html`
      &nbsp;|&nbsp;
    `;
    const emailTemplate = email
      ? html`
          <span>${email}</span>
        `
      : '';
    const jobTitleTemplate = jobTitle
      ? html`
          <span>${jobTitle}</span>
        `
      : '';
    const locationTemplate = location
      ? html`
          <span>${location}</span>
        `
      : '';

    return html`
      <span slot="primary">${label}</span>
      <span slot="secondary">
        ${emailTemplate} ${email && jobTitle ? pipeTemplate : ''} ${jobTitleTemplate}
        ${(email || jobTitle) && location ? pipeTemplate : ''} ${locationTemplate}
      </span>
    `;
  }

  /**
   * Returns popover content
   */
  protected _renderPopoverContent() {
    const classes = {
      'people-picker__popover': true,
      'people-picker__popover--inverted': this.popoverinverted,
    };

    return html`
      <div class="${classMap(classes)}">
        ${this.popoverData ? this._renderPopoverHeader() : ''}
        ${this.popoverData ? this._renderPopoverFooter() : ''}
      </div>
    `;
  }

  /**
   * Returns popover header
   */
  protected _renderPopoverHeader() {
    const {
      label,
      entry: {
        content: { EMAIL },
      },
    } = this.popoverData as Choice;

    return html`
      <header class="people-picker__popover-header">
        ${this._renderPopoverAvatar(this.popoverData as Choice)}
        <div class="people-picker__popover-right">
          <span class="people-picker__popover-label">
            ${label}
          </span>
          <span class="people-picker__popover-email">
            ${EMAIL}
          </span>
        </div>
      </header>
    `;
  }

  /**
   * Returns popover avatar
   */
  protected _renderPopoverAvatar(choice: Choice) {
    const { label, entry } = choice;
    const {
      reference,
      content: { EMAIL },
    } = entry;
    const avatarImage = EMAIL ? this._getAvatarImageURL(EMAIL) : '';
    const avatarInitials = this._getInitials(label);
    const avatarColor = reference ? this._getAvatarColor(reference) : '';
    const graphicStyle = {
      backgroundColor: avatarColor,
      color: avatarColor ? this._getAvatarTextColor(avatarColor) : '',
    };
    const _handleImageLoad = async event => {
      await this.updateComplete;
      event.target.parentElement.classList.add('people-picker__popover-avatar--img-loaded');
    };
    const avatarImageElement = avatarImage
      ? html`
          <img src="${avatarImage}" @load="${_handleImageLoad}" alt="${label}" />
        `
      : '';

    if (this.popoverAvatar) {
      this.popoverAvatar.classList.remove('people-picker__popover-avatar--img-loaded');
    }

    return html`
      <span
        style="${styleMap(graphicStyle)}"
        class="people-picker__popover-avatar"
        data-text="${avatarInitials}"
      >
        ${avatarImageElement}
      </span>
    `;
  }

  /**
   * Returns popover footer
   */
  protected _renderPopoverFooter() {
    const {
      entry: {
        content: { JOBTITLE, LOCATION },
      },
    } = this.popoverData as Choice;
    const _shouldHideFooter = !JOBTITLE && !LOCATION;
    const _shouldRenderDivider = JOBTITLE && LOCATION;
    const classes = {
      'people-picker__popover-footer': true,
      'people-picker__popover-footer--hide': _shouldHideFooter,
    };

    return html`
      <footer class="${classMap(classes)}">
        ${JOBTITLE}
        ${_shouldRenderDivider
          ? html`
              &nbsp;|&nbsp;
            `
          : ''}
        ${LOCATION}
      </footer>
    `;
  }

  /**
   * (Override) Returns an input chips
   */
  protected _renderInputChips() {
    return html`
      <gsk-input-chips class="autocomplete__input" preventAutoInput></gsk-input-chips>
      <gsk-popover
        class="people-picker__popover"
        gap="0"
        size="small"
        strategy="fixed"
        position="${this.popoverposition}"
      >
        ${this._renderPopoverContent()}
      </gsk-popover>
    `;
  }

  protected async firstUpdated() {
    super.firstUpdated();

    await this.updateComplete;

    // Fix popover lef alignment
    // const popoverRoot = this.popover!.shadowRoot!.querySelector('.popover') as HTMLElement;
    // popoverRoot.style.marginLeft = '4px';

    // Patch initial choices data
    if (this._hasChoices) {
      this._patchInitialChoicesData();
    }
  }

  /**
   * Patches initial choices data
   *
   * Loads and patches existing choices data
   */
  protected _patchInitialChoicesData() {
    this._choicesLoaders = this.choices.map(choice => this._loadAndPatchChoice(choice as Choice));
  }

  /**
   * Loads and patches existing choice data
   *
   * uses async getChoices in order to obtain initial choice data,
   * then updates available choices, selected choices, chips label,
   * and chips image sources with the new data.
   *
   * Returns choices loader instance.
   */
  protected _loadAndPatchChoice(choice: Choice) {
    const choicesLoader = new ChoicesLoader(this.getChoices);

    choicesLoader.getChoices(choice.label).then(async response => {
      const choices = response as Choice[];
      const matchedChoice = choices.find(item => item.value === choice.value);

      setTimeout(async () => {
        const currentChoiceIndex = this.choices.findIndex(item => item.value === choice.value);

        if(matchedChoice)
        this.choices[currentChoiceIndex] = matchedChoice as Choice;

        this._updateAvailableChoices();
        this._updateSelectedChoices();
        this._updateChipsLabel();
        this._updateChipsImageSrc();

        await this.requestUpdate();

        this._filterChoices();
      });
    });

    return choicesLoader;
  }

  /**
   * Updates selected choices base on updated choices
   */
  protected _updateSelectedChoices() {
    this._selectedChoices = this.choices.filter(item =>
      this.selectedChoices.find(choice => choice.value === item.value),
    );
  }

  /**
   * Updates chips label
   *
   * Updates chips label based on updated selected choices
   */
  protected _updateChipsLabel() {
    this.inputChips!.chips.forEach(chip => {
      const matchedChoice = this.selectedChoices.find(
        choice => choice.value === chip.value,
      ) as Choice;
      chip.label = matchedChoice.label;
    });
  }

  /**
   * Updates chips image src
   *
   * Updates chips avatar with an image based on updated selected choices
   */
  protected _updateChipsImageSrc() {
    this.inputChips!.chips.forEach(chip => {
      const matchedChoice = this.selectedChoices.find(
        choice => choice.value === chip.value,
      ) as Choice;
      this._addChipImageSrc(chip, matchedChoice.entry.content.EMAIL);
    });
  }

  /**
   * Searches for people sending the query to the API
   *
   * @param { string } searchQuery text to be used for searching people
   */
  protected _search(searchQuery: string): Promise<Choice[]> {
    if (this.apiurl.indexOf('sinequa') !== -1) {
      return this._searchSinequa(searchQuery);
    } else {
      return this._searchLegacy(searchQuery);
    }
  }

  /**
     * Searches for people sending the query to the API
     *
     * @param { string } searchQuery text to be used for searching people
     */
  protected _searchLegacy(searchQuery: string): Promise<Choice[]> {
    const { apiurl, apitoken, apiextrafields, apilimit } = this;
    const url = new URL(apiurl);
    const bearer = `Bearer ${apitoken}`;
    const src = this.source;
    let fields = [
      'email',
      'first_name',
      'last_name',
      'pictureurl',
      'jobtitle',
      'location',
      'mudId',
    ].join(',');
    // Include api extra fields
    if (apiextrafields) {
      fields = `${fields},${apiextrafields}`;
    }

    url.searchParams.append('q', searchQuery);
    url.searchParams.append('src', src);
    url.searchParams.append('fields', fields);
    url.searchParams.append('limit', apilimit.toString());

    return fetch(url.toString(), {
      headers: {
        Accept: 'application/json',
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then(response => {
        const { status } = response;
        if (status >= 400 && status < 600) {
          return Promise.reject(response);
        }

        return response.json();
      })
      .then(json => {
        return json.entries.map((entry: PersonEntryItem) => ({
          ...entry,
          content: typeof entry.content === 'string' ? JSON.parse(entry.content) : entry.content,
        })) as PersonEntryItem[];
      })
      .then(entries => {
        return entries.map(entry => {
          return {
            value: entry.content.MUDID,
            label: `${entry.content.FIRST_NAME} ${entry.content.LAST_NAME}`,
            entry,
          };
        }) as Choice[];
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  }

  /**
     * Searches for people sending the query to the API
     *
     * @param { string } searchQuery text to be used for searching people
     */
  protected _searchSinequa(searchQuery: string): Promise<Choice[]> {
    const { apiurl, apitoken, apilimit } = this;

    const url = new URL(apiurl);
    const bearer = `Bearer ${apitoken}`;

    const requestBody = {
      app: this.appname,
      query: {
        page: 0,
        pageSize: apilimit,
        name: "enterprise-search-query",
        text: searchQuery,
        select: [
          {
            expression: "treepath: (`People`:`/People/*`)",
            facet: "Treepath"
          }
        ]
      }
    };

    return fetch(url.toString(), {
      headers: {
        Accept: 'application/json',
        Authorization: bearer,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        const { status } = response;
        if (status >= 400 && status < 600) {
          return Promise.reject(response);
        }

        return response.json();
      })
      .then(json => {
        console.log("Sinequa response");
        console.log(json)
        console.log("Sinequa response");

        return json.records.map((item) => ({
          source_key: this.source,
          title: item.displayFields.name,
          link: item.displayFields.userId,
          id: "",
          reference: item.displayFields.userId,
          updated: "",
          summary: "",
          relevance: item.globalrelevance,
          startIndex: "",
          content: {
            EMAIL: item.displayFields.mail,
            FIRST_NAME: item.sourcestr201,
            LAST_NAME: item.sourcestr202,
            PICTUREURL: item.displayFields.myconnect_photo_url,
            JOBTITLE: item.displayFields.jobTitle,
            LOCATION: item.displayFields.location,
            MUDID: item.displayFields.userId,
          },
        })) as PersonEntryItem[];
      })
      .then(entries => {
        return entries.map(entry => {
          return {
            value: entry.content.MUDID,
            label: `${entry.content.FIRST_NAME} ${entry.content.LAST_NAME}`,
            entry,
          };
        }) as Choice[];
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  }

  /**
   * Gets image url
   *
   * Returns image based on person's email
   */
  protected _getAvatarImageURL(email: string): string {
    return `https://myconnect.gsk.com/_layouts/15/userphoto.aspx?size=L&username=${email}`;
  }

  /**
   * Gets Person data
   *
   * Returns data to display based on Person entry props:
   *  - EMAIL
   *  - JOBTITLE
   *  - LOCATION
   */
  protected _getPersonDisplayData(entry: PersonEntryItem): PersonDisplayData {
    const { EMAIL: email, JOBTITLE: jobTitle, LOCATION: location } = entry.content;

    return {
      email,
      jobTitle,
      location,
    };
  }

  protected _setValueBySelection() {
    const matchedChoice = this._filteredChoices.find(
      item => item.value === this._tempSelectedItem!.value,
    ) as Choice;

    if (this.multiselect) {
      // Adds a new chip based on matched choice label
      this._updateChipsAndValue(matchedChoice);
    } else {
      // Updates form element value based on matched choice label
      this.formElement!.value = matchedChoice.label;
    }

    if (this.suggest) {
      // Updates component value based on form element value
      this._setValue(this.formElement!.value as string);
    } else {
      // Updates Selected Choices
      this.selectedChoices = this.singleSelect
        ? [matchedChoice]
        : [...this.selectedChoices, matchedChoice];
    }

    this._tempSelectedItem = undefined;
  }

  /**
   * (Override) Updates chips and value
   *
   * Adds a new chip based on label and custom chip props,
   * also adds listeners to the chip in order to display a
   * popover with person's info.
   */
  protected _updateChipsAndValue(choice: Choice | string) {
    const {
      value,
      entry: {
        content: { EMAIL },
      },
    } = choice as Choice;
    if (this.singleSelect && this.inputChips) {
      this.inputChips.clear();
    }

    const chip = super._updateChipsAndValue(choice);
    const _openPopover = async () => {
      const _hasDataChanged = !this.popoverData || this.popoverData!.value !== value;
      if (!choice) {
        return; // Return early
      }
      this.popoverData = choice as Choice;
      if (_hasDataChanged) {
        await this.requestUpdate();
      }
      await chip.updateComplete;
      if (this.popover) {
        this.popover.setAnchorElement(chip['mdcRoot']);
        this.popover.open();
      }
    };
    const _closePopover = () => {
      if (this.popover) {
        this.popover.close();
      }
    };
    this._addChipImageSrc(chip, EMAIL);

    chip.addEventListener('mouseover', _openPopover);
    chip.addEventListener('mouseout', _closePopover);
    chip.addEventListener('click', () => {
      try {
        navigator.clipboard.writeText(EMAIL).catch(err => console.error(err));
      } catch (e) {
        console.error(e);
      }
    });

    chip.addEventListener('removal', _closePopover);
    return chip;
  }

  /**
   * Adds image source to chip's avatar
   *
   * Loads an image based on person's email, and
   * updates chip's avatar with it.
   */
  protected _addChipImageSrc(chip: GSKChip, email: string) {
    const imageSrc = this._getAvatarImageURL(email);

    if (imageSrc) {
      const img = new Image();

      img.onload = () => {
        chip.avatar = imageSrc;
      };

      img.onerror = () => {
        // throw new Error('Image load failed');
      };

      img.src = imageSrc;
    }
  }

  /**
   * (Override) Gets chip props
   *
   * Obtains custom chip props based on choice label and value.
   */
  protected _getChipProps(choice: Choice | string) {
    const { label } = choice as Choice;

    return {
      ...super._getChipProps(choice),
      avatar: this._getInitials(label),
    };
  }

  /**
   * Returns initials
   *
   * Returned string is based on up to two initials from text.
   */
  protected _getInitials(text: string): string {
    return text
      .split(' ')
      .map(t => t[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  }

  /**
   * Returns avatar color
   *
   * Returned color uses avatar colors list, and is based on person's reference.
   */
  protected _getAvatarColor(reference: string) {
    let hash = 0;

    if (reference.length === 0) {
      return this.avatarColors[0];
    }

    for (let i = 0; i < reference.length; i += 1) {
      hash = (hash << 5) - hash + reference.charCodeAt(i);
      hash &= hash;
    }
    return this.avatarColors[Math.abs(hash % this.avatarColors.length)];
  }

  /**
   * Returns avatar text color
   *
   * Returned color is based on avatar color contrast,
   * in order to improve readability.
   */
  protected _getAvatarTextColor(color: string) {
    const CODE = color.substring(1);
    const RGB = parseInt(CODE, 16);
    const RED = (RGB >> 16) & 0xff;
    const GREEN = (RGB >> 8) & 0xff;
    const BLUE = (RGB >> 0) & 0xff;

    const luma = 0.2126 * RED + 0.7152 * GREEN + 0.0722 * BLUE; // per ITU-R BT.709

    return luma > 40 ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .65)';
  }
}

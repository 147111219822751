/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PropertyPart, noChange } from 'lit';
import { directive, Directive, DirectiveParameters, PartInfo, PartType } from 'lit/directive.js';

import { SpecificEventListener } from '@gsk-tech/gsk-base/base-element';
import MDCRippleFoundation from '@material/ripple/foundation';
import { MDCRippleAdapter } from '@material/ripple/adapter';
import * as util from '@material/ripple/util';
import { matches } from '@material/dom/ponyfill';

const supportsCssVariables = util.supportsCssVariables(window);

type Handler = EventListenerOrEventListenerObject;

export interface RippleOptions {
  adapter?: any;
  interactionNode?: HTMLElement;
  unbounded?: boolean;
  disabled?: boolean;
  active?: boolean;
}

export interface RippleNodeOptions extends RippleOptions {
  surfaceNode: HTMLElement;
}

/**
 * Applied a ripple to the node specified by {surfaceNode}.
 * @param options {RippleNodeOptions}
 */
export const rippleNode = (options: RippleNodeOptions) => {
  // TODO(sorvell): This directive requires bringing css yourself. We probably need to do this
  // because of ShadyCSS, but on Safari, the keyframes styling must be global. Perhaps this
  // directive could fix that.
  const surfaceNode = options.surfaceNode;
  const interactionNode = options.interactionNode || surfaceNode;
  // only style interaction node if not in the same root
  if (interactionNode.getRootNode() !== surfaceNode.getRootNode()) {
    if (interactionNode.style.position === '') {
      interactionNode.style.position = 'relative';
    }
  }
  const adapter: MDCRippleAdapter = {
    browserSupportsCssVars: () => supportsCssVariables,
    isUnbounded: () =>
      options.unbounded === undefined ? true : options.unbounded,
    isSurfaceActive: () => matches(interactionNode, ':active'),
    isSurfaceDisabled: () => Boolean(options.disabled),
    addClass: (className: string) => surfaceNode.classList.add(className),
    removeClass: (className: string) =>
      surfaceNode.classList.remove(className),
    containsEventTarget: (target: HTMLElement) => interactionNode.contains(target),
    registerInteractionHandler: (type: string, handler: Handler) =>
      interactionNode.addEventListener(type, handler, util.applyPassive()),
    deregisterInteractionHandler: (type: string, handler: Handler) =>
      interactionNode.removeEventListener(type, handler, util.applyPassive()),
    registerDocumentInteractionHandler: (evtType: string, handler: Handler) =>
      document.documentElement!.addEventListener(
        evtType, handler, util.applyPassive()),
    deregisterDocumentInteractionHandler: (evtType: string, handler: Handler) =>
      document.documentElement!.removeEventListener(
        evtType, handler, util.applyPassive()),
    registerResizeHandler: (handler: SpecificEventListener<'resize'>) =>
      window.addEventListener('resize', handler),
    deregisterResizeHandler: (handler: SpecificEventListener<'resize'>) =>
      window.removeEventListener('resize', handler),
    updateCssVariable: (varName: string, value: string) =>
      surfaceNode.style.setProperty(varName, value),
    computeBoundingRect: () => surfaceNode.getBoundingClientRect(),
    getWindowPageOffset: () => ({ x: window.pageXOffset, y: window.pageYOffset }),
    ...options.adapter
  };
  const rippleFoundation = new MDCRippleFoundation(adapter);
  rippleFoundation.init();
  return rippleFoundation;
}

const rippleInteractionNodes = new WeakMap();

class RippleDirective extends Directive {
  private foundation: MDCRippleFoundation | null = null;
  private previousPart: PropertyPart | null = null;

  constructor(partInfo: PartInfo) {
    super(partInfo);

    switch (partInfo.type) {
      // Only allow Attribute and Part bindings
      case PartType.ATTRIBUTE:
      case PartType.PROPERTY:
        break;
      default:
        throw new Error(
          'Ripple directive only supports attribute and property parts');
    }
  }

  override update(part: PropertyPart, [options]: DirectiveParameters<this>) {
    let rippleFoundation: null | typeof noChange | MDCRippleFoundation = this.foundation; // (part.value as any);

    if (part !== this.previousPart) {
      if (this.foundation) {
        this.foundation.destroy();
        rippleFoundation = noChange;
      }
      this.previousPart = part;
    }

    const surfaceNode = part.element as HTMLElement;
    const interactionNode = options.interactionNode || surfaceNode;

    // make the ripple, if needed
    if (rippleFoundation === noChange || rippleFoundation === null) {
      rippleFoundation = rippleNode(Object.assign({}, options, { surfaceNode }));
      rippleInteractionNodes.set(rippleFoundation, interactionNode);
      // otherwise update settings as needed.
    } else {
      if (options.unbounded !== undefined) {
        rippleFoundation.setUnbounded(options.unbounded);
      }
      if (options.disabled !== undefined) {
        rippleFoundation.setUnbounded(options.disabled);
      }
    }

    if (options.active === true) {
      rippleFoundation.activate();
    } else if (options.active === false) {
      rippleFoundation.deactivate();
    }

    return rippleFoundation;
  }

  render(_options: RippleOptions) {
    return this.foundation;
  }
}


export const ripple = directive(RippleDirective)

import {
    unsafeCSS,
    CSSResult,
    LitElement,
    TemplateResult,
    html
  } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
  // eslint-disable-next-line import/no-unresolved
  import { gskStyle } from './gsk-badge-css';

  export class BadgeBase extends LitElement {
    public static get styles(): CSSResult {
      return unsafeCSS(gskStyle.cssText);
    }

    @property({ type: String })
    public type: 'info' | 'light' | 'success' | 'warning' | 'danger' = 'info';
  
    @property({ type: String })
    public label: string = '';
    
    @property({ type: Boolean })
    public dot: boolean=false;

    protected render(): TemplateResult | void {

       const variant = this.label.length==2?'medium': this.label.length>2?'extended':'';

        let classes={'gsk-badge':!this.dot, 
        'gsk-dot':this.dot,
        'gsk-medium':variant==='medium',
        'gsk-extended':variant==='extended',
        [`${this.type}`]:true};
       
        return html`
            <span class="${classMap(classes)}">
                ${this.dot?'':this.label}
            </span>
        `;
      }
    
}
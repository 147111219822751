import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { IconBase } from './gsk-icon-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-icon': Icon;
  }
}

@customElementDefinition('gsk-icon')
export class Icon extends IconBase {}

import { property, query, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { FabBase as MWCFab } from './mwc-fab-base';
import { gskStyle as mwcStyle } from './mwc-fab-css';
import { gskStyle } from './gsk-fab-css';

export class FabBase extends MWCFab {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  /**
   * Optional. Default value is false. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = false;

  /**
   * Optional. Default value is false. Use to sets the inverted color to be used on dark backgrounds
   */
  @property({ type: Boolean })
  public inverted = false;

  /**
   * Root element for gsk-fab component. This root element is use for MDC Foundation usage
   */
  @query('.mdc-fab')
  public mdcRoot!: HTMLElement;

  /**
   * This method is invoked whenever the gsk-fab is updated
   * @param _changedProperties Map of changed properties with old values
   */
  public updated(_changedProperties): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('primary')) {
      this.mdcRoot.classList.toggle('mdc-fab--primary', this.primary);
    }

    if (_changedProperties.has('inverted')) {
      this.mdcRoot.classList.toggle('mdc-fab--inverted', this.inverted);
    }
  }
}

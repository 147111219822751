import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { FormfieldBase } from './gsk-formfield-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-formfield': Formfield;
  }
}

@customElementDefinition('gsk-formfield')
export class Formfield extends FormfieldBase {}

import { customElementDefinition, ensureCustomElementLoaded } from '@gsk-tech/gsk-base/utils';
import { TextFieldBase } from './gsk-textfield-base';
import { IconBase } from '@gsk-tech/gsk-icon/gsk-icon-base';
import { TooltipBase } from '@gsk-tech/gsk-tooltip/gsk-tooltip-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-textfield': TextField;
  }
}

@ensureCustomElementLoaded('gsk-icon', 'gsk-autocomplete')
export class Icon extends IconBase {}

@ensureCustomElementLoaded('gsk-tooltip', 'gsk-autocomplete')
export class Tooltip extends TooltipBase {}

// Define Element
@customElementDefinition('gsk-textfield')
export class TextField extends TextFieldBase {}

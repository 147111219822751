import { property, query, unsafeCSS } from '@gsk-tech/gsk-base/base-element';
import { SliderBase as MWCSlider } from './mwc-slider-base';
import { gskStyle as mwcStyle } from './mwc-slider-css';
import { gskStyle } from './gsk-slider-css';

export class SliderBase extends MWCSlider {
  public static get styles() {
    return unsafeCSS(mwcStyle.cssText + gskStyle.cssText);
  }

  @query('.mdc-slider__pin-value-marker')
  protected pinMarker!: HTMLElement;

  /**
   * Optional. Setter/getter for the slider's name
   */
  @property({ type: String })
  public name = '';

  /**
   * Optional. Default value is false. Removes the ripple effect on the Slider.
   */
  @property({ type: Boolean })
  public noripple = false;

  /**
   * Optional. Default value is false. Use to sets the primary color
   */
  @property({ type: Boolean })
  public primary = false;

  /**
   * Optional. Default value is true. Use to sets the secondary color
   */
  @property({ type: Boolean })
  public secondary = true;

  /**
   * An instance method use to set the initial values for gsk-slider
   */
  public constructor() {
    super();
  }

  /**
   * Invoked whenever the element is updated. Implement to perform post-updating tasks via DOM APIs, for example, focusing an element.
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   *
   * @param _changedProperties Map of changed properties with old values
   */
  protected updated(changedProperties): void {
    super.updated(changedProperties);

    if (changedProperties.has('secondary') && !this.primary) {
      this.mdcRoot.classList.toggle('mdc-slider--secondary', this.secondary);
    }

    if (changedProperties.has('noripple')) {
      this.mdcRoot.classList.toggle('mdc-slider--no-ripple', this.noripple);
    }
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  public firstUpdated() {
    super.firstUpdated();

    if (this.discrete) {
      this.pinMarker.innerText = this.value.toString();
    }
  }
}

import { ensureCustomElementLoaded, customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { CircularProgressBase } from '@gsk-tech/gsk-circular-progress/gsk-circular-progress-base';
import { PopoverBase } from '@gsk-tech/gsk-popover/gsk-popover-base';
import { InputChipsBase } from '@gsk-tech/gsk-input-chips/gsk-input-chips-base';
import { PeoplePickerBase } from './gsk-people-picker-base';
import { MenuBase } from '@gsk-tech/gsk-menu/gsk-menu-base';
import { IconBase } from '@gsk-tech/gsk-icon/gsk-icon-base';
import { TooltipBase } from '@gsk-tech/gsk-tooltip/gsk-tooltip-base';
import { ListItemBase } from '@gsk-tech/gsk-list/gsk-list-base';
import { ListDividerBase } from '@gsk-tech/gsk-list/mwc-list-divider-base';
import { ListBase } from '@gsk-tech/gsk-list/mwc-list-base';
import { ChipBase } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { ChipSetBase } from '@gsk-tech/gsk-chip-set/gsk-chip-set-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-people-picker': PeoplePicker;
  }
}

@ensureCustomElementLoaded('gsk-popover', 'gsk-people-picker')
export class Popover extends PopoverBase {}

@ensureCustomElementLoaded('gsk-menu', 'gsk-people-picker')
export class Menu extends MenuBase {}

@ensureCustomElementLoaded('gsk-circular-progress', 'gsk-people-picker')
export class CircularProgress extends CircularProgressBase {}

@ensureCustomElementLoaded('gsk-icon', 'gsk-people-picker')
export class Icon extends IconBase {}

@ensureCustomElementLoaded('gsk-tooltip', 'gsk-people-picker')
export class Tooltip extends TooltipBase {}

@ensureCustomElementLoaded('gsk-chip', 'gsk-people-picker')
export class Chip extends ChipBase {}

@ensureCustomElementLoaded('gsk-chip-set', 'gsk-people-picker')
export class ChipSet extends ChipSetBase {}

@ensureCustomElementLoaded('gsk-input-chips', 'gsk-people-picker')
export class InputChips extends InputChipsBase {}

@ensureCustomElementLoaded('gsk-list-item', 'gsk-people-picker')
export class ListItem extends ListItemBase {}

@ensureCustomElementLoaded('gsk-list-divider', 'gsk-people-picker')
export class ListDivider extends ListDividerBase {}

@ensureCustomElementLoaded('gsk-list', 'gsk-people-picker')
export class List extends ListBase {}


@customElementDefinition('gsk-people-picker')
export class PeoplePicker extends PeoplePickerBase {}

/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  LitElement,
  query,
  classMap,
  findAssignedElements,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import { ListBase as MWCList } from './mwc-list-base';

export class ListGroupBase extends LitElement {

  @query('.mdc-list-group')
  protected mdcRoot!: HTMLElement;

  @query('slot')
  protected slotEl!: HTMLSlotElement;

  protected get listElements(): MWCList[] {
    return this.slotEl && findAssignedElements(this.slotEl, 'gsk-mwc-list') as MWCList[];
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const classes = {
      'mdc-list-group': true,
    };

    return html`
      <div class="${classMap(classes)}">
        <slot></slot>
      </div>
    `;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  firstUpdated() {
    this.listElements.forEach(item => item.group = true);
  }
}

import {
  html,
  classMap,
  observer,
  property,
  query,
  unsafeCSS,
  TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import { MDCTextFieldAdapter } from '@material/textfield/adapter';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';
import { TextFieldBase as GSKTextField } from '@gsk-tech/gsk-textfield/gsk-textfield-base';
import { ChipSetBase as GSKChipSet } from '@gsk-tech/gsk-chip-set/gsk-chip-set-base';
import { ChipBase as GSKChip } from '@gsk-tech/gsk-chips/gsk-chip-base';
import {
  ConstraintValidationModel,
  createValidityObj,
  CustomValidityState,
} from '@gsk-tech/gsk-base/constraint-validation';
import { gskStyle } from './gsk-input-chips-css';

const UNFORWARD_PROPS = [
  'pattern',
  'minLength',
  'maxLength',
  'min',
  'max',
  'step',
  'cols',
  'rows',
  'wrap',
];

export class InputChipsBase extends GSKTextField implements ConstraintValidationModel {
  public static get styles() {
    return unsafeCSS(GSKTextField.styles.cssText + gskStyle.cssText);
  }

  @query('input.mdc-text-field__input')
  protected formElement!: HTMLInputElement;

  @query('gsk-chip-set')
  protected chipSet!: GSKChipSet;

  @query('.mdc-text-field__hidden-input')
  protected hiddenFormElement!: HTMLInputElement;

  /**
   * Optional. Setter/getter for the input chips name
   */
  @property({ type: String })
  public name = '';

  /**
   * Optional. Default value is ','.
   * This property sets the character used to split the values entered in the component
   */
  @property({ type: String })
  public breakchar = ',';

  /**
   * Optional. Default value is false. This property is used to not allow add new chips
   */
  @property({ type: Boolean })
  public preventautoinput = false;

  /**
   * Optional. Use to sets the value of the input-chips
   */
  @property({ type: String, reflect: true })
  @observer(function(this: InputChipsBase, value: string) {
    const nextValue = this._normalizeValue(value);

    if (value !== nextValue) {
      this.value = nextValue;
      return;
    }

    this.chipsValue = value;

    if (this.mdcFoundation) {
      this._notifyChange();
    }

    if (this.mdcFoundation) {
      this.mdcFoundation.setValue(value);
    }
  })
  public value = '';

  /**
   * minimum number of chips required
   */
  @property({ type: Number })
  public minValues: number = 0;

  /**
   * maximum number of chips allowed
   */
  @property({ type: Number })
  public maxValues: number = Infinity;

  @property({ type: String })
  public pattern: string | undefined = undefined;

  /**
   * Get the input-chips values as an array
   */
  public get values(): string[] {
    return this.splitValue(this.value);
  }

  protected _notifyChange() {
    emit(this, 'change', { value: this.value, values: this.values }, true);
  }

  protected splitValue(value: InputChipsBase['value']): string[] {
    return value
      .split(this.breakchar)
      .map(item => item.trim())
      .filter(item => Boolean(item));
  }

  protected _chipsValue = '';

  /**
   * Get the chips value
   */
  protected get chipsValue() {
    return this._chipsValue;
  }

  /**
   * Set the chips value
   *
   * @parm chips value
   */
  protected set chipsValue(value: string) {
    if (this._chipsValue === value) return;

    this._chipsValue = value;

    this.chipSet.updateComplete.then(() => {
      this._removeChips();

      this._chipsValue
        .split(this.breakchar)
        .filter(item => Boolean(item))
        .forEach(label => this._addChip(label));
    });
  }

  /**
   * Gets the input value
   */
  public get inputValue() {
    return this.formElement?.value;
  }

  public get chips(): GSKChip[] {
    return this.chipSet ? this.chipSet.chips : [];
  }

  /**
   * Gets a boolean value to know if input-chips is focused or not
   */
  public get focused() {
    return this._isFocus;
  }

  protected _isUpdating = false;

  protected _currentLabel = '';

  // protected _handleChange = this._onChange.bind(this) as EventListenerOrEventListenerObject;

  protected _handleChipRemoval = this._onChipRemoval.bind(
    this,
  ) as EventListenerOrEventListenerObject;

  // protected _handleKeydown = this._onKeydown.bind(this) as EventListenerOrEventListenerObject;

  /**
   * Create the adapter for the `mdcFoundation`.
   *
   * Override and return an object with the Adapter's functions implemented
   */
  public createAdapter(): MDCTextFieldAdapter {
    return {
      ...super.createAdapter(),
      getNativeInput: () => this.hiddenFormElement,
    };
  }

  protected _renderCharacterCounter(): TemplateResult {
    return html`
      <div class="mdc-text-field-character-counter" style="display: none;"></div>
    `;
  }

  /**
   * Renders a lit-html TemplateResult for the input
   */
  protected _renderInput(): TemplateResult {
    const inputClasses = {
      'mdc-text-field__input': true,
      'mdc-text-field__input--with-chips': this.value !== '',
    };

    return html`
      <gsk-chip-set input class="mdc-text-field__input">
        <input
          type="hidden"
          class="mdc-text-field__hidden-input"
          .maxLength="${this.maxLength}"
          ?disabled="${this.disabled}"
          ?required="${this.required}"
          .value="${this.value}"
        />
        <input
          class="${classMap(inputClasses)}"
          id="form-element"
          type="${this.type}"
          placeholder="${!this.value ? this.placeholder : ''}"
          aria-label="${this.label}"
          ?required="${this.required}"
          ?disabled="${this.disabled}"
          @input="${this._onInput}"
          @focus="${this._handleInteractionEvent}"
          @blur="${this._onBlur}"
          @keydown="${this._onKeydown}"
        />
      </gsk-chip-set>
    `;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update again after this update cycle completes.
   */
  public async firstUpdated() {
    await super.firstUpdated();

    // yet another setTimeout
    setTimeout(() => {
      UNFORWARD_PROPS.forEach(prop => {
        this.formElement.removeAttribute(prop);
        delete this.formElement[prop];
      });
    });

    this.mdcFoundation.isValid = () => this.isUiValid;

    this.addEventListener('removal', this._handleChipRemoval, { passive: true });
  }

  protected removeTheLastValue(value: string) {
    value = value.substr(0, value.length - 1);
    return value;
  }

  /**
   * Handles keydown event
   */
  // eslint-disable-next-line consistent-return
  protected _onKeydown(evt) {
    const isBackspace = evt.key === 'Backspace' || evt.keyCode === 8;
    // const isTab = evt.key === 'Tab' || evt.keyCode === 9;
    const isEnter = evt.key === 'Enter' || evt.keyCode === 13;
    const focusedChip = this.chipSet.chips.find(item => (item as GSKChip).focused) as GSKChip;

    if (isEnter) {
      evt.preventDefault(); // stop form submission
      evt.cancelBubble = true;
      return this._onChange();
    }

    if (isBackspace) {
      evt.preventDefault();
      evt.cancelBubble = true;
      if (!focusedChip) {
        if (this.inputValue === '') {
          const lastChip = this.chipSet.chips[this.chipSet.chips.length - 1];

          if (lastChip) {
            evt.preventDefault();
            lastChip.forceExit();
            lastChip.focus();
          }
        } else {
          this.formElement.value = this.removeTheLastValue(this.formElement.value);
        }
      } else {
        evt.preventDefault();

        const hasPreviousChip = focusedChip.previousSibling instanceof GSKChip;

        if (hasPreviousChip) {
          const previousChip = focusedChip.previousSibling as GSKChip;
          previousChip.focus();
        } else {
          this.formElement.focus();
        }

        focusedChip.forceExit();
      }
    }
  }

  /**
   * Handles chip removal event
   */
  protected async _onChipRemoval() {
    const nextValue = this.chipSet.chips.map(item => item.label).join(',');
    this._chipsValue = this._normalizeValue(nextValue);
    this.value = nextValue;
    this.dirty = true;

    await this.updateComplete;
    this.reportValidity();
  }

  /**
   * Handle input event
   */
  protected async _onInput() {
    if (this.required) {
      this.dirty = true;
    }
  }

  /**
   * Handle blur event
   */
  protected _onBlur() {
    this._isFocus = false;
    this._onChange();
    this.dirty = true;
    this.reportValidity();
    this.requestUpdate();
  }

  /**
   * Handle change event
   */
  protected _onChange() {
    if (!this.preventautoinput) {
      const inputValue = this._normalizeValue(this.inputValue);
      const nextValue = this._normalizeValue(`${this.value}${this.breakchar}${inputValue}`);
      this._chipsValue = this._normalizeValue(nextValue);

      if (this.value !== nextValue) {
        this.value = nextValue;

        inputValue
          .split(this.breakchar)
          .filter(item => Boolean(item))
          .forEach(label => this._addChip(label));
      }
    }

    this.formElement.value = '';
  }

  /**
   * Returns a normalized value
   *
   */
  protected _normalizeValue(value: string): string {
    if (!value) return '';

    return value
      .split(this.breakchar)
      .map(item => item.trim())
      .filter(item => Boolean(item))
      .join(this.breakchar);
  }

  /**
   * Removes all the chips
   */
  protected _removeChips() {
    this.chipSet.chips.forEach(item => {
      item.updateComplete.then(() => item.remove());
    });
  }

  /**
   * Returns a new chip element with the given label and props
   *
   * @param label Chip label
   * @param props Chip props
   */
  protected _createChip(label: string, props: any = {}) {
    const chip = document.createElement('gsk-chip') as GSKChip;
    const chipProps = { trailingIcon: 'close', ...props };
    chip.label = label;
    chip.tabIndex = 0;

    Object.keys(chipProps).forEach(key => {
      chip[key] = chipProps[key];
    });

    return chip;
  }

  /**
   * Adds a new chip element with the given label and props
   *
   * @param label Chip label
   * @param props Chip props
   */
  protected _addChip(label: string, props: any = {}) {
    const chip = this._createChip(label, props);
    if (this.chipSet) {
      this.chipSet.addChip(chip);
    }
    return chip;
  }

  /**
   * Updates value and adds a new chip element with the given label and props
   *
   * @param label Chip label
   * @param props Chip props
   */
  public updateChipsAndValue(label: string, props: any = {}) {
    const nextValue = `${this.value},${label}`;
    this._chipsValue = this._normalizeValue(nextValue);
    this.value = nextValue;

    this.formElement.value = '';

    setTimeout(() => {
      this.reportValidity();
    });

    return this._addChip(label, props);
  }

  /**
   * Clears input value
   */
  public clearValue() {
    this.formElement.value = '';
  }

  /**
   * Clears all the chips
   */
  public clear() {
    if (this.chipSet) {
      this._chipsValue = '';
      this.value = '';

      this.chipSet.chips.forEach(item => item.remove());
    }
  }

  /**
   * Deactivates the Text Field's focus state.
   */
  public deactivateFocus() {
    this.mdcFoundation.deactivateFocus();
  }

  public setCustomValidity(message: string): void {
    this.validationMessage = message;
    const isError = message !== '';
    this._validity = createValidityObj({
      customError: isError,
    });
    this.dirty = true;
    this.formElement.setCustomValidity(message);
    this.reportValidity();
  }

  protected _checkValidity(value: string): boolean {
    const v = this.splitValue(value);
    const isCustomError = this._validity.customError;
    const nativeValidity: CustomValidityState = createValidityObj({
      customError: isCustomError,
      valid: !isCustomError,
    });
    let valid = true;
    if (this.required && valid) {
      if (v.length < 1) {
        nativeValidity.valueMissing = true;
        valid = false;
      }
    }
    if (this.minValues !== undefined && valid) {
      if (v.length && v.length < this.minValues) {
        nativeValidity.rangeUnderflow = true;
        valid = false;
      }
    }
    if (this.maxValues !== undefined && valid) {
      if (v.length > this.maxValues) {
        nativeValidity.rangeOverflow = true;
        valid = false;
      }
    }
    if (this.minLength !== undefined && valid) {
      const someInvalid = v.some(val => val.length < this.minLength);
      if (someInvalid) {
        nativeValidity.tooShort = true;
        valid = false;
      }
    }
    if (this.maxLength !== undefined && valid) {
      const someInvalid = v.some(val => val.length > this.maxLength);
      if (someInvalid) {
        nativeValidity.tooLong = true;
        valid = false;
      }
    }
    if (this.pattern !== undefined && valid) {
      const pattern = new RegExp(this.pattern);
      const someInvalid = v.some(val => !pattern.test(val));
      if (someInvalid) {
        nativeValidity.patternMismatch = true;
        valid = false;
      }
    }
    nativeValidity.valid = nativeValidity.valid && valid;

    let validity = nativeValidity;
    if (this.validityTransform) {
      const transformedValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...transformedValidity };
      this.mdcFoundation.setUseNativeValidation(false);
    } else if (!this._userValiditySet) {
      this.mdcFoundation.setUseNativeValidation(true);
    }

    this._validity = validity;
    return this._validity.valid;
  }
}

import { customElementDefinition, ensureCustomElementLoaded } from '@gsk-tech/gsk-base/utils';
import { FileUploadBase } from './gsk-file-upload-base';
import { ButtonBase } from '@gsk-tech/gsk-button/gsk-button-base';
import { IconButtonBase } from '@gsk-tech/gsk-icon-button/gsk-icon-button-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-file-upload': FileUpload;
  }
}

@ensureCustomElementLoaded('gsk-button', 'gsk-file-upload')
export class Button extends ButtonBase {}

@ensureCustomElementLoaded('gsk-icon-button', 'gsk-file-upload')
export class IconButton extends IconButtonBase {}

@customElementDefinition('gsk-file-upload')
export default class FileUpload extends FileUploadBase {}

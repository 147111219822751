import { customElementDefinition, ensureCustomElementLoaded } from '@gsk-tech/gsk-base/utils';
import { AutocompleteBase } from './gsk-autocomplete-base';
import { MenuBase } from '@gsk-tech/gsk-menu/gsk-menu-base';
import { IconBase } from '@gsk-tech/gsk-icon/gsk-icon-base';
import { TextFieldBase } from '@gsk-tech/gsk-textfield/gsk-textfield-base';
import { InputChipsBase } from '@gsk-tech/gsk-input-chips/gsk-input-chips-base';
import { TooltipBase } from '@gsk-tech/gsk-tooltip/gsk-tooltip-base';
import { ListItemBase } from '@gsk-tech/gsk-list/gsk-list-base';
import { ListDividerBase } from '@gsk-tech/gsk-list/mwc-list-divider-base';
import { ListBase } from '@gsk-tech/gsk-list/mwc-list-base';
import { ChipBase } from '@gsk-tech/gsk-chips/gsk-chip-base';
import { ChipSetBase } from '@gsk-tech/gsk-chip-set/gsk-chip-set-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-autocomplete': Autocomplete;
  }
}

@ensureCustomElementLoaded('gsk-menu', 'gsk-autocomplete')
export class Menu extends MenuBase {}

@ensureCustomElementLoaded('gsk-icon', 'gsk-autocomplete')
export class Icon extends IconBase {}

@ensureCustomElementLoaded('gsk-tooltip', 'gsk-autocomplete')
export class Tooltip extends TooltipBase {}

@ensureCustomElementLoaded('gsk-textfield', 'gsk-autocomplete')
export class TextField extends TextFieldBase {}

@ensureCustomElementLoaded('gsk-chip', 'gsk-autocomplete')
export class Chip extends ChipBase {}

@ensureCustomElementLoaded('gsk-chip-set', 'gsk-autocomplete')
export class ChipSet extends ChipSetBase {}

@ensureCustomElementLoaded('gsk-input-chips', 'gsk-autocomplete')
export class InputChips extends InputChipsBase {}

@ensureCustomElementLoaded('gsk-list-item', 'gsk-autocomplete')
export class ListItem extends ListItemBase {}

@ensureCustomElementLoaded('gsk-list-divider', 'gsk-autocomplete')
export class ListDivider extends ListDividerBase {}

@ensureCustomElementLoaded('gsk-list', 'gsk-autocomplete')
export class List extends ListBase {}


@customElementDefinition('gsk-autocomplete')
export class Autocomplete extends AutocompleteBase {}

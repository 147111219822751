/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  FormElement,
  query,
  addHasRemoveClass,
  RippleSurface,
  HTMLElementWithRipple,
  queryAll,
  classMap,
  property,
  observer,
  html,
  PropertyValues,
  TemplateResult,
} from '@gsk-tech/gsk-base/form-element';
import { eventOptions } from 'lit/decorators.js';
import { MDCFloatingLabel, MDCFloatingLabelFactory } from '@material/floating-label';
import { cssClasses as floatingLabelCssClasses } from '@material/floating-label/constants';
import { MDCLineRipple, MDCLineRippleFactory } from '@material/line-ripple';
import { MDCNotchedOutline, MDCNotchedOutlineFactory } from '@material/notched-outline';
import {
  MDCTextFieldAdapter,
  MDCTextFieldInputAdapter,
  MDCTextFieldLabelAdapter,
  MDCTextFieldLineRippleAdapter,
  MDCTextFieldOutlineAdapter,
  MDCTextFieldRootAdapter,
} from '@material/textfield/adapter';
import {
  MDCTextFieldCharacterCounter,
  MDCTextFieldCharacterCounterFactory,
  MDCTextFieldCharacterCounterFoundation,
} from '@material/textfield/character-counter';
import { cssClasses, strings } from '@material/textfield/constants';
import { MDCTextFieldFoundation } from '@material/textfield/foundation';
import {
  MDCTextFieldHelperText,
  MDCTextFieldHelperTextFactory,
  MDCTextFieldHelperTextFoundation,
} from '@material/textfield/helper-text';
import { MDCTextFieldIcon, MDCTextFieldIconFactory } from '@material/textfield/icon';
import { MDCTextFieldFoundationMap } from '@material/textfield/types';
import { ripple } from '@gsk-tech/gsk-ripple/ripple-directive';
import { emit } from '@gsk-tech/gsk-base/utils_mwc';

const lineRippleFactory: MDCLineRippleFactory = el => new MDCLineRipple(el);
const helperTextFactory: MDCTextFieldHelperTextFactory = el => new MDCTextFieldHelperText(el);
const characterCounterFactory: MDCTextFieldCharacterCounterFactory = el =>
  new MDCTextFieldCharacterCounter(el);
const iconFactory: MDCTextFieldIconFactory = el => new MDCTextFieldIcon(el);
const labelFactory: MDCFloatingLabelFactory = el => new MDCFloatingLabel(el);
const outlineFactory: MDCNotchedOutlineFactory = el => new MDCNotchedOutline(el);

const INPUT_PROPS = [
  'pattern',
  'minLength',
  'maxLength',
  'min',
  'max',
  'step',
  'cols',
  'rows',
  'wrap',
];

type CustomValidityState = { -readonly [P in keyof ValidityState]: ValidityState[P] };

const createValidityObj = (customValidity: Partial<ValidityState> = {}): ValidityState => {
  /*
   * We need to make ValidityState an object because it is readonly and
   * we cannot use the spread operator. Also, we don't export
   * `CustomValidityState` because it is a leaky implementation and the user
   * already has access to `ValidityState` in lib.dom.ts. Also an interface
   * {a: Type} can be casted to {readonly a: Type} so passing any object
   * should be fine.
   */
  const objectifiedCustomValidity: Partial<CustomValidityState> = {};

  // eslint-disable-next-line guard-for-in
  for (const propName in customValidity) {
    /*
     * Casting is needed because ValidityState's props are all readonly and
     * thus cannot be set on `onjectifiedCustomValidity`. In the end, the
     * interface is the same as ValidityState (but not readonly), but the
     * function signature casts the output to ValidityState (thus readonly).
     */
    objectifiedCustomValidity[propName as keyof CustomValidityState] =
      customValidity[propName as keyof ValidityState];
  }

  return {
    badInput: false,
    customError: false,
    patternMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valid: true,
    valueMissing: false,
    ...objectifiedCustomValidity,
  };
};

export const EVENTS = {
  trailingIconInteraction: 'trailingiconinteraction',
};

export class TextFieldBase extends FormElement {
  @query('.mdc-text-field')
  protected mdcRoot!: HTMLElementWithRipple;

  @query(strings.INPUT_SELECTOR)
  protected formElement!: HTMLInputElement;

  @query(strings.LABEL_SELECTOR)
  protected labelElement!: HTMLLabelElement;

  @query(strings.LINE_RIPPLE_SELECTOR)
  protected lineRippleElement!: HTMLElement;

  @query(strings.OUTLINE_SELECTOR)
  protected outlineElement!: HTMLElement;

  @query(`.${cssClasses.HELPER_LINE}`)
  protected helperLine!: HTMLElement;

  @queryAll(strings.ICON_SELECTOR)
  protected iconElements!: HTMLElement[];

  /**
   * Optional. Setter/getter for the input control's value.
   */
  @property({ type: String, reflect: true })
  @observer(function(this: TextFieldBase, value: string) {
    this.processInputValue(value);
  })
  public value = '';

  /**
   * Optional. Default value is set to false. Use this property to whether or not the input should be disabled.
   */
  @property({ type: Boolean, reflect: true })
  @observer(function(this: TextFieldBase, value: boolean): void {
    this.mdcFoundation && this.mdcFoundation.setDisabled(value);
  })
  public disabled?: boolean;

  /**
   * Optional. Default value is 'text'. Use this property to specifying the type of control to render.
   */
  @property({ type: String, reflect: true })
  public type = 'text';

  /**
   * Optional. Sets floating label value.
   */
  @property({ type: String })
  public label = '';

  /**
   * Optional. Sets floating aria-label value.
   */
  @property({ type: String })
  public arialabel = '';

  /**
   * Optional. Sets disappearing input placeholder.
   */
  @property({ type: String })
  public placeholder = '';

  /**
   * Optional. Default value is set to false.
   * Whether or not to make the input fullwidth. No longer displays label; only placeholder and helper.
   */
  @property({ type: Boolean })
  public fullWidth = false;

  /**
   * Optional. Sets floating label value.
   */
   @property({ type: Boolean })
   public defaultlabeltype = false;
   
   

  /**
   * Optional. Default value is set to false. Whether or not to show the outlined variant.
   */
  @property({ type: Boolean })
  public outlined = !this.defaultlabeltype? true: false;

  /**
   * Optional. Helper text to display below the input. Display default only when focused.
   */
  @property({ type: String })
  public helperTextContent = '';

  /**
   * Optional. Message to show in the error color when the textfield is invalid. (Helper text will not be visible)
   */
  @property({ type: String })
  public validationMessage = '';

  /**
   * Optional. Default value is set to false. Always show the helper text despite focus.
   */
  @property({ type: Boolean })
  @observer(function(this: TextFieldBase, value: boolean) {
    this._helperText && this._helperText.foundation.setPersistent(value);
  })
  public persistentHelperText = false;

  /**
   * Optional. Use this property to sets the value for the aria-label text related to the leading icon
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    this.mdcFoundation && this.mdcFoundation.setLeadingIconAriaLabel(value);
  })
  public leadingIconAriaLabel = '';

  /**
   * Optional. This property help us to set the value for the aria-label text related to the trailing icon
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    this.mdcFoundation && this.mdcFoundation.setTrailingIconAriaLabel(value);
  })
  public trailingIconAriaLabel = '';

  /**
   * Optional. Used to sets the leading icon name to display in the input
   */
  @property({ type: String })
  public leadingIconContent = '';

  /**
   * Optional. Used to sets the trailing icon name to display in the input
   */
  @property({ type: String })
  public trailingIconContent = '';

  /**
   * Optional. Default value is set to false. Use this property to enable the interaction with the trailing icon
   */
  @property({ type: Boolean })
  public trailingIconInteraction = false;

  /**
   * Optional. Default value is set to false. Displays error state if value is empty and input is blurred.
   */
  @property({ type: Boolean })
  @observer(function(this: TextFieldBase, value: boolean) {
    if (!this.formElement) return;
    this.formElement.required = value;
  })
  public required = false;

  /**
   * Optional. Use this property to sets an HTMLInputElement.prototype.pattern (empty string will unset attribute)
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (!this.formElement) return;
    this.formElement.pattern = value;
  })
  public pattern;

  /**
   * Optional. Specifies a minimum length to be accepted in the input
   */
  @property({ type: Number })
  @observer(function(this: TextFieldBase, value: number) {
    if (!this.formElement) return;
    this.formElement.minLength = value;
  })
  public minLength;

  /**
   * Optional. Specifies a maximum length to accept input.
   */
  @property({ type: Number })
  @observer(function(this: TextFieldBase, value: number) {
    if (!this.formElement) return;

    if (!value || value < 0) {
      this.formElement.removeAttribute('maxLength');
    } else {
      this.formElement.maxLength = value;
    }
  })
  public maxLength;

  /**
   * Optional. Specifies a minimum value for number and date input
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (!this.formElement) return;
    this.formElement.min = value;
  })
  public min;

  /**
   * Optional. Specifies a maximum value for number and date input
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (!this.formElement) return;
    this.formElement.max = value;
  })
  public max;

  /**
   * Optional. Specifies the interval between valid values in a number-based input
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (!this.formElement) return;
    this.formElement.step = value;
  })
  public step;

  /**
   * Optional. Use this property along with 'type=textarea'. This property specifies the visible width of a text area
   */
  @property({ type: Number })
  @observer(function(this: TextFieldBase, value: number) {
    if (!this.formElement) return;
    (this.formElement as Partial<HTMLTextAreaElement>).cols = value;
  })
  public cols;

  /**
   * Optional. Use this property along with 'type=textarea'. This property specifies the visible number of lines in a text area
   */
  @property({ type: Number })
  @observer(function(this: TextFieldBase, value: number) {
    if (!this.formElement) return;
    (this.formElement as Partial<HTMLTextAreaElement>).rows = value;
  })
  public rows;

  /**
   * Optional. Use this property along with 'type=textarea'. This property specifies how the text in a text area is to be wrapped when submitted in a form
   */
  @property({ type: String })
  @observer(function(this: TextFieldBase, value: string) {
    if (!this.formElement) return;
    (this.formElement as Partial<HTMLTextAreaElement>).wrap = value;
  })
  public wrap;

  /**
   * Optional. Default value is set to false. Use this property to display the floating label.
   */
  @property({ type: Boolean })
  public get floatLabel(): boolean {
    return this.defaultlabeltype? true: false;
  }

  public set floatLabel(_) {
    // noop
  }

  private _isUiValid: boolean = true;

  @property({ type: Boolean })
  protected get isUiValid(): boolean {
    return this._isUiValid;
  }

  protected set isUiValid(_isUiValid) {
    const oldValue = this._isUiValid;
    this._isUiValid = _isUiValid;
    this.requestUpdate('isUiValid', oldValue);
  }

  @property({ type: Boolean }) validateOnInitialRender = false;

  protected _validity: ValidityState = createValidityObj();

  get validity(): ValidityState {
    this._checkValidity(this.value);

    return this._validity;
  }

  get willValidate(): boolean {
    return this.formElement.willValidate;
  }

  get selectionStart(): number | null {
    return this.formElement.selectionStart;
  }

  get selectionEnd(): number | null {
    return this.formElement.selectionEnd;
  }

  public dirty: boolean = false;

  validityTransform:
    | ((value: string, nativeValidity: ValidityState) => Partial<ValidityState>)
    | null = null;

  /**
   * Focuses the input element.
   */
  focus() {
    const focusEvt = new CustomEvent('focus');
    this.formElement.dispatchEvent(focusEvt);
    this.formElement.focus();
  }

  /**
   * Removes focus the input element.
   */
  blur() {
    const blurEvt = new CustomEvent('blur');
    this.formElement.dispatchEvent(blurEvt);
    this.formElement.blur();
    this._isFocus = false;
  }

  /**
   * Selects the input element.
   */
  select() {
    this.formElement.select();
  }

  /**
   * sets selection range on the input element.
   */
  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection?: 'forward' | 'backward' | 'none',
  ) {
    this.formElement.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  protected _onInputBlur() {
    this._isFocus = false;
    this.dirty = true;
    // this.value = this.formElement.value;
    this.processInputValue(this.value);
    this.reportValidity();
  }

  processInputValue(value: string) {
    if (this.mdcFoundation) {
      if (!this._isFocus) {
        if (this._checkValidity(this.value)) {
          this.mdcFoundation.setValue(value);
          // ignore initial value
          if (this._previousValue !== undefined) {
            this._notifyChange();
          }
          this._previousValue = value;
        }
      }
      if (this.dirty) {
        // we only want to see errors if dirty (after blur event)
        this.mdcFoundation.setValue(value);
        this._setValidity(this.checkValidity()); // Update Validation Message
      } else {
        // Prevent errors from being raised by foundation class
        this.mdcFoundation.setUseNativeValidation(false);
        this.mdcFoundation.setValid(true);
        this.mdcFoundation.setValue(value);
      }
    }
  }

  checkValidity(): boolean {
    const isValid = this._checkValidity(this.value);

    if (!isValid) {
      const invalidEvent = new Event('invalid', { bubbles: false, cancelable: true });
      this.dispatchEvent(invalidEvent);
    }

    return isValid;
  }

  reportValidity(): boolean {
    const isValid = this.checkValidity();

    this.mdcFoundation.setValid(isValid);
    this.isUiValid = isValid;

    return isValid;
  }

  protected _checkValidity(value: string) {
    const nativeValidity = this.formElement.validity;

    let validity = createValidityObj(nativeValidity);

    if (this.validityTransform) {
      const customValidity = this.validityTransform(value, validity);
      validity = { ...validity, ...customValidity };
      this.mdcFoundation.setUseNativeValidation(false);
    } else {
      this.mdcFoundation.setUseNativeValidation(true);
    }

    this._validity = validity;

    return this._validity.valid;
  }

  setCustomValidity(message: string) {
    this.validationMessage = message;
    this.formElement.setCustomValidity(message);
  }

  updated(changedProperties: PropertyValues) {
    const maxLength = changedProperties.get('maxLength') as number | undefined;

    const maxLengthBecameDefined = maxLength === -1 && this.maxLength !== -1;
    const maxLengthBecameUndefined =
      maxLength !== undefined && maxLength !== -1 && this.maxLength === -1;

    /* We want to recreate the foundation if maxLength changes to defined or
     * undefined, because the textfield foundation needs to be instantiated with
     * the char counter's foundation, and the char counter's foundation needs
     * to have maxLength defined to be instantiated. Additionally, there is no
     * exposed API on the MdcTextFieldFoundation to dynamically add a char
     * counter foundation, so we must recreate it.
     */
    if (maxLengthBecameDefined || maxLengthBecameUndefined) {
      this.createFoundation();
    }
  }

  /**
   * Gets the valid state of the textfield
   */
  public get internalValidity() {
    return this.checkValidity();
  }

  /**
   * Sets the valid state of the textfield
   */
  public set internalValidity(isValid: boolean) {
    // this.mdcFoundation && this.mdcFoundation.setValid(isValid);
    this._setValidity(isValid);
  }

  /**
   * Ripple getter for Ripple integration
   */
  public get ripple(): RippleSurface | undefined {
    return this.mdcRoot.ripple;
  }

  /**
   * Enables or disables the use of native validation. Use this for custom validation.
   */
  public set useNativeValidation(value: boolean) {
    this.mdcFoundation.setUseNativeValidation(value);
  }

  protected _previousValue;

  protected _formElementId = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  protected _characterCounter!: MDCTextFieldCharacterCounter | null;

  protected _helperText!: MDCTextFieldHelperText | null;

  protected _label!: MDCFloatingLabel | null;

  protected _leadingIcon!: MDCTextFieldIcon | null;

  protected _lineRipple!: MDCLineRipple | null;

  protected _outline!: MDCNotchedOutline | null;

  protected _trailingIcon!: MDCTextFieldIcon | null;

  protected _isFocus!: Boolean;

  // protected _handleInput = this._onInput.bind(this) as EventListenerOrEventListenerObject;

  // protected _handleBlur = this._onInputBlur.bind(this) as EventListenerOrEventListenerObject;

  // protected _handleFocus = this._onFocus.bind(this) as EventListenerOrEventListenerObject;

  protected mdcFoundation!: MDCTextFieldFoundation;

  protected readonly mdcFoundationClass = MDCTextFieldFoundation;

  /**
   * Create the adapter of type 'MDCTextFieldAdapter'.
   * Override and return an object with the Adapter's functions implemented
   */
  createAdapter(): MDCTextFieldAdapter {
    return {
      ...this._getRootAdapterMethods(),
      ...this._getInputAdapterMethods(),
      ...this._getLabelAdapterMethods(),
      ...this._getLineRippleAdapterMethods(),
      ...this._getOutlineAdapterMethods(),
    };
  }

  protected _getRootAdapterMethods(): MDCTextFieldRootAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      registerTextFieldInteractionHandler: (evtType, handler) =>
        this.mdcRoot.addEventListener(evtType, handler),
      deregisterTextFieldInteractionHandler: (evtType, handler) =>
        this.mdcRoot.addEventListener(evtType, handler),
      registerValidationAttributeChangeHandler: handler => {
        const getAttributesList = (mutationsList: MutationRecord[]): string[] => {
          return mutationsList
            .map(mutation => mutation.attributeName)
            .filter(attributeName => attributeName) as string[];
        };

        const observer = new MutationObserver(mutationsList =>
          handler(getAttributesList(mutationsList)),
        );
        const config = { attributes: true };

        observer.observe(this.formElement, config);

        return observer;
      },
      deregisterValidationAttributeChangeHandler: observer => observer.disconnect(),
    };
  }

  protected _getInputAdapterMethods(): MDCTextFieldInputAdapter {
    return {
      getNativeInput: () => this.formElement,
      isFocused: () => {
        const activeElement = (this as any).getRootNode().activeElement;
        return activeElement === this.formElement;
      },
      registerInputInteractionHandler: (evtType, handler) =>
        this.formElement.addEventListener(evtType, handler, { passive: true }),
      deregisterInputInteractionHandler: (evtType, handler) =>
        this.formElement.removeEventListener(evtType, handler),
    };
  }

  protected _getLabelAdapterMethods(): MDCTextFieldLabelAdapter {
    return {
      floatLabel: shouldFloat => this._label && this._label.float(shouldFloat),
      getLabelWidth: () => (this._label ? this._label.getWidth() : 0),
      hasLabel: () => Boolean(this._label) && this.floatLabel,
      shakeLabel: shouldShake =>
        this._label &&
        this.labelElement.classList.contains(floatingLabelCssClasses.LABEL_FLOAT_ABOVE) &&
        this._label.shake(shouldShake),
    };
  }

  protected _getLineRippleAdapterMethods(): MDCTextFieldLineRippleAdapter {
    return {
      activateLineRipple: () => {
        if (this._lineRipple) {
          this._lineRipple.activate();
        }
      },
      deactivateLineRipple: () => {
        if (this._lineRipple) {
          this._lineRipple.deactivate();
        }
      },
      setLineRippleTransformOrigin: normalizedX => {
        if (this._lineRipple) {
          this._lineRipple.setRippleCenter(normalizedX);
        }
      },
    };
  }

  protected _getOutlineAdapterMethods(): MDCTextFieldOutlineAdapter {
    return {
      closeOutline: () => this._outline && this._outline.closeNotch(),
      hasOutline: () => Boolean(this._outline),
      notchOutline: labelWidth => this._outline && this._outline.notch(labelWidth),
    };
  }

  /**
   * Used to render the lit-html TemplateResult with the textfield's input
   */
  protected _renderInput(): TemplateResult {
    const isTextArea = this.type === 'textarea';

    return isTextArea
      ? html`
          <textarea
            id="${this._formElementId}"
            class="mdc-text-field__input"
            placeholder="${this.placeholder}"
            aria-label="${this.arialabel || this.label}"
            @focus="${evt => this._handleInteractionEvent(evt)}"
            @input="${this._handleInputChange}"
            @blur="${this._onInputBlur}"
          ></textarea>
        `
      : html`
          <input
            id="${this._formElementId}"
            class="mdc-text-field__input"
            type="${this.type}"
            placeholder="${this.placeholder}"
            aria-label="${this.arialabel || this.label}"
            @focus="${evt => this._handleInteractionEvent(evt)}"
            @input="${this._handleInputChange}"
            @blur="${this._onInputBlur}"
          />
        `;
  }

  /**
   * Used to render the lit-html TemplateResult with the textfield's floating label
   */
  protected _renderFloatingLabel() {
    return html`
      <label class="mdc-floating-label" for="${this._formElementId}">${this.label}</label>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the textfield's notched outline
   */
  protected _renderNotchedOutline(showAdjacentLabel: boolean | string) {
    const classes = {
      'mdc-notched-outline__notch': true,
      'mdc-notched-outline__notch--no-label': !this.label
    }
    return html`
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="${classMap(classes)}">
          ${!showAdjacentLabel ? this._renderFloatingLabel() : ''}
        </div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the line ripple
   */
  protected _renderLineRipple() {
    return html`
      <div class="mdc-line-ripple"></div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the icon
   *
   * @param variant Variant name. Values will be: 'leading' or 'trailing'
   */
  protected _renderIcon(variant: string) {
    const isTrailingIcon = variant === 'trailing';
    const isTrailingIconInteraction = isTrailingIcon && this.trailingIconInteraction;
    const iconContent = isTrailingIcon ? this.trailingIconContent : this.leadingIconContent;

    return html`
      <i
        class="material-icons mdc-text-field__icon mdc-text-field__icon--${variant}"
        tabindex="${isTrailingIconInteraction ? 0 : -1}"
      >
        ${iconContent}
      </i>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the helper line
   */
  protected _renderHelperLine() {
    const isTextarea = this.type === 'textarea';
    const hasCharacterCounter = this.maxLength && this.maxLength > 0;
    const hasHelperText = this.isUiValid
      ? this.helperTextContent
      : this.validationMessage || 'invalid';

    return hasCharacterCounter || hasHelperText
      ? html`
          <div class="mdc-text-field-helper-line">
            ${hasHelperText ? this._renderHelperText() : ''}
            ${hasCharacterCounter && !isTextarea ? this._renderCharacterCounter() : ''}
          </div>
        `
      : '';
  }

  /**
   * Used to render the lit-html TemplateResult with the helper text
   */
  protected _renderHelperText() {
    const classes = {
      'mdc-text-field-helper-text': true,
      'mdc-text-field-helper-text--persistent': this.persistentHelperText,
      'mdc-text-field-helper-text--validation-msg': !this.isUiValid,
    };
    const hasHelperText = this.isUiValid
      ? this.helperTextContent
      : this.validationMessage || 'invalid';

    return html`
      <div class="${classMap(classes)}">${hasHelperText}</div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the character counter
   */
  protected _renderCharacterCounter(): TemplateResult {
    return html`
      <div class="mdc-text-field-character-counter"></div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult with the adjacent label
   */
  protected _renderAdjacentLabel(): TemplateResult {
    const classes = {
      'mdc-floating-label--adjacent': true,
    };

    return html`
      <div class="${classMap(classes)}" for="${this._formElementId}">${this.label}</div>
    `;
  }

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  public render(): TemplateResult {
    const isTextarea = this.type === 'textarea';
    const hasOutline = this.outlined || isTextarea;
    const hasLabel = this.label && (!this.fullWidth || isTextarea);
    const hasLabelAndIsNotOutlined = hasLabel && !hasOutline;
    const hasLeadingIcon = this.leadingIconContent;
    const hasTrailingIcon = this.trailingIconContent;
    const hasCharacterCounter = this.maxLength && this.maxLength > 0;
    const showAdjacentLabel = hasLabel && !this.floatLabel;
    const classes = {
      'mdc-text-field': true,
      'mdc-text-field--no-label': !hasLabel || showAdjacentLabel,
      'mdc-text-field--outlined': this.outlined,
      'mdc-text-field--textarea': this.type === 'textarea',
      'mdc-text-field--fullwidth': this.fullWidth,
      'mdc-text-field--disabled': Boolean(this.disabled),
      'mdc-text-field--with-leading-icon': hasLeadingIcon,
      'mdc-text-field--with-trailing-icon': hasTrailingIcon,
      'mdc-text-field--with-trailing-icon-interaction': this.trailingIconInteraction,
      'mdc-text-field--with-adjacent-label': showAdjacentLabel,
    };

    return html`
      <div class="${classMap(classes)}" .ripple="${!hasOutline && ripple({ unbounded: false })}">
        ${hasCharacterCounter && isTextarea ? this._renderCharacterCounter() : ''}
        ${hasLeadingIcon ? this._renderIcon('leading') : ''} ${this._renderInput()}
        ${!showAdjacentLabel && hasLabelAndIsNotOutlined ? this._renderFloatingLabel() : ''}
        ${hasTrailingIcon ? this._renderIcon('trailing') : ''}
        ${hasOutline ? this._renderNotchedOutline(showAdjacentLabel) : this._renderLineRipple()}
      </div>
      ${this._renderHelperLine()} ${showAdjacentLabel ? this._renderAdjacentLabel() : ''}
    `;
  }

  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   */
  protected firstUpdated() {
    this._label = this.labelElement ? labelFactory(this.labelElement) : null;
    this._lineRipple = this.lineRippleElement ? lineRippleFactory(this.lineRippleElement) : null;
    this._outline = this.outlineElement ? outlineFactory(this.outlineElement) : null;

    // Helper text
    const helperTextStrings = MDCTextFieldHelperTextFoundation.strings;
    const helperTextEl = this.helperLine
      ? this.helperLine.querySelector(helperTextStrings.ROOT_SELECTOR)
      : null;
    this._helperText = helperTextEl ? helperTextFactory(helperTextEl) : null;

    // Character counter
    const characterCounterStrings = MDCTextFieldCharacterCounterFoundation.strings;
    let characterCounterEl = this.mdcRoot.querySelector(characterCounterStrings.ROOT_SELECTOR);
    // If character counter is not found in root element search in sibling element.
    if (!characterCounterEl && this.helperLine) {
      characterCounterEl = this.helperLine.querySelector(characterCounterStrings.ROOT_SELECTOR);
    }
    if (this.maxLength && this.maxLength > 0) {
      this._characterCounter = characterCounterEl
        ? characterCounterFactory(characterCounterEl)
        : null;
    }

    this._leadingIcon = null;
    this._trailingIcon = null;

    if (this.iconElements.length > 0) {
      if (this.iconElements.length > 1) {
        // Has both icons.
        this._leadingIcon = iconFactory(this.iconElements[0]);
        this._trailingIcon = iconFactory(this.iconElements[1]);
      } else {
        if (this.mdcRoot.classList.contains(cssClasses.WITH_LEADING_ICON)) {
          this._leadingIcon = iconFactory(this.iconElements[0]);
        } else {
          this._trailingIcon = iconFactory(this.iconElements[0]);
        }
      }
    }

    // // set intial input props
    INPUT_PROPS.forEach(prop => {
      const value = this[prop];

      switch (prop) {
        case 'maxLength':
          if (value && value > 0) this.formElement[prop] = value;
          break;
        default:
          if (value) this.formElement[prop] = value;
          break;
      }
    });

    if (this.validateOnInitialRender) {
      this.reportValidity();
    }

    // this.formElement.addEventListener('input', this._handleInput);
    // this.formElement.addEventListener('focus', this._handleFocus);
    // this.formElement.addEventListener('blur', this._handleBlur);

    if (this._trailingIcon) {
      this._trailingIcon.listen('click', evt => this._onTrailingIconAction(evt));
      this._trailingIcon.listen('keydown', evt => this._onTrailingIconAction(evt));
    }

    super.firstUpdated();
  }

  /**
   * Create and attach the MDC Foundation to the instance
   */
  protected createFoundation() {
    if (this.mdcFoundation !== undefined) {
      this.mdcFoundation.destroy();
    }

    this.mdcFoundation = new this.mdcFoundationClass(
      this.createAdapter(),
      this._getFoundationMap(),
    );
    this.mdcFoundation.init();
  }

  /**
   * Notifies the change event for the textfield
   */
  protected _notifyChange() {
    if (this._previousValue !== this.value) {
      emit(this, 'change', { value: this.value }, true);
    }
  }

  /**
   * Handles the input event for the textfield
   */
  @eventOptions({ passive: true })
  protected _handleInputChange() {
      this.value = this.formElement.value;
  }

  /**
   * Handle trailing icon action event
   */
  protected _onTrailingIconAction(evt) {
    if (evt.type === 'keydown') {
      const isSpace = evt.key === 'Space' || evt.keyCode === 32;
      const isEnter = evt.key === 'Enter' || evt.keyCode === 13;

      if (!isSpace && !isEnter) return;

      if (isSpace) evt.preventDefault();
    }

    emit(this, EVENTS.trailingIconInteraction, {}, true);
  }

  /**
   * Handle formElement interaction event
   */
  protected _handleInteractionEvent(evt) {
    emit(this.mdcRoot, evt.type, {}, false);
    this._isFocus = true;
  }

  /**
   * handles formElement change event
   */
  // protected _handleChangeEvent(evt) {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  //
  //   emit(this, evt.type, { value: this.value }, true);
  // }

  protected async _setValidity(isValid: boolean) {
    this.isUiValid = isValid;
    await this.updateComplete;
    if (this._helperText && this.validationMessage) {
      // this.mdcFoundation &&
      //   this.mdcFoundation.setHelperTextContent(
      //     isValid ? this.helperTextContent : this.validationMessage,
      //   );
      this._helperText.foundation.setValidation(!isValid);
      return this.requestUpdate();
    }
  }

  /**
   * @return A map of all subcomponents to subfoundations.
   */
  protected _getFoundationMap(): Partial<MDCTextFieldFoundationMap> {
    return {
      characterCounter: this._characterCounter ? this._characterCounter.foundation : undefined,
      helperText: this._helperText ? this._helperText.foundation : undefined,
      leadingIcon: this._leadingIcon ? this._leadingIcon.foundation : undefined,
      trailingIcon: this._trailingIcon ? this._trailingIcon.foundation : undefined,
    };
  }

  /**
   * Recomputes the outline SVG path for the outline element.
   */
  public layout() {
    const openNotch = this.mdcFoundation.shouldFloat;
    this.mdcFoundation.notchOutline(openNotch);
  }
}

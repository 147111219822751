import { customElementDefinition } from '@gsk-tech/gsk-base/utils';
import { ChipBase } from './gsk-chip-base';

declare global {
  interface HTMLElementTagNameMap {
    'gsk-chip': Chip;
  }
}

@customElementDefinition('gsk-chip')
export class Chip extends ChipBase {}

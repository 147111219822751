/**
@license
Copyright 2018 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  BaseElement,
  property,
  query,
  PropertyValues,
  classMap,
  addHasRemoveClass,
  html, TemplateResult,
} from '@gsk-tech/gsk-base/base-element';
import MDCTabIndicatorFoundation from '@material/tab-indicator/foundation';
import MDCSlidingTabIndicatorFoundation from '@material/tab-indicator/sliding-foundation';
import MDCFadingTabIndicatorFoundation from '@material/tab-indicator/fading-foundation';
import { MDCTabIndicatorAdapter } from '@material/tab-indicator/adapter';

export class TabIndicatorBase extends BaseElement {

  protected mdcFoundation!: MDCTabIndicatorFoundation;

  protected get mdcFoundationClass() {
    return this.fade ? MDCFadingTabIndicatorFoundation : MDCSlidingTabIndicatorFoundation;
  }

  @query('.mdc-tab-indicator')
  protected mdcRoot!: HTMLElement;

  @query('.mdc-tab-indicator__content')
  protected contentElement!: HTMLElement;

  /**
   * Optional. Indicates the icon name to be used in the tab-indicator
   */
  @property()
  icon = '';

  /**
   * Optional. Default value is false. Sets up the tab indicator to fade in on activation and fade out on deactivation
   */
  @property({ type: Boolean })
  fade = false;

  /**
   * Used to render the lit-html TemplateResult to the element's DOM
   */
  render(): TemplateResult {
    const contentClasses = {
      'mdc-tab-indicator__content--icon': this.icon,
      'material-icons': this.icon,
      'mdc-tab-indicator__content--underline': !this.icon
    };
    return html`
      <span class="mdc-tab-indicator ${classMap({ 'mdc-tab-indicator--fade': this.fade })}">
        <span class="mdc-tab-indicator__content ${classMap(contentClasses)}">${this.icon}</span>
      </span>
      `;
  }

  /**
   * This method is invoked whenever the card is updated
   *
   * @param _changedProperties Map of changed properties with old values
   */
  updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('fade')) {
      this.createFoundation();
    }
  }

  /**
   * Create the adapter for the `mdcFoundation`.
   *
   * Override and return an object with the Adapter's functions implemented
   */
   /* istanbul ignore next */
  createAdapter(): MDCTabIndicatorAdapter {
    return {
      ...addHasRemoveClass(this.mdcRoot),
      computeContentClientRect: () => this.contentElement.getBoundingClientRect(),
      setContentStyleProperty: (prop: string, value: string) =>
        this.contentElement.style.setProperty(prop, value)
    };
  }

  /**
   * Returns the content element bounding client rect
   */
  computeContentClientRect() {
    return this.mdcFoundation.computeContentClientRect();
  }

  /**
   * Activates the tab indicator
   */
  activate(previousIndicatorClientRect?: ClientRect) {
    this.mdcFoundation.activate(previousIndicatorClientRect);
  }

  /**
   * Deactivates the tab indicator
   */
  deactivate() {
    this.mdcFoundation.deactivate();
  }
}
